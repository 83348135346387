import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as htmlToImage from 'html-to-image';
import lodashClonedeep from 'lodash.clonedeep';

import { AreaDeepdiveHelper } from 'src/app/components/pages/area-deepdive/area-deepdive-helper';
import { FilterHelper }       from 'src/app/components/elements/add-more-filter/filter-helper';
import { FILTER_CONFIGS, AreaDeepdiveQueries } from 'src/app/model/constants';
import { downloadAsPDF }             from 'src/app/helpers/pdfExtend';
import { PDFProperty }               from 'src/app/model/pdfProperty.model';
import { ChartServices }             from 'src/app/services/chart.services';
import { CsvService }                from 'src/app/services/csv.services';
import { UserService }               from 'src/app/services/user.services';
import { ProcessGroupedDataService } from 'src/app/services/process-grouped-data.services';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
  selector: 'app-area-deepdive-vs-ly-channel',
  templateUrl: './vs-ly-channel.component.html',
  styleUrls: ['./vs-ly-channel.component.scss']
})
export class VsLyChannelComponent implements OnInit, OnChanges {
  FILTERS: any = FILTER_CONFIGS?.ADD_MORE_FILTER?.DEFAULT || [];
  AREA_DEEPDIVE_FILTERS = FILTER_CONFIGS?.ADD_MORE_FILTER?.COLLECTIONS?.AREA_DEEPDIVE;
  defaultVisibleFilters: number = FILTER_CONFIGS?.ADD_MORE_FILTER?.VISIBLE_COUNT ?? 4;
  lstOption_ChannelLev = [{ value: 'CHANNEL_LEV2_NAME', name: 'Channel Lv2' }, { value: 'CHANNEL_LEV3_NAME', name: 'Channel Lv3' }, { value: 'AGG_BUSINESS_TYPE_NAME', name: 'Agg Business Type Name' }];
  lstOption_Selected_ChannelLev = this.lstOption_ChannelLev[0].value;

  @Input() lstOption_Selected_DateType: any;
  @Input() lstOption_Selected_SalesDate: any;
  @Input() lstAllOptionsCalendarData: any;
  @Input() lstAllOptionsAreaData: any;
  @Input() lstAllOptionsAccountData: any;
  @Input() lstAllOptionsProductData: any;
  @Input() lstOption_Selected: any;
  @Input() lstOption_States: any;
  @Input() lstOption_IsUsed: any;
  @Input() listData_Filter: any;
  @Input() updatedFilters: any;
  @Output() ready = new EventEmitter<boolean>();
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('observedElement', { static: true }) observedElement: ElementRef;

  sectionName: string = 'vs LY by Channel/Business';
  vsLYByCategoryData: any = null;
  listGroupCategoryDetail: any[] = null;
  vsLYByCategoryData_CSV: any = null;

  isLoading: boolean = false;
  isNoDataMode = true;
  lstOption_States_Applied: any = {};
  lstOption_Selected_Applied: any = {};
  areaFilterQuery: object = {};

  public listChart: any[] = [];
  public listChartId: any[] = [];

  public maxBAPC_TY: number;
  public maxNSR_TY: number;
  public maxGP_TY: number;

  public BAPC_TYHalfColumn: any;
  public BAPC_TYFullColumn: any;
  public NSR_TYHalfColumn: any;
  public NSR_TYFullColumn: any;
  public GP_TYHalfColumn: any;
  public GP_TYFullColumn: any;

  sortType: string = "desc";
  sortColumn: string = "NSR_TY";

  isHasFistLoad: boolean = false;

  constructor(private http: HttpClient, private router: Router, private csvService: CsvService, private userService: UserService, private chartServices: ChartServices, private dataProcessingService: ProcessGroupedDataService, private mixPanelTrackingService: MixPanelTrackingService) { }

  ngOnInit() {
    this.areaFilterQuery = AreaDeepdiveHelper.skuSetQueryString(this.lstOption_Selected);
    this.lstOption_IsUsed = FilterHelper.getListIsUsedFilter(this.FILTERS, this.AREA_DEEPDIVE_FILTERS) || {};
  }

  ngOnChanges(changes: SimpleChanges) {
    if (((changes['lstOption_Selected_DateType'] && this.lstOption_Selected_DateType && this.lstOption_Selected_DateType.length > 0)
        || (changes['lstOption_Selected_SalesDate'] && this.lstOption_Selected_SalesDate && this.lstOption_Selected_SalesDate.length > 0))) {
      if (this.isHasFistLoad) {
        this.onSearchData();
      }

      this.handleonReady();
    }

    if (changes['lstOption_Selected'] || changes['lstOption_States'] || changes['updatedFilters']) {
      this.applyFilters();

      if (this.isHasFistLoad) {
        this.onSearchData();
      }
    }

    this.isHasFistLoad = true;
  }

  ngAfterViewInit(): void {
    this.mixPanelTrackingService.observeElement(this.observedElement);
  }

  applyFilters() {
    this.lstOption_States_Applied = lodashClonedeep(this.lstOption_States);
    this.lstOption_Selected_Applied = lodashClonedeep(this.lstOption_Selected);
  }

  ngOnDestroy() {
    this.destroyCharts();
  }

  onSaveChannelLevChange(event: any) {
    this.lstOption_Selected_ChannelLev = event;
    this.onSearchData();
  }

  onSearchData() {
    if (!this.lstOption_Selected_DateType || !this.lstOption_Selected_DateType?.length || !this.lstOption_Selected_SalesDate || !this.lstOption_Selected_SalesDate.length) {
      return;
    }

    this.isLoading = true;
    this.areaFilterQuery = AreaDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied);

    AreaDeepdiveHelper.GetDataByChannel(this.http, this.lstOption_Selected_DateType ? this.lstOption_Selected_DateType : 'MTD', !AreaDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [], this.areaFilterQuery, this.lstOption_Selected_ChannelLev).then((data) => {
      if (data) {
        const { Data } = data;

        if (Data) {
          this.vsLYByCategoryData = Data;
          this.processData();

          switch (this.lstOption_Selected_ChannelLev) {
            case 'CHANNEL_LEV2_NAME':
              this.groupDataChannelLev2();
              break;

            case 'CHANNEL_LEV3_NAME':
              this.groupDataChannelLev3();
              break;

            case 'AGG_BUSINESS_TYPE_NAME':
              this.groupDataBusiness();
              break;
          }

          this.sortTable('');
          this.isLoading = false;
        }
      }
    });
  }

  private processData() {
    this.isNoDataMode = this.vsLYByCategoryData.length == 0;
    const metrics = this.dataProcessingService.processMetrics(this.vsLYByCategoryData);
    Object.assign(this, metrics);  // Assign metrics to component
  }

  groupDataChannelLev2() {
    this.listGroupCategoryDetail = this.dataProcessingService.processLevel2Data(
      this.vsLYByCategoryData,
      this
    );
  }

  groupDataChannelLev3() {
    this.listGroupCategoryDetail = this.dataProcessingService.processLevel3Data(
      this.vsLYByCategoryData,
      this,
      'CHANNEL_LEV2_NAME'
    );
  }

  groupDataBusiness() {
    this.listGroupCategoryDetail = this.dataProcessingService.processHierarchicalData(
      this.vsLYByCategoryData,
      this,
      'CHANNEL_LEV2_NAME',
      'CHANNEL_LEV3_NAME'
    );
  }

  getLabelsForChart() {
    const labelMapping = {
      'CHANNEL_LEV2_NAME': 'CHANNEL_LEV2_NAME',
      'CHANNEL_LEV3_NAME': 'CHANNEL_LEV3_NAME',
    };

    const labelKey = labelMapping[this.lstOption_Selected_ChannelLev] || 'AGG_BUSINESS_TYPE_NAME';
    return this.listGroupCategoryDetail.map(e => e[labelKey] || 'NULL');
  }

  sortTable(column: string) {
    if (column == this.sortColumn && column) {
      this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortType = "desc";
    }

    // Sort data for CHANNEL_LEV2_NAME
    if (this.lstOption_Selected_ChannelLev === 'CHANNEL_LEV2_NAME') {
      if (this.sortColumn) {
        this.listGroupCategoryDetail = this.sortType == 'asc' ? this.listGroupCategoryDetail.slice().sort((a, b) => a[this.sortColumn] - b[this.sortColumn]) : this.listGroupCategoryDetail.slice().sort((a, b) => b[this.sortColumn] - a[this.sortColumn]);
      }

      this.destroyCharts();
      this.createChartChannelLev2();
    }

    // Sort data for CHANNEL_LEV3_NAME
    if (this.lstOption_Selected_ChannelLev === 'CHANNEL_LEV3_NAME') {
      let lstChildItems = [];
      this.listGroupCategoryDetail = this.listGroupCategoryDetail.map((category, index) => {
        let childItems = this.sortType == 'asc' ? category.ChildItems.slice().sort((a, b) => a[this.sortColumn] - b[this.sortColumn]) : category.ChildItems.slice().sort((a, b) => b[this.sortColumn] - a[this.sortColumn]);
        lstChildItems.push(...childItems);

        return {
          CHANNEL_LEV2_NAME: category.CHANNEL_LEV2_NAME,
          subIdChart: 'package_' + category.CHANNEL_LEV2_NAME.split(' ').join('_') + index,
          ChildItems: childItems
        };
      });

      lstChildItems = this.sortType == 'asc' ? lstChildItems.slice().sort((a, b) => a[this.sortColumn] - b[this.sortColumn]) : lstChildItems.slice().sort((a, b) => b[this.sortColumn] - a[this.sortColumn]);
      let listGroupPackageName = Array.from(new Set(lstChildItems.map(item => item.CHANNEL_LEV2_NAME)));

      let packageOrderMap = new Map();
      listGroupPackageName.forEach((name, index) => {
        packageOrderMap.set(name, index);
      });

      this.listGroupCategoryDetail.sort((a, b) => {
        return packageOrderMap.get(a.CHANNEL_LEV2_NAME) - packageOrderMap.get(b.CHANNEL_LEV2_NAME);
      });

      this.destroyCharts();
      this.createChartChannelLev3();
    }
    // Sort data for AGG_BUSINESS_TYPE_NAME
    if (this.lstOption_Selected_ChannelLev === 'AGG_BUSINESS_TYPE_NAME') {
      let lstChildItems = [];
      this.listGroupCategoryDetail = this.listGroupCategoryDetail.map((category, index) => {
        let childItems = this.sortType == 'asc' ? category.ChildItems?.slice()?.sort((a, b) => a[this.sortColumn] - b[this.sortColumn]) : category.ChildItems?.slice().sort((a, b) => b[this.sortColumn] - a[this.sortColumn]);
        lstChildItems.push(...childItems);

        return {
          CHANNEL_LEV2_NAME: category.CHANNEL_LEV2_NAME,
          CHANNEL_LEV3_NAME: category.CHANNEL_LEV3_NAME,
          subIdChart: category.CHANNEL_LEV2_NAME?.split(' ')?.join('_') + '_' + category.CHANNEL_LEV3_NAME?.split(' ')?.join('_'),
          ChildItems: childItems
        };
      });

      lstChildItems = this.sortType == 'asc' ? lstChildItems?.slice()?.sort((a, b) => a[this.sortColumn] - b[this.sortColumn]) : lstChildItems?.slice()?.sort((a, b) => b[this.sortColumn] - a[this.sortColumn]);
      let listGroupPackageName = Array.from(new Set(lstChildItems.map(item => item.CHANNEL_LEV2_NAME)));
      let packageOrderMap = new Map();

      listGroupPackageName.forEach((name, index) => {
        packageOrderMap.set(name, index);
      });

      this.listGroupCategoryDetail.sort((a, b) => {
        return packageOrderMap.get(a.CHANNEL_LEV2_NAME) - packageOrderMap.get(b.CHANNEL_LEV2_NAME);
      });
      this.destroyCharts();
      this.createChartAggBusiness();
    }
  };

  createChartChannelLev2() {
    this.listChartId = [{ chartBAPC: 'chartBAPC_Channel', chartNRS: 'chartNRS_Channel', chartGP: 'chartGP_Channel' }];
    const dataBAPC_lable = this.getLabelsForChart();
    const bapcData = this.listGroupCategoryDetail.map(e => parseFloat((e.BAPC_VS_LY * 100).toFixed(1)));
    const nsrData = this.listGroupCategoryDetail.map(e => parseFloat((e.NSR_VS_LY * 100).toFixed(1)));
    const gpData = this.listGroupCategoryDetail.map(e => parseFloat((e.GP_VS_LY * 100).toFixed(1)));
    setTimeout(() => {
      this.destroyCharts();
      this.listChart.push({
        chartBAPC: this.chartServices.createLineChart(this.listChartId[0]?.chartBAPC, dataBAPC_lable, bapcData, -200, 200),
        chartNRS: this.chartServices.createLineChart(this.listChartId[0]?.chartNRS, dataBAPC_lable, nsrData, -200, 200),
        chartGP: this.chartServices.createLineChart(this.listChartId[0]?.chartGP, dataBAPC_lable, gpData, -200, 200)
      });
    }, 1000);
  }

  createChartChannelLev3() {
    this.listChartId = [];
    const datas = [];

    this.listGroupCategoryDetail.map((category) => {
      const { subIdChart, ChildItems } = category;
      const bapcData = ChildItems.map(e => (e.BAPC_VS_LY * 100).toFixed(1));
      const nsrData = ChildItems.map(e => (e.NSR_VS_LY * 100).toFixed(1));
      const gpData = ChildItems.map(e => (e.GP_VS_LY * 100).toFixed(1));
      const labels = ChildItems.map(e => e.CHANNEL_LEV3_NAME || 'No-category');

      datas.push({ labels, bapcData, nsrData, gpData });
      this.listChartId.push({ chartBAPC: `chartBAPC_${subIdChart}`, chartNRS: `chartNRS_${subIdChart}`, chartGP: `chartGP_${subIdChart}` });
    });

    setTimeout(() => {
      this.destroyCharts();
      this.listChartId.map((item, index) => {
        const { labels, bapcData, nsrData, gpData } = datas[index];
        this.listChart.push({
          chartBAPC: this.chartServices.createLineChart(item.chartBAPC, labels, bapcData, -200, 200),
          chartNRS: this.chartServices.createLineChart(item.chartNRS, labels, nsrData, -200, 200),
          chartGP: this.chartServices.createLineChart(item.chartGP, labels, gpData, -200, 200)
        });
      });
    }, 1000);
  }

  createChartAggBusiness() {
    this.listChartId = [];
    const datas = [];

    this.listGroupCategoryDetail.map((category) => {
      const { subIdChart, ChildItems } = category;
      const bapcData = ChildItems.map(e => (e.BAPC_VS_LY * 100).toFixed(1));
      const nsrData = ChildItems.map(e => (e.NSR_VS_LY * 100).toFixed(1));
      const gpData = ChildItems.map(e => (e.GP_VS_LY * 100).toFixed(1));
      const labels = ChildItems.map(e => e.CHANNEL_LEV3_NAME || 'No-category');

      datas.push({ labels, bapcData, nsrData, gpData });
      this.listChartId.push({ chartBAPC: `chartBAPC_${subIdChart}`, chartNRS: `chartNRS_${subIdChart}`, chartGP: `chartGP_${subIdChart}` });
    });

    setTimeout(() => {
      this.listChartId.map((item, index) => {
        const { labels, bapcData, nsrData, gpData } = datas[index];
        this.listChart.push({
          chartBAPC: this.chartServices.createLineChart(item.chartBAPC, labels, bapcData, -200, 200),
          chartNRS: this.chartServices.createLineChart(item.chartNRS, labels, nsrData, -200, 200),
          chartGP: this.chartServices.createLineChart(item.chartGP, labels, gpData, -200, 200)
        });
      });
    }, 1000);
  }

  // Destroy Chart
  destroyCharts() {
    if (this.listChart?.length <= 0) {
      return;
    }

    this.listChart.forEach((chart: any) => {
      const { chartBAPC, chartNRS, chartGP } = chart;

      if (chartBAPC) {
        chartBAPC.destroy();
      }

      if (chartNRS) {
        chartNRS.destroy();
      }

      if (chartGP) {
        chartGP.destroy();
      }
    });

    this.listChart = [];
    this.listChartId = [];
  }

  // Handle onReady
  handleonReady() {
    let t = this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }

  // CSV Export
  exportCVS_by_Url() {
    const obj = AreaDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied);

    const dateType = this.lstOption_Selected_DateType ? this.lstOption_Selected_DateType : 'MTD';
    const salesDate = !AreaDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [];

    this.areaFilterQuery = {
      ...obj,
      CacheKey: `DATE_TYPE=${encodeURIComponent(dateType)}&SALES_DATE=${salesDate}&${obj['CacheKey']}`,
      Selected: {
        ...obj['Selected'],
        DATE_TYPE: dateType,
        SALES_DATE: salesDate
      }
    };

    this.csvService.getCsvUrl(AreaDeepdiveQueries.byChannel, this.areaFilterQuery, this.lstOption_Selected_ChannelLev)
      .subscribe((data) => {
        if (data) {
          this._downloadCsv(data?.Csv_Url);
        }
      });
  }

  private _downloadCsv(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }

  // PDF Export
  @ViewChild('pdf_print_channel_2', { static: false }) pdf_print_channel_2: ElementRef;
  exportPDF(name) {
    const $ = window["jQuery"];
    $("body").addClass("pdf-printing");
    $(".table-container").css('height', 'auto');
    $(".table-container").css('overflow-y', 'scroll');
    $(".filter-section").css('display', 'none');

    let channel = this.pdf_print_channel_2.nativeElement;
    htmlToImage.toCanvas(this.pdf_print_channel_2.nativeElement, { quality: 1 })
      .then(function (canvas) {
        let pdfProperty: PDFProperty = {
          option: { margin: [50, 0, 46, 0] },
          canvas: canvas
        }
        downloadAsPDF(name, channel, false, pdfProperty);
      });
  }
}
