import { Component, OnInit, OnChanges, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import lodashClonedeep from 'lodash.clonedeep';
import { PhotoReportFilterHelper } from './photo-report-filter.helper';
import { isObjEmpty } from 'src/app/helpers/utils';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
  selector   : 'app-photo-report-filter',
  templateUrl: './photo-report-filter.component.html',
  styleUrls  : ['./photo-report-filter.component.scss']
})

export class PhotoReportFilter implements OnInit, OnChanges {
  @Input() sectionName: string = '';
  @Input() filterModuleName : string = '';
  @Input() filters: any;

  @Input() lstAllOptionsData: any  = {};
  @Input() lstOption_Selected: any = {};
  @Input() listData_Filter: any    = {};
  @Input() lstOption_IsUsed: any   = {};

  @Output() onUpdateChart = new EventEmitter<{ lstOption_Selected: any }>();

  lstOptions: any = {};
  lstOption_Aggregated: any = {};

  constructor(private mixPanelTrackingService: MixPanelTrackingService) {}

  ngOnInit(): void {
    this.lstOptions = PhotoReportFilterHelper.getDefaultFilterList(this.filters);
    this.lstOption_Aggregated = lodashClonedeep(this.lstOption_Selected);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.listData_Filter && !isObjEmpty(this.listData_Filter)) {
      // todo
    } else {
      if (changes['lstAllOptionsData']  && !isObjEmpty(this.lstAllOptionsData)) {
        this.initAllOptionSelect();
      }
    }
  }

  private getOptions(keyName: string, allOptions: any) {
    if (!this.lstOptions) {
      this.lstOptions = {};
    }

    if (this.lstOption_IsUsed[keyName] && allOptions[keyName]?.length) {
      const opts = [...allOptions[keyName]];

      switch (keyName) {
        case 'CHANNEL':
          this.lstOptions[keyName] = this._sortChannelArray(opts);
          break;

        case 'SALES_LOCATION_TYPE_NAME_EN':
          this.lstOptions[keyName] = this._sortTeibanArray(opts);
          break;

        case 'SALES_DISTRICT_GROUP':
          this.lstOptions[keyName] = this._sortAreaArray(opts);
          break;

        default:
          this.lstOptions[keyName] = [{ value: 'All', text: 'All' }, ...opts?.map((item) => { return { value: item || 'NULL', text: item || 'NULL' }; })];
          break;
      }
    }
  }

  private initAllOptionSelect() {
    this.lstOptions = {...this.lstAllOptionsData};
    this.getOptions('CHANNEL', this.lstOptions);
    this.getOptions('SALES_DISTRICT_GROUP', this.lstOptions);
    this.getOptions('SALES_LOCATION_TYPE_NAME_EN', this.lstOptions);
  }

  public onSaveItemsChange(event: any) {
    const { keyName, selectedItems } = event;
    this.lstOption_Selected[keyName] = selectedItems;

    this.onUpdateChart.emit(
      {
        lstOption_Selected: this.lstOption_Selected
      }
    );
  }

  public onSingleDropdownBlur(rollBack: boolean, keyName: string, filterName?: string) {
    if (!rollBack) {
      this.lstOption_Aggregated[keyName] = lodashClonedeep(this.lstOption_Selected[keyName]);
      this.onUpdateChart.emit(
        {
          lstOption_Selected: this.lstOption_Selected
        }
      );
      this.mixPanelTrackingService.onTrackDropdownAggregate(this.sectionName, filterName, [this.lstOption_Selected[keyName]]);
    } else {
      this.lstOption_Selected[keyName] = lodashClonedeep(this.lstOption_Aggregated[keyName]);
    }
  }

  private _sortChannelArray(array: string[]): { text: string; value: string }[] {
    const sortOrder = ['SMDD', 'SM', 'Drug', 'Discounter'];
    const orderMap = new Map(sortOrder.map((item, index) => [item, index]));

    const sortedArray = array.slice().sort((a, b) => {
      const indexA = orderMap.get(a) ?? Infinity;
      const indexB = orderMap.get(b) ?? Infinity;
      return indexA - indexB;
    });

    let results = [];

    if (array.includes('SMDD')) {
      results = sortedArray.map(item => ({
        value : item,
        text  : item,
        child : (item === 'SMDD') ? false : true,
        parent: (item === 'SMDD') ? '' : 'SMDD'
      }));
    } else {
      results = sortedArray.map(item => ({
        value: item,
        text: item
      }));
    }

    return results;
  }

  private _sortTeibanArray(array: string[]): { text: string; value: string }[] {
    const sortOrder = ['Cold Teiban', 'Ambient Teiban'];
    const orderMap = new Map(sortOrder.map((item, index) => [item, index]));

    const sortedArray = array.slice().sort((a, b) => {
      const indexA = orderMap.get(a) ?? Infinity;
      const indexB = orderMap.get(b) ?? Infinity;
      return indexA - indexB;
    });

    const results = sortedArray.map(item => ({
      value : item,
      text  : item,
      child : true,
      parent: 'Teiban Total'
    }));

    results.unshift({value: 'Teiban Total', text: 'Teiban Total', child: false, parent: ''});
    return results;
  }

  private _sortAreaArray(array: string[]): {text: string, value: string}[] {
    const sortOrder = ['CCBJI', 'North Kanto/South Tohoku', 'Metropolitan', 'Kansai/Chubu', 'Kyushu/Chusikoku'];
    const orderMap = new Map(sortOrder.map((item, index) => [item, index]));

    const sortedArray = array.slice().sort((a, b) => {
      const indexA = orderMap.get(a) ?? Infinity;
      const indexB = orderMap.get(b) ?? Infinity;
      return indexA - indexB;
    });

    const results = sortedArray.map(item => ({
      value : item,
      text  : item,
    }));

    return results;
  }
}
