import { Component, Renderer2, OnChanges, Input, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { IntageHelper } from 'src/app/components/pages/intage/intage_helper';
import html2canvas from 'src/html2canvas';
import { downloadAsPDF as _downloadAsPDF } from './../../../../helpers/pdf';
import { IndexDbHelper } from 'src/app/helpers/indexDb';
import { getChartStateFromUrl } from 'src/app/helpers/queryString';
import { Router } from '@angular/router';

@Component({
  selector: 'intage-otc-market-share-by-package',
  templateUrl: './otc-market-share-by-package.component.html',
  styleUrls: ['./otc-market-share-by-package.component.scss']
})
export class IntageOtcMarketShareByPackageComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intageMonth: any;
  @Input() intage: any;
  @Input() users: any;
  //FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  categories: any;
  categoryColors: any;
  smddChanelOrder: any;
  marketSizeBySSMMData:any;
  marketShareBySSMMData: any;
  selectedChannel: any;
  makerLogo: any;
  ssmmOrder: any;
  summaryRegions: any;
  marketShareSelectedRegion: any;
  populated: boolean;
  aggregateBy: any;
  chartId = "otc-market-value-share-by-package-size"

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient, private router: Router) { }

  ngOnInit() {
    const chartState = getChartStateFromUrl(this.router, this.chartId);
    for (const key in chartState) {
      this[key] = chartState[key]
    }
  }    

  ngOnChanges() {
    if(!this.populated){
      this.marketShareSelectedRegion = ['CCBJI Territory'];
      this.ssmmOrder = [
        {
          packageType: 'Total',
          index: 1
        },
        {
          packageType: 'SS',
          index: 2
        },
        {
          packageType: 'MS',
          index: 3
        },
        {
          packageType: 'Other',
          index: 4
        }
      ];
      this.categoryColors = IntageHelper.CategoryColors();
      this.smddChanelOrder = IntageHelper.SmddChannels();
      this.selectedChannel = ['OTC Total'];
      this.makerLogo = IntageHelper.MakerLogos();
      this.populated = true;
    }
    if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
      let commonData = IntageHelper.PopulateCommonData(this.intage, this.selectedWeek);
      this.categories = commonData.categories;
      this.summaryRegions = commonData.summaryRegions;
      this.handleMarketSizeBySSMM();
      this.handleMarketShareBySSMM();
    }
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["selectedChannel"] = [...this.selectedChannel];
      window["marketShareSelectedRegion"] = [...this.marketShareSelectedRegion];
    } else {
      this.selectedChannel = window["selectedChannel"];
      this.marketShareSelectedRegion = window["marketShareSelectedRegion"];
    }
  }
  isSmddChild(channel: string){
    return AnalyticsHelper.isSmddChild(channel);
  }
  onRemoveFilterOTCChannel($event) {
    if(this.selectedChannel.length == 0){
      this.selectedChannel = ['OTC Total'];
      this.SaveOptionValueTemp(false);
      this.onFilterByChannelChange();
    }
    if ($event.value === 'OTC Total') {
      this.selectedChannel = [];
      return;
    }
    if (AnalyticsHelper.isSmddParent($event.value)) {
      var arrChilds = AnalyticsHelper.getSmddChildIntage($event.value);
      this.selectedChannel = this.selectedChannel.filter(function (el) {
        return arrChilds.indexOf(el) < 0;
      });
    }
    if (AnalyticsHelper.isSmddChild($event.value)) {
      var parent = AnalyticsHelper.getSmddParent($event.value);
      this.selectedChannel = this.selectedChannel.filter(e => e !== parent);
    }
  }
  onAddFilteOTCChannel($event) {
    if ($event === 'OTC Total') {
      this.selectedChannel = ['OTC Total'];
      return;
    }
    if (AnalyticsHelper.isSmddParent($event)) {
      var arrChilds = AnalyticsHelper.getSmddChildIntage($event);
      this.selectedChannel = this.selectedChannel.filter(e => !arrChilds.includes(e));
    }
    if (AnalyticsHelper.isSmddChild($event)) {
      var parent = AnalyticsHelper.getSmddParent($event);
      var checkChildSelected = true;
      var arrChilds = AnalyticsHelper.getSmddChildIntage(parent);
      arrChilds.forEach(child => {
        if (this.smddChanelOrder.some(x=>x.channel==child) && !this.selectedChannel.includes(child)) {
          checkChildSelected = false;
        }
      });
      if (checkChildSelected) {
        var arrTemp = [parent];
        this.selectedChannel = this.selectedChannel.filter(e => !arrChilds.includes(e));
        this.selectedChannel = arrTemp.concat(this.selectedChannel);
      }else{
        this.selectedChannel = this.selectedChannel.filter(e => e !== parent);
      }
    }
    var checkParentFilter = true;
    var filterNotCheck = this.smddChanelOrder.filter(e => !this.selectedChannel.includes(e.channel) && e.channel!='OTC Total');
    filterNotCheck.forEach(element => {
      if (!AnalyticsHelper.isSmddChild(element.channel) || !this.selectedChannel.includes(AnalyticsHelper.getSmddParent(element.channel))) {
        checkParentFilter=false;
      }
    });
    if(checkParentFilter){
      this.selectedChannel = ['OTC Total'];
    }else{
      this.selectedChannel = this.selectedChannel.filter(e => e !== 'OTC Total');
    }
  }
  onRemoveFilterShareRegion($event) {
    if(this.marketShareSelectedRegion.length == 0){
      this.marketShareSelectedRegion = ['CCBJI Territory'];
      this.SaveOptionValueTemp(false);
      this.onMarketShareRegionChange()
    }
    if ($event.value === 'CCBJI Territory') {
      this.marketShareSelectedRegion = [];
      return;
    }
  }
  onAddFilterShareRegion($event) {
    if ($event === 'CCBJI Territory') {
      this.marketShareSelectedRegion = ['CCBJI Territory'];
      return;
    }
    if(this.marketShareSelectedRegion.filter(x=> x != 'CCBJI Territory').length == (this.summaryRegions.length-1)){
      this.marketShareSelectedRegion = ['CCBJI Territory'];
    }else{
      this.marketShareSelectedRegion = this.marketShareSelectedRegion.filter(e => e !== 'CCBJI Territory');;
    }
  } 

  onOTCMarketsizeAggregateChange(aggregateBy: string)
  {
    this.aggregateBy = aggregateBy;
    this.handleMarketSizeBySSMM();
    this.handleMarketShareBySSMM();
  }

  handleMarketSizeBySSMM(){
    switch (this.aggregateBy) {
      case 'mtd':
        var month = this.intageMonth.find(x => x.week == this.selectedWeek).month;
        var weeks = [...new Set(this.intageMonth.filter(x => x.month == month && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllData();
        break;
      case 'qtd':
        var quarter = this.intageMonth.find(x => x.week == this.selectedWeek).quarter;
        var weeks = [...new Set(this.intageMonth.filter(x => x.quarter == quarter && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllData();       
        break;
      case 'ytd':
        var year = this.intageMonth.find(x => x.week == this.selectedWeek).year;
        var weeks = [...new Set(this.intageMonth.filter(x => x.year == year && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllData();
        break;
      default:
        weeks = [this.selectedWeek];
        this.handleIntageDataMarketSize(weeks);
        break;
    }
  }

  public async firstcheckIntageAllData()
  {
    var intage = await IndexDbHelper.Get('IntageFlashAllData'); 
    if (intage != null)
    {
      this.intage = intage;
      this.handleIntageDataMarketSize(window["weeks"]);
    }
    else
    {
      this.checkIntageFlashAllData();
    }
  }

  public async checkIntageFlashAllData()
  {
    setTimeout(async () => {
      var intage = await IndexDbHelper.Get('IntageFlashAllData');  
      if (intage != null)
      {
        this.intage = intage;
        this.handleIntageDataMarketSize(window["weeks"]);
      }
      else
      {
        this.checkIntageFlashAllData();
      }
    }, 200);
  }

  public handleIntageDataMarketSize(weeks: any)
  {
    var data = this.intage.filter(x=> weeks.includes(x.week)); 
    let smddChannel = null;
    if(!this.selectedChannel.includes('OTC Total')){
      smddChannel = this.smddChanelOrder.find(x=> this.selectedChannel.includes(x.channel));
      if(smddChannel != null){
        if(smddChannel.childChannels == null){
          data = data.filter(x=> this.selectedChannel.includes(x.channel));
        }else{
          data = data.filter(x=> smddChannel.childChannels.includes(x.channel));
        }
      }
    }
    this.marketSizeBySSMMData = [{
      packageType: 'Total',
      categories: [{
        category: 'Total',
        ty: 0,
        ly: 0,
        imp: '',
        vsLy: ''
      }]
    }];

    for(var i=0;i<data.length;i++){
      let row = data[i];
      if(row.packageType == ''){
        continue;
      }
      if(!this.marketShareSelectedRegion.includes('CCBJI Territory') && !this.marketShareSelectedRegion.includes(row.region)){
        continue;
      }
      let packageType = this.marketSizeBySSMMData.find(x=> x.packageType == row.packageType);
      if(packageType == null){
        packageType = {
          packageType: row.packageType,
          categories: [
            {
              category: 'Total',
              ty: 0,
              ly: 0,
              imp: '',
              vsLy: ''
            }
          ]
        };
        this.marketSizeBySSMMData.push(packageType);
      }

      let category = packageType.categories.find(x=> x.category == row.category);
      if(category == null){
        category = {
          category: row.category,
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly),
          imp: '',
          vsLy: ''
        };
        packageType.categories.push(category);
      }
      else{
        category.ty += parseFloat(row.ty);
        category.ly += parseFloat(row.ly);
      }

      let totalCategory = this.marketSizeBySSMMData[0].categories.find(x=> x.category == row.category);
      if(totalCategory == null){
        totalCategory = {
          category: row.category,
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly),
          imp: '',
          vsLy: ''
        };
        this.marketSizeBySSMMData[0].categories.push(totalCategory);
      }
      else{
        totalCategory.ty += parseFloat(row.ty);
        totalCategory.ly += parseFloat(row.ly);
      }

      packageType.categories[0].ty+= parseFloat(row.ty);
      packageType.categories[0].ly+= parseFloat(row.ly);
      this.marketSizeBySSMMData[0].categories[0].ty+= parseFloat(row.ty);
      this.marketSizeBySSMMData[0].categories[0].ly+= parseFloat(row.ly);
    }

    for(var i=0;i<this.marketSizeBySSMMData.length;i++){
      let packageType = this.marketSizeBySSMMData[i];
      let order = this.ssmmOrder.find(x=> x.packageType == packageType.packageType);
      if(order != null){
        packageType.index = order.index;
      }else{
        packageType.index = 100;
      }

      for(var j=0;j<packageType.categories.length;j++){
        let category = packageType.categories[j];
        let totalPackage = packageType.categories[0];
        category.imp = totalPackage.ty == 0 ? "-" : (category.ty/totalPackage.ty * 100).toFixed(1);
        if (category.ly == 0)
        {
          category.vsLy = '-';
        }
        else if (category.ty == 0)
        {
          category.vsLy = '-100.0';
        }
        else
        {
          category.vsLy = ((category.ty/category.ly) * 100 - 100).toFixed(1);
        }
      }
    }

    this.marketSizeBySSMMData.sort((a, b) => a.index > b.index ? 1 : -1);
    this.marketSizeBySSMMData = this.marketSizeBySSMMData.filter(x=> x.packageType != 'Other');
  }

  handleMarketShareBySSMM(){
    switch (this.aggregateBy) {
      case 'mtd':
        var month = this.intageMonth.find(x => x.week == this.selectedWeek).month;
        var weeks = [...new Set(this.intageMonth.filter(x => x.month == month && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllDataMarketShare();
        break;
      case 'qtd':
        var quarter = this.intageMonth.find(x => x.week == this.selectedWeek).quarter;
        var weeks = [...new Set(this.intageMonth.filter(x => x.quarter == quarter && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllDataMarketShare();       
        break;
      case 'ytd':
        var year = this.intageMonth.find(x => x.week == this.selectedWeek).year;
        var weeks = [...new Set(this.intageMonth.filter(x => x.year == year && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllDataMarketShare();
        break;
      default:
        weeks = [this.selectedWeek];
        this.handleIntageDataMarketShare(weeks);
        break;
    }
  }

  public async firstcheckIntageAllDataMarketShare()
  {
    var intage = await IndexDbHelper.Get('IntageFlashAllData'); 
    if (intage != null)
    {
      this.intage = intage;
      this.handleIntageDataMarketShare(window["weeks"]);
    }
    else
    {
      this.checkIntageFlashAllDataMarketShare();
    }
  }

  public async checkIntageFlashAllDataMarketShare()
  {
    setTimeout(async () => {
      var intage = await IndexDbHelper.Get('IntageFlashAllData');  
      if (intage != null)
      {
        this.intage = intage;
        this.handleIntageDataMarketShare(window["weeks"]);
      }
      else
      {
        this.checkIntageFlashAllDataMarketShare();
      }
    }, 200);
  }

  public handleIntageDataMarketShare(weeks: any)
  {
    var data = this.intage.filter(x=> weeks.includes(x.week));  
    let smddChannel = null;
    if(!this.selectedChannel.includes('OTC Total')){
      smddChannel = this.smddChanelOrder.find(x=> this.selectedChannel.includes(x.channel));
      if(smddChannel != null){
        if(smddChannel.childChannels == null){
          data = data.filter(x=> this.selectedChannel.includes(x.channel));
        }else{
          data = data.filter(x=> smddChannel.childChannels.includes(x.channel));
        }
      }
    }
    this.marketShareBySSMMData = [];
    let totals = [{
      channel: 'OTC Total',
      category: 'Total',
      ty: 0,
      ly: 0
    }];
    for(var i=0;i<data.length;i++){
      let row = data[i];
      if(row.packageType == ''){
        continue;
      }
      if(!this.marketShareSelectedRegion.includes('CCBJI Territory') && !this.marketShareSelectedRegion.includes(row.region)){
        continue;
      }
      let total = totals.find(x=> x.channel == row.channel && x.category == row.category);
      if(total == null){
        total = {
          channel: row.channel,
          category: row.category,
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly)
        }
        totals.push(total);
      }else{
        total.ty +=parseFloat(row.ty);
        total.ly +=parseFloat(row.ly);
      }
      let categoryTotal = totals.find(x=> x.channel == row.channel && x.category == 'Total');
      if(categoryTotal == null){
        categoryTotal = {
          channel: row.channel,
          category: 'Total',
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly)
        }
        totals.push(categoryTotal);
      }else{
        categoryTotal.ty +=parseFloat(row.ty);
        categoryTotal.ly +=parseFloat(row.ly);
      }
      let channelTotal = totals.find(x=> x.channel == 'OTC Total' && x.category == row.category);
      if(channelTotal == null){
        channelTotal = {
          channel: 'OTC Total',
          category: row.category,
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly)
        }
        totals.push(channelTotal);
      }else{
        channelTotal.ty +=parseFloat(row.ty);
        channelTotal.ly +=parseFloat(row.ly);
      }
      totals[0].ty +=parseFloat(row.ty);
      totals[0].ly +=parseFloat(row.ly);

      let maker = this.marketShareBySSMMData.find(x=> x.maker == row.maker);
      if(maker == null){
        let makerLogo = this.makerLogo.find(x=> x.maker == row.maker);
        maker = {
          maker: row.maker,
          index: makerLogo != null ? makerLogo.index : 100,
          logo: makerLogo != null ? makerLogo.logo: "",
          packageTypes: [
            {
              packageType: 'Total',
              categories: [
                {
                  category: 'Total',
                  ty: 0,
                  ly: 0,
                  totalTy: 0,
                  totalLy: 0,
                  share: '',
                  vsLy: '',
                  shareDiff: ''
                }
              ]
            }
          ]
        };
        this.marketShareBySSMMData.push(maker);
      }

      let packageType = maker.packageTypes.find(x=> x.packageType == row.packageType);
      if(packageType == null){
        packageType = {
          packageType: row.packageType,
          categories: [
            {
              category: 'Total',
              ty: 0,
              ly: 0,
              totalTy: 0,
              totalLy: 0,
              share: '',
              vsLy: '',
              shareDiff: ''
            }
          ]
        };
        maker.packageTypes.push(packageType);
      }

      let category = packageType.categories.find(x=> x.category == row.category);
      if(category == null){
        category = {
          category: row.category,
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly),
          totalTy: 0,
          totalLy: 0,
          share: '',
          vsLy: '',
          shareDiff: ''
        };
        packageType.categories.push(category);
      }
      else{
        category.ty += parseFloat(row.ty);
        category.ly += parseFloat(row.ly);
      }
      let totalCategory = maker.packageTypes[0].categories.find(x=> x.category == row.category);
      if(totalCategory == null){
        totalCategory = {
          category: row.category,
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly),
          totalTy: 0,
          totalLy: 0,
          share: '',
          vsLy: '',
          shareDiff: ''
        };
        maker.packageTypes[0].categories.push(totalCategory);
      }
      else{
        totalCategory.ty += parseFloat(row.ty);
        totalCategory.ly += parseFloat(row.ly);
      }
      packageType.categories[0].ty+= parseFloat(row.ty);
      packageType.categories[0].ly+= parseFloat(row.ly);
      maker.packageTypes[0].categories[0].ty+= parseFloat(row.ty);
      maker.packageTypes[0].categories[0].ly+= parseFloat(row.ly);
    }

    for(var i=0;i<this.marketShareBySSMMData.length;i++){
      let maker = this.marketShareBySSMMData[i];
      for(var j=0;j<maker.packageTypes.length;j++){
        let packageType = maker.packageTypes[j];
        let order = this.ssmmOrder.find(x=> x.packageType == packageType.packageType);
        if(order != null){
          packageType.index = order.index;
        }else{
          packageType.index = 100;
        }
        for(var k=0;k<maker.packageTypes[j].categories.length;k++){
          let category = maker.packageTypes[j].categories[k];
          let total = null;
          if((smddChannel != null && smddChannel.childChannels == null) || smddChannel == null){
            total = totals.find(x=> x.category == category.category && this.selectedChannel.includes(x.channel));
          }
          else{
            let totalList = totals.filter(x=> x.category == category.category && smddChannel.childChannels.includes(x.channel));
            total = {
              ty:0,
              ly:0
            };
            for(var t=0;t<totalList.length;t++){
              total.ty += totalList[t].ty;
              total.ly += totalList[t].ly;
            }
          }

          category.totalTy = total.ty;
          category.totalLy = total.ly;

          category.share = category.totalTy == 0 ? "-" : ((category.ty / category.totalTy) * 100).toFixed(1);
          let share_noround =  category.totalTy == 0 ? null : ((category.ty / category.totalTy) * 100) + '';
          let shareLy_noround = category.totalLy == 0 ? null : ((category.ly / category.totalLy) * 100) + '';
          category.vsLy = (share_noround == null || shareLy_noround == null) ? '-' : (parseFloat(share_noround) - parseFloat(shareLy_noround)).toFixed(1);
        }
      }
      maker.packageTypes.sort((a, b) => a.index > b.index ? 1 : -1);
    }

    this.marketShareBySSMMData.sort((a, b) => a.index > b.index ? 1 : -1);
    for(var i=0;i<this.marketShareBySSMMData.length;i++){
      let maker = this.marketShareBySSMMData[i];
      maker.packageTypes = maker.packageTypes.filter(x=> x.packageType != 'Other');
    }
    let t1= this;
    setTimeout(() => {
      t1.ready.emit(true);
    });
  }

  public getCategoryColor(category: string){
    return IntageHelper.GetCategoryColor(category);
  }

  public getCategoryDisplayName(category: string){
    return IntageHelper.GetCategoryDisplayName(category);
  }

  public onFilterByChannelChange(){
    if(this.selectedChannel.length==0){
      this.selectedChannel = ['OTC Total'];
    }
    this.handleMarketSizeBySSMM();
    this.handleMarketShareBySSMM();
  }

  public onMarketShareRegionChange(){
    if(this.marketShareSelectedRegion.length == 0){
      this.marketShareSelectedRegion = ['CCBJI Territory'];
    }
    this.handleMarketSizeBySSMM();
    this.handleMarketShareBySSMM();
  }
  public getShareOfCategory(categories: any, category: string){
    return IntageHelper.GetShareOfCategory(categories, category);
  }

  public getVsLyOfCategory(categories: any, category: string){
    return IntageHelper.GetVsLyOfCategory(categories, category);
  }

  public getVsLyOfCategoryForMarketSize(categories: any, category: string){
    return IntageHelper.GetVsLyOfCategoryForMarketSize(categories, category);
  }

  public getImpOfCategory(categories: any, category: string){
    return IntageHelper.GetImpOfCategory(categories, category);
  }

  // FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
  public exportOTCMarketValueShareBySSMS(isImage: boolean) {
    const fileName = 'OTC Market Value Share by Package Size';
    if (isImage) {
      // FPT-ChienNX7 [2022/12/09][SENSE][1203444563290052] Fix bug image download incorrect
      this.scroller.nativeElement.scrollTop = 0;
      this.scroller.nativeElement.scrollLeft = 0;
      setTimeout(() => {
        html2canvas(this.screen.nativeElement).then(canvas => {
          this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
          this.downloadLink.nativeElement.download = fileName;
          this.downloadLink.nativeElement.click();
        });
      }, 500);
    } else {
      let data = [];
      for (var i = 0; i < this.marketSizeBySSMMData.length; i++) {
        let packageType = this.marketSizeBySSMMData[i];
        for (var j = 0; j < packageType.categories.length; j++) {
          let category = packageType.categories[j];
          data.push({
            week: this.selectedWeek,
            aggregateBy: this.aggregateBy == null ? "wtd" : this.aggregateBy,
            channel: this.selectedChannel,
            region: this.marketShareSelectedRegion,
            maker: 'Market Size',
            packageType: packageType.packageType,
            category: category.category,
            ty: category.ty,
            ly: category.ly,
            share: '',
            totalTy: '',
            totalLy: '',
            imp: category.imp,
            vsLy: category.vsLy,
            shareDiff: ''
          });
        }
      }

      for (var i = 0; i < this.marketShareBySSMMData.length; i++) {
        let maker = this.marketShareBySSMMData[i];
        for (var j = 0; j < maker.packageTypes.length; j++) {
          let packageType = maker.packageTypes[j];
          for (var k = 0; k < packageType.categories.length; k++) {
            let category = packageType.categories[k];
            data.push({
              week: this.selectedWeek,
              aggregateBy: this.aggregateBy == null ? "wtd" : this.aggregateBy,
              channel: this.selectedChannel,
              region: this.marketShareSelectedRegion,
              maker: maker.maker,
              packageType: packageType.packageType,
              category: category.category,
              ty: category.ty,
              ly: category.ly,
              share: category.share,
              totalTy: category.totalTy,
              totalLy: category.totalLy,
              imp: '',
              vsLy: '',
              shareDiff: category.vsLy
            });
          }
        }
      }
      new AngularCsv(data, fileName, {
        showLabels: true,
        headers: ["Week", "Aggregate By", "Channel", "Region", "Maker", "Package Type", "Category", "TY_Value", "LY_Value", "Share_Value", "Total_TY_Value", "Total_LY_Value", "IMP", "% vs LY_Value", "Diff_LY_Value"],
      });
    }
    // FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
    this.showOptionDownload();
  }

  showOptionDownload() {
    const download = document.getElementById('download-option-value-share-package');
    if (download.style.display === 'block') {
      download.style.display = 'none';
    } else {
      download.style.display = 'block';
    }
  }

  @ViewChild('pdf_print_area_2', { static: false }) pdf_print_area_2: ElementRef;
  public downloadAsPDF(name) {
    return _downloadAsPDF(name, this.pdf_print_area_2.nativeElement)
  }

}
