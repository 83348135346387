import { Component, EventEmitter, OnInit, OnChanges, SimpleChanges, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, of, from } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { APP_CONFIG } from 'src/app/model/constants';
import { MultiDropdownHelper } from './helpers';
import { PRIORITY_ORDER } from 'src/app/helpers/sort';
import { IOption } from './data';
import { getPageName } from '../../../helpers/utils';
import { SkuDeepdiveHelper } from 'src/app/components/pages/sku-deepdive/sku-deepdive-helper';
import { AreaDeepdiveHelper } from 'src/app/components/pages/area-deepdive/area-deepdive-helper';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
  selector   : 'multi-dropdown',
  templateUrl: './multi-dropdown.component.html',
  styleUrls  : ['./multi-dropdown.component.scss'],
  host       : {
    'class'              : 'ng-section summary-section sense-multi-dropdown',
    '[class.enabled]'    : 'initialState === "enable"',
    '[class.enabledTemp]': 'initialState === "enableTemp"',
    '[class.disabled]'   : 'initialState === "disable"',
    '[class.tentative]'  : 'initialState === "tentative"'
  }
})

export class MultiDropdownComponent implements OnInit, OnChanges {
  @Input() dataTitle?: string;
  @Input() dataDescription?: string;
  @Input() sectionName?: string;
  @Input() initialData: IOption[] = [];
  @Input() initialSelected: any   = [];
  @Input() initialState?: string  = 'enable';
  @Input() keyName: string;
  @Input() filterModuleName?: string;
  @Input() showTooltip?: boolean = true;
  @Input() isHierarchical?: boolean = false;

  @Input() isFetchDataOnSearch?: boolean;
  @Input() dataSortName?: string = '';
  @Input() isNeedSortDisplayValue?: boolean = false;

  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;
  @Output() saveItemsChange = new EventEmitter<{ keyName: string, selectedItems: any }>();
  @Output() convertTentativeToEnableTemp = new EventEmitter<{ keyName: string, name: string }>();
  @Output() convertEnableTempToTentative = new EventEmitter<{ keyName: string, name: string }>();

  bufferItems$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  bufferSelectedItemsSearch: any;
  bufferSize: number = APP_CONFIG?.selectLazyLoadSize || 10;

  selectedItems: any     = [];
  selectedItemsTemp: any = [];

  searchControl: FormControl = new FormControl();
  searchObj: any = {
    data       : [],
    term       : '',
    loading    : false,
    currentPage: 0,
    fetchMore  : true,
    isSearchEmpty: false,
  };

  isLoading: boolean     = false;
  isLazyLoading: boolean = false;
  isSearch: boolean      = false;
  isSearchInputFocused: boolean = false;
  isSaveDuringSearch: boolean   = false;
  isSetSearchLimit: boolean     = false;

  constructor(private http: HttpClient, private mixPanelTrackingService: MixPanelTrackingService) {}

  ngOnInit(): void {
    if (this.isFetchDataOnSearch) {
      this.searchControl.valueChanges.pipe(
        debounceTime(700),
        tap(term => {
          this.searchObj = {
            ...this.searchObj,
            data: [],
            term,
            fetchMore: true,
            currentPage: 0
          };
        }),
        switchMap(term => this._fetchDataFromServer(term))
      ).subscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialData && Array.isArray(this.initialData) && this.initialData.length > 0) {
      if (this.dataSortName.length > 0) {
        this._sortData(this.dataSortName);
      }

      this.isSearch          = this.initialData?.length > this.bufferSize;
      this.isLazyLoading     = this.initialData?.length > this.bufferSize;
      this.selectedItems     = [...this.initialSelected];
      this.selectedItemsTemp = [...this.initialSelected];
      this.bufferItems$.next([...this.initialData.slice(0, this.bufferSize)]);
    }

    if (changes.initialSelected && Array.isArray(this.initialSelected) && this.initialSelected.length > 0) {
      this.selectedItems = [...this.initialSelected];
      this.selectedItemsTemp = [...this.initialSelected];
    }
  }

  public onSelectedItemsChange(event: any): void {
    this.selectedItems = event;
  }

  public onScroll({ end }): void {
    if (this.isLoading) {
      return;
    }

    if (this.searchObj.data.length > 0) {
      return;
    }

    if (this.initialData.length <= this.bufferItems$.value.length) {
      return;
    }

    if (end + 4 >= this.bufferItems$.value.length) {
      this._fetchMore();
    }
  }

  public onScrollToEnd(): void {
    if (this.isFetchDataOnSearch && this.searchObj.data.length > 0 && this.searchObj.fetchMore) {
      this._fetchDataFromServer(this.searchObj.term);
    }
  }

  public onSearchLocal(term: string) {
    if (term && term.trim() !== '') {
      this.searchObj.data = this.initialData.filter(item =>
        item.value.toLowerCase().includes(term.toLowerCase())
      );

      this.searchObj.isSearchEmpty = this.searchObj.data.length === 0;
    } else {
      this.searchObj.data = [...this.initialData];
      this.searchObj.isSearchEmpty = this.searchObj.data.length === 0;
    }
  }

  public customSearchFn(term: string, item: IOption) {
    term = term.toLowerCase();
    return item.value.toLowerCase().indexOf(term) > -1 || item.value.toLowerCase() === term;
  }

  public onBlur(): void {
    if (this.ngSelect) {
      this.ngSelect.close();
    }

    setTimeout(() => {
      if (this.isSearch) {
        this.isSearchInputFocused = false;
        this.isSetSearchLimit     = false;
        this.isSaveDuringSearch   = false;
      }

      this.selectedItems = this.selectedItemsTemp;
      this.searchControl.setValue('');

      this.searchObj = {
        loading: false,
        term   : '',
        data   : [],
        currentPage: 0,
        fetchMore  : false
      }
    });
  }

  public onFocus(): void {
    if (this.isSearch) {
      this.isSearchInputFocused = this.isSearch;
    }
  }

  public onSave(): void {
    if (this.ngSelect) {
      this.ngSelect.close();
    }

    if (this.isSearch) {
      this.isSearchInputFocused = true;
      this.isSaveDuringSearch   = true;
    }

    this.selectedItemsTemp = this.selectedItems;
    this.saveItemsChange.emit(
      {
        keyName: this.keyName,
        selectedItems: this.selectedItems,
      }
    );
  }

  public onAdd(event: IOption): void {
    const { value } = event;

    if (!value) {
      return;
    }

    if (this.isHierarchical) {
      if (!event.parent) {
        this.selectedItems = this.selectedItems.filter(item => !this.initialData.find(data => data.parent === value && data.value === item));
      }

      if (event.parent) {
        this.selectedItems = this.selectedItems.filter(item => item !== event.parent);
      }
    } else {
      if (Array.isArray(this.searchObj.data) && this.searchObj.data.length > 0 && !MultiDropdownHelper.checkObjectExistence(this.initialData, event)) {
        this.initialData = [this.initialData[0], event, ...this.initialData.slice(1)];
        this.bufferItems$.next([...this.initialData.slice(0, this.bufferSize)]);
      }

      this.selectedItems = value === 'All' ? ['All'] : this.selectedItems.filter((e: string) => e !== 'All');
    }
  }

  public onClose(): void {
    if (this.isSearch) {
      setTimeout(() => {
        this.isSearchInputFocused = false;
        this.isSetSearchLimit     = false;
        this.isSaveDuringSearch   = false;
      }, 100);
    }
  }

  public onRemove(event: IOption): void {
    const { value } = event;

    if (!value) {
      return;
    }

    if (this.isHierarchical) {
      if (event.parent) {
        this.selectedItems = this.selectedItems.filter(item => item !== value);
      }

      if (!event.parent) {
        this.selectedItems = this.selectedItems.filter(item => !this.initialData.find(data => data.parent === value && data.value === item));
      }

      if (this.selectedItems.length === 0) {
        this.selectedItems = [...this.initialSelected];
      }
    } else {
      if (this.selectedItems.length === 0) {
        this.selectedItems = ['All'];
      }
    }
  }

  public onConvertTentativeToEnableTemp(): void {
    this.convertTentativeToEnableTemp.emit(
      {
        keyName: this.keyName,
        name: this.dataTitle
      }
    );
  }

  public onConvertEnableTempToTentative(): void {
    this.convertEnableTempToTentative.emit(
      {
        keyName: this.keyName,
        name: this.dataTitle
      }
    );
  }

  public trackByFn(item: IOption) {
    return item.value || undefined;
  }

  private _sortData(sortType: string): any {
    this.initialData = [...MultiDropdownHelper.sortArrayByPriority([...this.initialData], PRIORITY_ORDER[sortType])];
  }

  private _fetchDataFromServer = async (term: string) => {
    if (term && term.trim() !== '') {
      if (this.searchObj.fetchMore) {
        this.searchObj = {
          ...this.searchObj,
          loading: true,
          currentPage: this.searchObj.currentPage + 1,
        };

        const pageName = getPageName(this.filterModuleName) || '';

        if (!pageName) {
          this.searchObj = {
            ...this.searchObj,
            loading: false,
            isSearchEmpty: true
          }
          return of(null);
        }

        const fetchDataObservable = pageName === 'sku-deepdive'
          ? SkuDeepdiveHelper.fetchDataOnSearch(this.http, this.keyName, term, this.searchObj.currentPage)
          : AreaDeepdiveHelper.fetchDataOnSearch(this.http, this.keyName, term, this.searchObj.currentPage);

        return from(fetchDataObservable).pipe(
          tap(data => {
            const newData   = data[this.keyName] || [];
            const searchKey = data['Selected'][this.keyName][0] || '';

            if (searchKey === this.searchObj.term) {
              this.searchObj.loading = false;

              if (newData.length > 0) {
                this.searchObj = {
                  ...this.searchObj,
                  data: [
                    ...this.searchObj.data,
                    ...newData.map((item: string) => ({ value: item || 'NULL', text: item || 'NULL' }))
                  ],
                  fetchMore: newData.length >= 100 ? true : false,
                  isSearchEmpty: false,
                }
              } else {
                this.searchObj.isSearchEmpty = true;
              }
            }
          })
        ).subscribe();
      } else {}
    } else {
      this.searchObj.isSearchEmpty = false;
      this.searchObj.data = [];
      return of(null);
    }
  }

  private _fetchMore(): void {
    if (this.isLoading) {
      return;
    }

    const currentDataLength = this.bufferItems$.value.length;
    const moreData = this.initialData.slice(currentDataLength, currentDataLength + this.bufferSize);

    this.isLoading = true;

    setTimeout(() => {
        this.isLoading = false;
        this.bufferItems$.next([...this.bufferItems$.value, ...moreData]);
    }, 200)
  }

  // Mixpanel Tracking
  public onMixpanelTracking(): void {
    this.mixPanelTrackingService.onTrackDropdownAggregate(this.sectionName, this.dataTitle, this.selectedItems);
  }

  // Render Classes
  public getClasses(): any {
    return {
      'sense-ng-select-focused-search': this.isSearchInputFocused,
      'sense-ng-select-search-dropdown-limit': this.isSetSearchLimit,
      'sense-ng-select-save-during-search': this.isSaveDuringSearch,
      'sense-ng-select-has-search': this.isSearch,
      'sense-ng-select-fetch-search-loading': this.searchObj.loading,
      'sense-ng-select-fetch-search-loading-with-items': this.searchObj.loading && this.searchObj.data.length > 0
    };
  }
}
