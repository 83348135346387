import { Injectable } from '@angular/core';
import  { localStorageHelper } from 'src/app/helpers/localStorage';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
      localStorageHelper.clear();
      document.querySelector('.modal-backdrop')?.remove();
			this.router.navigateByUrl('authentication/login');
        }
        return throwError(error);
      })
    );
  }
}
