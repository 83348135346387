import * as tsvParse from "d3-dsv";
import { environment } from 'src/environments/environment';
import { AnalyticsQueries } from 'src/app/model/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { apiUrl, FILTER_CONFIGS_HQ } from 'src/app/model/constants';
import { isObjEmpty, objectToQueryString, openWarningPopup, closeWarningPopup } from 'src/app/helpers/utils';

export class HQProgressHelper {
  private static salesDateSubject = new BehaviorSubject<any>(null);
  private static dateInJobClearCache = new BehaviorSubject<string>('');
  private static csvLoadingSubject = new BehaviorSubject<boolean>(false);
  private static csvLoadingCount   = 0;
  private static userGroupName = '';

  static setCsvLoading(loading: boolean) {
    return this.csvLoadingSubject.next(loading);
  }

  static isCsvLoading() {
      return this.csvLoadingSubject.asObservable();
  }

  static startCsvLoading() {
      this.csvLoadingCount++;
      this.csvLoadingSubject.next(true);
  }

  static stopCsvLoading() {
      this.csvLoadingCount--;
      if (this.csvLoadingCount === 0) {
            this.csvLoadingSubject.next(false);
      }
  }

  static setSalesDate(value: string[]) {
    return this.salesDateSubject.next(value);
  }

  static getSalesDate() {
    return this.salesDateSubject.asObservable();
  }

  static setDateInJobClearCache(value: string) {
    return this.dateInJobClearCache.next(value);
  }

  static getDateInJobClearCache() {
    return this.dateInJobClearCache.asObservable();
  }

  static HQLevel1SetQueryString(lstOptionSelected: object,
                                pagingCurrent: number,
                                sortType: string,
                                sortColumn: string,
                                isExportAllData: boolean = false) {
    const analyticsQuery = {
      IsDefaultPage: false,
      IsExportAllData: isExportAllData,
      Page      : pagingCurrent,
      SortType  : sortType,
      SortBy    : sortColumn,
      Selected  : {},
      UnSelected: {},
    };

    let cacheKey = `IsDefaultPage=false&IsExportAllData=${isExportAllData}&Page=${pagingCurrent}&SortType=${sortType}&SortBy=${sortColumn}`;

    if (lstOptionSelected) {
      for (const key in lstOptionSelected) {
        if (lstOptionSelected.hasOwnProperty(key)) {
          if (!this.isAllSelected(lstOptionSelected[key])) {
            analyticsQuery['Selected'][key] = lstOptionSelected[key];
          }
        }
      }
    }

    if (!isObjEmpty(analyticsQuery?.Selected)) {
      cacheKey += `&${objectToQueryString(analyticsQuery['Selected'])}`;
    }

    if (!isObjEmpty(analyticsQuery?.UnSelected)) {
      cacheKey += `&${objectToQueryString(analyticsQuery['UnSelected'])}`;
    }

    analyticsQuery['CacheKey'] = cacheKey;
    return analyticsQuery;
}

  static HQLevel2SetQueryString(lstOptionSelected: object,
                                pagingCurrent: number,
                                sortType: string,
                                sortColumn: string,
                                isExportAllData: boolean = false) {
    const analyticsQuery = {
      IsDefaultPage: false,
      IsExportAllData: isExportAllData,
      Page      : pagingCurrent,
      SortType  : sortType,
      SortBy    : sortColumn,
      Selected  : {},
      UnSelected: {},
    };

    let cacheKey = `IsDefaultPage=false&IsExportAllData=${isExportAllData}&Page=${pagingCurrent}&SortType=${sortType}&SortBy=${sortColumn}`;

    if (lstOptionSelected) {
      for (const key in lstOptionSelected) {
        if (lstOptionSelected.hasOwnProperty(key)) {
          if (!this.isAllSelected(lstOptionSelected[key])) {
            analyticsQuery['Selected'][key] = lstOptionSelected[key];
          }
        }
      }
    }

    if (!isObjEmpty(analyticsQuery?.Selected)) {
      cacheKey += `&${objectToQueryString(analyticsQuery['Selected'])}`;
    }

    if (!isObjEmpty(analyticsQuery?.UnSelected)) {
      cacheKey += `&${objectToQueryString(analyticsQuery['UnSelected'])}`;
    }

    analyticsQuery['CacheKey'] = cacheKey;
    return analyticsQuery;
  }

  static HQTimeLineSetQueryString(aggregateBy: string, lstOptionSelected: object) {
    const analyticsQuery = {
      IsDefaultPage: false,
      AggregateBy  : aggregateBy,
      Selected  : {},
      UnSelected: {}
    };

    let cacheKey = `IsDefaultPage=false&AggregateBy=${aggregateBy}`;

    if (lstOptionSelected) {
      for (const key in lstOptionSelected) {
        if (lstOptionSelected.hasOwnProperty(key) && key !='AggregateBy') {
          if (!this.isAllSelected(lstOptionSelected[key])) {
            analyticsQuery['Selected'][key] = lstOptionSelected[key];
          }
        }
      }
    }
    if (!isObjEmpty(analyticsQuery?.Selected)) {
      cacheKey += `&${objectToQueryString(analyticsQuery['Selected'])}`;
    }

    if (!isObjEmpty(analyticsQuery?.UnSelected)) {
      cacheKey += `&${objectToQueryString(analyticsQuery['UnSelected'])}`;
    }

    analyticsQuery['CacheKey'] = cacheKey;
    return analyticsQuery;
  }

  private static lstAllOptionsData = new BehaviorSubject<any>(null);

  static isAllSelected = (arr1: any) => {
    return JSON.stringify(arr1) === JSON.stringify(['All']);
  }

  static getLstAllOptionsData() {
    return this.lstAllOptionsData.asObservable();
  }


  static GetTotalRow(listGroupNameRoot: string[], rawData : any[] , rootLevel : number , keyOfRoot1 : string , keyOfRoot2 : string)
  {
    let totalRoot = [];
    listGroupNameRoot.forEach(element => {
        let returnModel = {
            IS_TOTALROW : true,
            KEYOFROOT : element,
            ROOTLEVEL: rootLevel,
            SALES_HQ_LEV1_NAME : rootLevel== 1 ? element :  '',
            SALES_HQ_LEV2_NAME : rootLevel== 1 ? element :  '',
            CHANNEL_LEV2_NAME :rootLevel== 1 ? 'Total' : element.split("||")[1],
            CHANNEL_LEV3_NAME :rootLevel== 1? '': 'Total',
            BAPC_TY : null,
            BAPC_VS_BP : 0,
            BAPC_DIFF_VS_BP : null,
            BAPC_VS_LY : 0,
            NSR_TY : null,
            NSR_VS_BP :0,
            NSR_DIFF_VS_BP :null,
            NSR_VS_LY : 0,
            NSR_PER_CASE_TY : 0,
            NSR_PER_CASE_BP : 0,
            NSR_PER_CASE_LY : 0,
            NSR_PER_CASE_DIFF_VS_BP : null,
            NSR_PER_CASE_DIFF_VS_LY :null,
            GP_TY : null,
            GP_VS_BP : 0,
            GP_DIFF_VS_BP : null,
            GP_VS_LY : 0,
            GP_PER_CASE_TY :0,
            GP_PER_CASE_BP :0,
            GP_PER_CASE_DIFF_VS_BP : null,
            GP_PER_CASE_DIFF_VS_LY : null,
            GP_PER_CASE_LY : 0,
            BAPC_BP: 0,
            BAPC_LY: 0,
            NSR_BP: 0,
            NSR_LY: 0,
            GP_BP: 0,
            GP_LY: 0
          };
          rawData.forEach(hqitem => {
            var elementArray =element?  element.split('||') : '';
            var keyOfRootArray = hqitem['KEYOFROOT'] ? hqitem['KEYOFROOT'].split('||') :'';

            if((rootLevel == 1 && keyOfRootArray[0] == element)
            || (rootLevel == 2 && elementArray[0]==hqitem[keyOfRoot1] && elementArray[1]==hqitem[keyOfRoot2]))
          {

              if(hqitem["BAPC_TY"]!== ''){
                returnModel.BAPC_TY +=!hqitem["BAPC_TY"]? 0: parseFloat(hqitem["BAPC_TY"]);
              }

              // if(hqitem["BAPC_DIFF_VS_BP"] !=='' && hqitem["BAPC_DIFF_VS_BP"]!=null){
              //   returnModel.BAPC_DIFF_VS_BP +=!hqitem["BAPC_DIFF_VS_BP"]? 0: parseFloat(hqitem["BAPC_DIFF_VS_BP"]);
              // }

              if(hqitem["NSR_TY"]!==''){
                returnModel.NSR_TY +=!hqitem["NSR_TY"]? 0: parseFloat(hqitem["NSR_TY"]);
              }

              // if(hqitem["NSR_DIFF_VS_BP"]!== '' && hqitem["NSR_DIFF_VS_BP"]!== null){
              //   returnModel.NSR_DIFF_VS_BP +=!hqitem["NSR_DIFF_VS_BP"]? 0: parseFloat(hqitem["NSR_DIFF_VS_BP"]);
              // }

              //returnModel.NSR_PER_CASE_TY +=!hqitem["NSR_PER_CASE_TY"]? 0: parseFloat(hqitem["NSR_PER_CASE_TY"]);
              //returnModel.NSR_PER_CASE_BP +=!hqitem["NSR_PER_CASE_BP"]? 0: parseFloat(hqitem["NSR_PER_CASE_BP"]);
              //returnModel.NSR_PER_CASE_LY +=!hqitem["NSR_PER_CASE_LY"]? 0: parseFloat(hqitem["NSR_PER_CASE_LY"]);
              //returnModel.NSR_PER_CASE_DIFF_VS_BP +=!hqitem["NSR_PER_CASE_DIFF_VS_BP"]? 0: parseFloat(hqitem["NSR_PER_CASE_DIFF_VS_BP"]);
              //returnModel.NSR_PER_CASE_DIFF_VS_LY +=!hqitem["NSR_PER_CASE_DIFF_VS_LY"]? 0: parseFloat(hqitem["NSR_PER_CASE_DIFF_VS_LY"]);

              if(hqitem["GP_TY"]!== ''){
                returnModel.GP_TY +=!hqitem["GP_TY"]? 0: parseFloat(hqitem["GP_TY"]);
              }

              // if(hqitem["GP_DIFF_VS_BP"]!== '' && hqitem["GP_DIFF_VS_BP"]!== null){
              //   returnModel.GP_DIFF_VS_BP +=!hqitem["GP_DIFF_VS_BP"]? 0: parseFloat(hqitem["GP_DIFF_VS_BP"]);
              // }

              //returnModel.GP_PER_CASE_TY +=!hqitem["GP_PER_CASE_TY"]? 0: parseFloat(hqitem["GP_PER_CASE_TY"]);
              //returnModel.GP_PER_CASE_BP +=!hqitem["GP_PER_CASE_BP"]? 0: parseFloat(hqitem["GP_PER_CASE_BP"]);
              //returnModel.GP_PER_CASE_DIFF_VS_BP +=!hqitem["GP_PER_CASE_DIFF_VS_BP"]? 0: parseFloat(hqitem["GP_PER_CASE_DIFF_VS_BP"]);
              //returnModel.GP_PER_CASE_DIFF_VS_LY +=!hqitem["GP_PER_CASE_DIFF_VS_LY"]? 0: parseFloat(hqitem["GP_PER_CASE_DIFF_VS_LY"]);

              if(hqitem["BAPC_BP"]!==''){
                returnModel.BAPC_BP +=!hqitem["BAPC_BP"]? 0: parseFloat(hqitem["BAPC_BP"]);
              }

              if(hqitem["BAPC_LY"]!== ''){
                returnModel.BAPC_LY +=!hqitem["BAPC_LY"]? 0: parseFloat(hqitem["BAPC_LY"]);
              }

              if(hqitem["NSR_BP"]!== ''){
                returnModel.NSR_BP +=!hqitem["NSR_BP"]? 0: parseFloat(hqitem["NSR_BP"]);
              }

              if(hqitem["NSR_LY"] !== ''){
                returnModel.NSR_LY +=!hqitem["NSR_LY"]? 0: parseFloat(hqitem["NSR_LY"]);
              }

              if(hqitem["GP_BP"] !==''){
                returnModel.GP_BP +=!hqitem["GP_BP"]? 0: parseFloat(hqitem["GP_BP"]);
              }

              if(hqitem["GP_LY"] !==''){
                returnModel.GP_LY +=!hqitem["GP_LY"]? 0: parseFloat(hqitem["GP_LY"]);
              }
            }
          });

          returnModel.BAPC_DIFF_VS_BP = !returnModel.BAPC_BP ? null :  returnModel.BAPC_TY - returnModel.BAPC_BP;

          returnModel.NSR_DIFF_VS_BP = !returnModel.NSR_BP ?   null :  returnModel.NSR_TY - returnModel.NSR_BP;
          returnModel.GP_DIFF_VS_BP = !returnModel.GP_BP?   null : returnModel.GP_TY - returnModel.GP_BP;


          returnModel.NSR_PER_CASE_TY =!returnModel.BAPC_TY ? null : returnModel.NSR_TY/returnModel.BAPC_TY;
          returnModel.NSR_PER_CASE_BP =!returnModel.BAPC_BP ? null : returnModel.NSR_BP/returnModel.BAPC_BP;
          returnModel.NSR_PER_CASE_LY =!returnModel.BAPC_LY ? null : returnModel.NSR_LY/returnModel.BAPC_LY;

          if(returnModel.NSR_PER_CASE_TY!==null && returnModel.NSR_PER_CASE_BP!==null){
            returnModel.NSR_PER_CASE_DIFF_VS_BP = returnModel.NSR_PER_CASE_TY-returnModel.NSR_PER_CASE_BP;
          }

          if(returnModel.NSR_PER_CASE_TY!==null && returnModel.NSR_PER_CASE_LY!==null){
            returnModel.NSR_PER_CASE_DIFF_VS_LY = returnModel.NSR_PER_CASE_TY-returnModel.NSR_PER_CASE_LY;
          }

          returnModel.GP_PER_CASE_TY =!returnModel.BAPC_TY ? null : returnModel.GP_TY/returnModel.BAPC_TY;
          returnModel.GP_PER_CASE_BP =!returnModel.BAPC_BP ? null : returnModel.GP_BP/returnModel.BAPC_BP;
          returnModel.GP_PER_CASE_LY =!returnModel.BAPC_LY ? null : returnModel.GP_LY/returnModel.BAPC_LY;

          if(returnModel.GP_PER_CASE_TY!==null &&  returnModel.GP_PER_CASE_BP!==null){
            returnModel.GP_PER_CASE_DIFF_VS_BP = returnModel.GP_PER_CASE_TY-returnModel.GP_PER_CASE_BP ;
          }

          if(returnModel.GP_PER_CASE_TY!==null && returnModel.GP_PER_CASE_LY!==null){
            returnModel.GP_PER_CASE_DIFF_VS_LY = returnModel.GP_PER_CASE_TY-returnModel.GP_PER_CASE_LY ;
          }

          returnModel.BAPC_VS_BP =!returnModel.BAPC_BP ?  null : returnModel.BAPC_TY/returnModel.BAPC_BP -1 ;
          returnModel.BAPC_VS_LY =!returnModel.BAPC_LY ?  null : returnModel.BAPC_TY/returnModel.BAPC_LY -1;
          returnModel.NSR_VS_BP =!returnModel.NSR_BP?  null : returnModel.NSR_TY/returnModel.NSR_BP -1;
          returnModel.NSR_VS_LY =!returnModel.NSR_LY ?  null : returnModel.NSR_TY/returnModel.NSR_LY -1;
          returnModel.GP_VS_BP =!returnModel.GP_BP ?  null : returnModel.GP_TY/returnModel.GP_BP -1;
          returnModel.GP_VS_LY =!returnModel.GP_LY ?  null : returnModel.GP_TY/returnModel.GP_LY -1;

          totalRoot.push(returnModel);
      });
      return totalRoot;
  }

  static FormartNumber(category:string, number : any){
    if((number== null || number =='null' || number === '')
    && (category!='SALES_HQ_LEV1_NAME' && category!= "SALES_HQ_LEV2_NAME" && category != "CHANNEL_LEV2_NAME"&& category != "CHANNEL_LEV3_NAME"))
    {
      return " - ";
    }
    switch(category) {

      case 'BAPC_TY':
        var num_negative = parseFloat(number);
        if (isNaN(num_negative)) {
            return number;
        }

        num_negative = Math.round(num_negative);

        if(num_negative == 0){
          return "0K";
        }
        if (num_negative < 1000 && num_negative > -1000) {
          return num_negative.toString();
        }
        const formattedSignValue = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.abs(num_negative)  / 1000);


        const signK_negative =num_negative== 0 ? '' : num_negative < 0 ? '-' : '';
        return `${signK_negative}${formattedSignValue}K`;

      case 'BAPC_DIFF_VS_BP':
        //(+/-) K
        const num = parseFloat(number);
        if (isNaN(num)) {
            return number;
        }
        if (num < 1000 && num > -1000) {
          return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.round(num));
        }
        const formattedValue = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.abs(num) / 1000);
        const signK =num== 0 ? '' : num < 0 ? '-' : '+';
        return `${signK}${formattedValue}K`;
      case 'BAPC_VS_BP':
      case 'BAPC_VS_LY' :
      case 'NSR_VS_BP' :
      case 'NSR_VS_LY' :
      case 'GP_VS_BP' :
      case 'GP_VS_LY' :
          //(+/-) %
          const  numper = parseFloat(number);
            if (isNaN(numper)) {
                return number;
            }
          const formattedPer: string = (numper * 100).toFixed(1) + "%";
          const sign1 =numper== 0 ? '' : numper < 0 ? '' : '+';
          return sign1+formattedPer;
      case 'NSR_PER_CASE_TY':
      case 'GP_PER_CASE_TY':
          //¥
          const  numperYenNoSign = parseFloat(number);
          if (isNaN(numperYenNoSign)) {
              return number;
          }

          const formattedYenNoSign = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.abs(numperYenNoSign));
          const signKYenNoSign =numperYenNoSign== 0 ? '' : numperYenNoSign < 0 ? '-' : '';
          return `${signKYenNoSign}¥${formattedYenNoSign}`;

      case 'NSR_PER_CASE_DIFF_VS_BP':
      case 'GP_PER_CASE_DIFF_VS_BP':
      case 'GP_PER_CASE_DIFF_VS_LY':
      case 'NSR_PER_CASE_DIFF_VS_LY':
          //(+/-) ¥
          const  numperYen = parseFloat(number);
          if (isNaN(numperYen)) {
              return number;
          }

          const formattedYen = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.abs(numperYen));
          const signYen =numperYen== 0 ? '' : numperYen < 0 ? '-' : '+';
          return `${signYen}¥${formattedYen}`;
       case 'NSR_DIFF_VS_BP' :
       case 'GP_DIFF_VS_BP':
          //(+/-) + ¥ + M
          const  numperYenM = parseFloat(number);
          if (isNaN(numperYenM)) {
              return number;
          }
          const formattedYenM = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.abs(numperYenM)/1000000);
          const sign =numperYenM== 0 ? '' : numperYenM < 0 ? '-' : '+';
          return `${sign}¥${formattedYenM}M`;
        case 'GP_TY':
        case 'NSR_TY' :
          //(-) + ¥ + M
          const  numperYenM_negative = parseFloat(number);
          if (isNaN(numperYenM_negative)) {
              return number;
          }
          const formattedYenM_negative = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.abs(numperYenM_negative)/1000000);
          const sign_negative =numperYenM_negative== 0 ? '' : numperYenM_negative < 0 ? '-' : '';
          return `${sign_negative}¥${formattedYenM_negative}M`;
      default:
        return number;
    }
  }

  static formartClassForColumn(isLunisolarColor: boolean, value : any)
  {
    if(!isLunisolarColor || value == null || value == undefined || value == ''){
      return '';
    }
    else{
      return value.toString().indexOf('-') == 0? 'text-red': 'text-green';
    }
  }

  static GetHQLevel1DataBySaleDate(http: HttpClient, startDate: string, endDate: string, selectedChannelLevel: string, analyticsQuery: object,isExport: boolean = false) : Promise<any>{
    const bodyObj = {
      ...analyticsQuery,
      // NoCache: true, // only on Develop
      Code: AnalyticsQueries.hqProgressLeve1BySaleDate,
      StartDate: startDate,
      EndDate: endDate,
      CacheKey: `?code=${AnalyticsQueries.hqProgressLeve1BySaleDate}&IsExport=${isExport}&StartDate=${startDate}&EndDate=${endDate}&GroupByLevel=${selectedChannelLevel}&${analyticsQuery['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
      IsExport : isExport
    };

    if (selectedChannelLevel && selectedChannelLevel.length > 0) {
      bodyObj['GroupByLevel'] = selectedChannelLevel;
      bodyObj['CacheKey'] = `${bodyObj['CacheKey']}&GroupByLevel=${selectedChannelLevel}&LatestUpdate=${this.dateInJobClearCache.value}`;
    } else {
      bodyObj['CacheKey'] = `${bodyObj['CacheKey']}&LatestUpdate=${this.dateInJobClearCache.value}`;
    }

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    if (isExport) {
      openWarningPopup();
    }

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
              resolve(JSON.parse(response));

              if (isExport) {
                closeWarningPopup();
              }
          },
          error => {
            console.log(error);

            if (isExport) {
              closeWarningPopup();
            }
          }
        );
    });
  }

  static GetHQLevel2DataBySaleDate(http: HttpClient, startDate:string, endDate:string, selectedChannelLevel: string ,analyticsQuery: object,isExport: boolean = false) : Promise<any>{
    const bodyObj = {
      ...analyticsQuery,
      // NoCache: true, // only on Develop
      Code: AnalyticsQueries.hqProgressLeve2BySaleDate,
      StartDate: startDate,
      EndDate: endDate,
      CacheKey: `?code=${AnalyticsQueries.hqProgressLeve2BySaleDate}&IsExport=${isExport}&StartDate=${startDate}&EndDate=${endDate}&${analyticsQuery['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
      IsExport : isExport
    };

    if (selectedChannelLevel && selectedChannelLevel.length > 0) {
      bodyObj['GroupByLevel'] = selectedChannelLevel;
      bodyObj['CacheKey'] = `${bodyObj['CacheKey']}&GroupByLevel=${selectedChannelLevel}&LatestUpdate=${this.dateInJobClearCache.value}`;
    } else {
      bodyObj['CacheKey'] = `${bodyObj['CacheKey']}&LatestUpdate=${this.dateInJobClearCache.value}`;
    }

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    if (isExport) {
      openWarningPopup();
    }

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
            resolve(JSON.parse(response));

            if (isExport) {
              closeWarningPopup();
            }
          },
          error => {
            console.log(error);

            if (isExport) {
              closeWarningPopup();
            }
          }
        );
      });
    }


    static GetHQNationalLocalDataBySaleDate(http: HttpClient, startDate:string, endDate:string, analyticsQuery: object,isExport: boolean = false) : Promise<any>{
      const bodyObj = {
        ...analyticsQuery,
        // NoCache: true, // only on Develop
        Code: AnalyticsQueries.hqprogressnationallocalSaleDate,
        StartDate: startDate,
        EndDate: endDate,
        CacheKey: `?code=${AnalyticsQueries.hqprogressnationallocalSaleDate}&IsExport=${isExport}&StartDate=${startDate}&EndDate=${endDate}&${analyticsQuery['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
        IsExport : isExport
      };
  
      bodyObj['CacheKey'] = `${bodyObj['CacheKey']}&LatestUpdate=${this.dateInJobClearCache.value}`;

      const httpOptions: Object = {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
        responseType: 'text'
      };

      if (isExport) {
        openWarningPopup();
      }

      return new Promise<any>((resolve, reject) => {
        http.post<any>(apiUrl, bodyObj, httpOptions)
          .subscribe(
            response => {
              resolve(JSON.parse(response));

              if (isExport) {
                closeWarningPopup();
              }
            },
            error => {
              console.log(error);

              if (isExport) {
                closeWarningPopup();
              }
            }
          );
        });
      }

  static GetHQTimelineDataByAggregateKey(http: HttpClient, analyticsQuery: object, startDate: any, endDate: any, isExport: boolean = false) : Promise<any>{
    const bodyObj = {
      ...analyticsQuery,
      Code: AnalyticsQueries.hqProgressTimeline,
      StartDate: startDate,
      EndDate: endDate,
      CacheKey: `?code=${AnalyticsQueries.hqProgressTimeline}&IsExport=${isExport}&StartDate=${startDate}&EndDate=${endDate}&${analyticsQuery['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}&LatestUpdate=${this.dateInJobClearCache.value}`,
      IsExport: isExport
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    if (isExport) {
      openWarningPopup();
    }

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
            const timelineData = JSON.parse(response);
            window['HQTimelineData'] = timelineData;
            resolve(timelineData);

            if (isExport) {
              closeWarningPopup();
            }
          },
          error => {
            console.log(error);

            if (isExport) {
              closeWarningPopup();
            }
          }
        );
    });
  }

        static GetAllFilterOptions(http: HttpClient, dateInJobClearCache: any, groupName: string) : Promise<any>{
          if (this.dateInJobClearCache.value.length <= 0 && dateInJobClearCache.length > 0) {
            this.dateInJobClearCache.next(dateInJobClearCache);
          }

          if (!this.userGroupName.length && groupName.length > 0) {
            this.userGroupName = groupName;
          }

          const bodyObj = {
            Code: AnalyticsQueries.hqProgressallfilteroptions,
            CacheKey: `?code=${AnalyticsQueries.hqProgressallfilteroptions}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
          };

          const httpOptions: Object = {
            headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
            responseType: 'text'
          };

          return new Promise<any>((resolve, reject) => {
            http.post<any>(apiUrl, bodyObj, httpOptions)
              .subscribe(
                response => {
                  const data = JSON.parse(response);

                  const data2 = { DATE_TYPE: ['Daily', 'MTD', 'Monthly', 'QTD', 'YTD', 'Specific Date']};
                  this.setSalesDate(data2?.DATE_TYPE);

                  this.lstAllOptionsData.next({
                    filters: FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.HQ_PROGRESS?.COLLECTIONS || [],
                    data: data
                  });

                  resolve(data);
                },
                error => {
                  console.log(error);
                }
              );
          });
    }

    static distinctName(value, index, self) {
          return self.indexOf(value) === index;
    }

    static distinctArray(inputArray: any[]): any[] {
      const uniqueValues = new Set(inputArray.map(item => item.value));

      const resultArray: any[] = Array.from(uniqueValues).map(value => {
        const correspondingItem = inputArray.find(item => item.value === value);
        return correspondingItem || { value, text: '' };
      });

      return resultArray;
    }

    static hanldeCheckBoxSelect(event) {
      setTimeout(() => {
          let checkboxs = document.querySelectorAll('.ng-option-selected input[type=checkbox]');
          checkboxs.forEach(e => {
              var element = e as HTMLInputElement | null;
              element.checked = true;
          });
          let notcheckboxs = document.querySelectorAll('.ng-option:not(.ng-option-selected) input[type=checkbox]');
          notcheckboxs.forEach(e => {
              var element = e as HTMLInputElement | null;
              element.checked = false;
          });
      }, 100);
    }

    static ListColumnHQLevel1(){
      return [
          {
          category: "SALES_HQ_LEV1_NAME",
          displayName: "Sales HQ Lev1 Name",
          color: "#8B90A3",
          index: 1,
          isSummarySum: true,
          enableSort: false,
          },
          {
            category: "CHANNEL_LEV1_NAME",
            displayName: "Channel Lev1 Name",
            color: "#C69C83",
            index: 2,
            isSummarySum: true,
            enableSort: false,
            isDisplay: true
          },
          {
          category: "CHANNEL_LEV2_NAME",
          displayName: "Channel Lev2 Name",
          color: "#C69C83",
          index: 3,
          isSummarySum: true,
          enableSort: false,
          isDisplay: true
          },
          {
          category: "CHANNEL_LEV3_NAME",
          displayName: "Channel Lev3 Name",
          color: "#93AFB9",
          index: 4,
          enableSort: false,
          isDisplay: true
          },
          {
            category: "BAPC_TY",
            displayName: "Act", // BAPC TY
            color: "#4DA2D6",
            index: 5
          },
          {
            category: "BAPC_VS_BP",
            displayName: "% vs BP", // BAPC% VS BP
            color: "#FCCF4D",
            index: 6,
            isLunisolarColor : true
          },
          {
            category: "BAPC_DIFF_VS_BP",
            displayName: "Diff vs BP", // BAPC DIFF VS BP
            color: "#9A64A7",
            index: 7,
            isLunisolarColor : true
          },
          {
            category: "BAPC_VS_LY",
            displayName: "% vs LY", // BAPC% VS LY
            color: "#4FB9C1",
            index: 8,
            isLunisolarColor : true
          },
          {
            category: "NSR_TY",
            displayName: "Act", // NSR TY
            color: "#F08258",
            index: 9
          },
          {
            category: "NSR_VS_BP",
            displayName: "% vs BP", // NSR% VS BP
            color: "#F40009",
            index: 10,
            isLunisolarColor : true
          },
          {
            category: "NSR_DIFF_VS_BP",
            displayName: "Diff vs BP", // NSR DIFF VS BP
            color: "#A09498",
            index: 11,
            isLunisolarColor : true
          },
          {
            category: "NSR_VS_LY",
            displayName: "% vs LY", // NSR% VS LY
            color: "#BCC3D1",
            index: 11,
            isLunisolarColor : true
          },
          {
            category: "NSR_PER_CASE_TY",
            displayName: "NSR PER CASE TY",
            color: "#BCC3D1",
            index: 12,
            isDisplay: false
          },
          {
            category: "NSR_PER_CASE_BP",
            displayName: "NSR PER CASE BP",
            color: "#BCC3D1",
            index: 13,
            isDisplay: false,
          },
          {
            category: "NSR_PER_CASE_LY",
            displayName: "NSR PER CASE LY",
            color: "#BCC3D1",
            index: 14,
            isDisplay: false
          },
          {
            category: "NSR_PER_CASE_DIFF_VS_BP",
            displayName: "NSR PER CASE DIFF VS BP",
            color: "#BCC3D1",
            index: 15,
            isLunisolarColor : true,
            isDisplay: false
          },
          {
            category: "NSR_PER_CASE_DIFF_VS_LY",
            displayName: "NSR PER CASE DIFF VS LY",
            color: "#BCC3D1",
            index: 16,
            isLunisolarColor : true,
            isDisplay: false
          },
          {
            category: "GP_TY",
            displayName: "Act", // GP TY
            color: "#BCC3D1",
            index: 17
          },
          {
            category: "GP_VS_BP",
            displayName: "% vs BP", // GP% VS BP
            color: "#BCC3D1",
            index: 18,
            isLunisolarColor : true
          },
          {
            category: "GP_DIFF_VS_BP",
            displayName: "Diff vs BP", // GP DIFF VS BP
            color: "#BCC3D1",
            index: 19,
            isLunisolarColor : true
          },
          {
            category: "GP_VS_LY",
            displayName: "% vs LY", // GP% VS LY
            color: "#BCC3D1",
            index: 20,
            isLunisolarColor : true
          },
          {
            category: "GP_PER_CASE_TY",
            displayName: "GP PER CASE TY",
            color: "#BCC3D1",
            index: 21,
            isDisplay: false
          },
          {
            category: "GP_PER_CASE_BP",
            displayName: "GP PER CASE BP",
            color: "#BCC3D1",
            index: 22,
            isDisplay: false
          },
          {
            category: "GP_PER_CASE_DIFF_VS_BP",
            displayName: "GP PER CASE DIFF VS BP",
            color: "#BCC3D1",
            index: 23,
            isLunisolarColor : true,
            isDisplay: false
          },
          {
            category: "GP_PER_CASE_DIFF_VS_LY",
            displayName: "GP PER CASE DIFF VS LY",
            color: "#BCC3D1",
            index: 24,
            isLunisolarColor : true,
            isDisplay: false
          },
          {
            category: "BAPC_BP",
            displayName: "BAPC BP",
            color: "#BCC3D1",
            index: 22,
            isDisplay: false
          },
          {
            category: "BAPC_LY",
            displayName: "BAPC LY",
            color: "#BCC3D1",
            index: 22,
            isDisplay: false
          },
          {
            category: "NSR_BP",
            displayName: "NSR BP",
            color: "#BCC3D1",
            index: 22,
            isDisplay: false
          },
          {
            category: "NSR_LY",
            displayName: "NSR LY",
            color: "#BCC3D1",
            index: 22,
            isDisplay: false
          },
          {
            category: "GP_BP",
            displayName: "GP BP",
            color: "#BCC3D1",
            index: 22,
            isDisplay: false
          },
          {
            category: "GP_LY",
            displayName: "GP LY",
            color: "#BCC3D1",
            index: 22,
            isDisplay: false
          }
      ];
   }


   static ListColumnHQLevel2(){
    return [
        {
        category: "SALES_HQ_LEV2_NAME",
        displayName: "Sales HQ Lev2 Name",
        color: "#8B90A3",
        index: 1,
        isSummarySum: true,
        textAlign: 'left',
        enableSort: false,
        },
        {
          category: "CHANNEL_LEV1_NAME",
          displayName: "Channel Lev1 Name",
          color: "#C69C83",
          index: 2,
          isSummarySum: true,
          enableSort: false,
          isDisplay: true
        },
        {
        category: "CHANNEL_LEV2_NAME",
        displayName: "Channel Lev2 Name",
        color: "#C69C83",
        index: 3,
        isSummarySum: true,
        textAlign: 'left',
        enableSort: false,
        },
        {
        category: "CHANNEL_LEV3_NAME",
        displayName: "Channel Lev3 Name",
        color: "#93AFB9",
        index: 4,
        textAlign: 'left',
        enableSort: false,
        },
        {
          category: "BAPC_TY",
          displayName: "Act", // BAPC TY
          color: "#4DA2D6",
          index: 5
        },
        {
          category: "BAPC_VS_BP",
          displayName: "% vs BP", // BAPC% VS BP
          color: "#FCCF4D",
          index: 6,
          isLunisolarColor : true
        },
        {
          category: "BAPC_DIFF_VS_BP",
          displayName: "Diff vs BP", // BAPC DIFF VS BP
          color: "#9A64A7",
          index: 7,
          isLunisolarColor : true
        },
        {
          category: "BAPC_VS_LY",
          displayName: "% vs LY", // BAPC% VS LY
          color: "#4FB9C1",
          index: 8,
          isLunisolarColor : true
        },
        {
          category: "NSR_TY",
          displayName: "Act", // NSR TY
          color: "#F08258",
          index: 9
        },
        {
          category: "NSR_VS_BP",
          displayName: "% vs BP", // NSR% VS BP
          color: "#F40009",
          index: 10,
          isLunisolarColor : true
        },
        {
          category: "NSR_DIFF_VS_BP",
          displayName: "Diff vs BP", // NSR DIFF VS BP
          color: "#A09498",
          index: 11,
          isLunisolarColor : true
        },
        {
          category: "NSR_VS_LY",
          displayName: "% vs LY", // NSR% VS LY
          color: "#BCC3D1",
          index: 11,
          isLunisolarColor : true
        },
        {
          category: "NSR_PER_CASE_TY",
          displayName: "NSR PER CASE TY",
          color: "#BCC3D1",
          index: 12,
          isDisplay: false
        },
        {
          category: "NSR_PER_CASE_BP",
          displayName: "NSR PER CASE BP",
          color: "#BCC3D1",
          index: 13,
          isDisplay: false
        },
        {
          category: "NSR_PER_CASE_LY",
          displayName: "NSR PER CASE LY",
          color: "#BCC3D1",
          index: 14,
          isDisplay: false
        },
        {
          category: "NSR_PER_CASE_DIFF_VS_BP",
          displayName: "NSR PER CASE DIFF VS BP",
          color: "#BCC3D1",
          index: 15,
          isLunisolarColor : true,
          isDisplay: false
        },
        {
          category: "NSR_PER_CASE_DIFF_VS_LY",
          displayName: "NSR PER CASE DIFF VS LY",
          color: "#BCC3D1",
          index: 16,
          isLunisolarColor : true,
          isDisplay: false
        },
        {
          category: "GP_TY",
          displayName: "Act", // GP TY
          color: "#BCC3D1",
          index: 17
        },
        {
          category: "GP_VS_BP",
          displayName: "% vs BP", // GP% VS BP
          color: "#BCC3D1",
          index: 18,
          isLunisolarColor : true
        },
        {
          category: "GP_DIFF_VS_BP",
          displayName: "Diff vs BP", // GP DIFF VS BP
          color: "#BCC3D1",
          index: 19,
          isLunisolarColor : true
        },
        {
          category: "GP_VS_LY",
          displayName: "% vs LY", // GP% VS LY
          color: "#BCC3D1",
          index: 20,
          isLunisolarColor : true
        },
        {
          category: "GP_PER_CASE_TY",
          displayName: "GP PER CASE TY",
          color: "#BCC3D1",
          index: 21,
          isDisplay: false
        },
        {
          category: "GP_PER_CASE_BP",
          displayName: "GP PER CASE BP",
          color: "#BCC3D1",
          index: 22,
          isDisplay: false
        },
        {
          category: "GP_PER_CASE_DIFF_VS_BP",
          displayName: "GP PER CASE DIFF VS BP",
          color: "#BCC3D1",
          index: 23,
          isLunisolarColor : true,
          isDisplay: false
        },
        {
          category: "GP_PER_CASE_DIFF_VS_LY",
          displayName: "GP PER CASE DIFF VS LY",
          color: "#BCC3D1",
          index: 24,
          isLunisolarColor : true,
          isDisplay: false
        },
        {
          category: "BAPC_BP",
          displayName: "BAPC BP",
          color: "#BCC3D1",
          index: 22,
          isDisplay: false
        },
        {
          category: "BAPC_LY",
          displayName: "BAPC LY",
          color: "#BCC3D1",
          index: 22,
          isDisplay: false
        },
        {
          category: "NSR_BP",
          displayName: "NSR BP",
          color: "#BCC3D1",
          index: 22,
          isDisplay: false
        },
        {
          category: "NSR_LY",
          displayName: "NSR LY",
          color: "#BCC3D1",
          index: 22,
          isDisplay: false
        },
        {
          category: "GP_BP",
          displayName: "GP BP",
          color: "#BCC3D1",
          index: 22,
          isDisplay: false
        },
        {
          category: "GP_LY",
          displayName: "GP LY",
          color: "#BCC3D1",
          index: 22,
          isDisplay: false
        },
    ];
 }

  static ListColumnNationalLocal(){
    return [
      {
      category: "CHANNEL_NL",
      displayName: "Channel NL",
      color: "#8B90A3",
      index: 1,
      isSummarySum: true,
      textAlign: 'left',
      enableSort: false,
      },
      {
        category: "CHANNEL_LEV1_NAME_NL",
        displayName: "Channel Lev1 Name",
        color: "#C69C83",
        index: 2,
        isSummarySum: true,
        enableSort: false,
        isDisplay: true
      },
      {
        category: "BAPC_TY",
        displayName: "Act", // BAPC TY
        color: "#4DA2D6",
        index: 5
      },
      {
        category: "BAPC_VS_BP",
        displayName: "% vs BP", // BAPC% VS BP
        color: "#FCCF4D",
        index: 6,
        isLunisolarColor : true
      },
      {
        category: "BAPC_DIFF_VS_BP",
        displayName: "Diff vs BP", // BAPC DIFF VS BP
        color: "#9A64A7",
        index: 7,
        isLunisolarColor : true
      },
      {
        category: "BAPC_VS_LY",
        displayName: "% vs LY", // BAPC% VS LY
        color: "#4FB9C1",
        index: 8,
        isLunisolarColor : true
      },
      {
        category: "NSR_TY",
        displayName: "Act", // NSR TY
        color: "#F08258",
        index: 9
      },
      {
        category: "NSR_VS_BP",
        displayName: "% vs BP", // NSR% VS BP
        color: "#F40009",
        index: 10,
        isLunisolarColor : true
      },
      {
        category: "NSR_DIFF_VS_BP",
        displayName: "Diff vs BP", // NSR DIFF VS BP
        color: "#A09498",
        index: 11,
        isLunisolarColor : true
      },
      {
        category: "NSR_VS_LY",
        displayName: "% vs LY", // NSR% VS LY
        color: "#BCC3D1",
        index: 11,
        isLunisolarColor : true
      },
      {
        category: "NSR_PER_CASE_TY",
        displayName: "NSR PER CASE TY",
        color: "#BCC3D1",
        index: 12,
        isDisplay: false
      },
      {
        category: "NSR_PER_CASE_BP",
        displayName: "NSR PER CASE BP",
        color: "#BCC3D1",
        index: 13,
        isDisplay: false
      },
      {
        category: "NSR_PER_CASE_LY",
        displayName: "NSR PER CASE LY",
        color: "#BCC3D1",
        index: 14,
        isDisplay: false
      },
      {
        category: "NSR_PER_CASE_DIFF_VS_BP",
        displayName: "NSR PER CASE DIFF VS BP",
        color: "#BCC3D1",
        index: 15,
        isLunisolarColor : true,
        isDisplay: false
      },
      {
        category: "NSR_PER_CASE_DIFF_VS_LY",
        displayName: "NSR PER CASE DIFF VS LY",
        color: "#BCC3D1",
        index: 16,
        isLunisolarColor : true,
        isDisplay: false
      },
      {
        category: "GP_TY",
        displayName: "Act", // GP TY
        color: "#BCC3D1",
        index: 17
      },
      {
        category: "GP_VS_BP",
        displayName: "% vs BP", // GP% VS BP
        color: "#BCC3D1",
        index: 18,
        isLunisolarColor : true
      },
      {
        category: "GP_DIFF_VS_BP",
        displayName: "Diff vs BP", // GP DIFF VS BP
        color: "#BCC3D1",
        index: 19,
        isLunisolarColor : true
      },
      {
        category: "GP_VS_LY",
        displayName: "% vs LY", // GP% VS LY
        color: "#BCC3D1",
        index: 20,
        isLunisolarColor : true
      },
      {
        category: "GP_PER_CASE_TY",
        displayName: "GP PER CASE TY",
        color: "#BCC3D1",
        index: 21,
        isDisplay: false
      },
      {
        category: "GP_PER_CASE_BP",
        displayName: "GP PER CASE BP",
        color: "#BCC3D1",
        index: 22,
        isDisplay: false
      },
      {
        category: "GP_PER_CASE_DIFF_VS_BP",
        displayName: "GP PER CASE DIFF VS BP",
        color: "#BCC3D1",
        index: 23,
        isLunisolarColor : true,
        isDisplay: false
      },
      {
        category: "GP_PER_CASE_DIFF_VS_LY",
        displayName: "GP PER CASE DIFF VS LY",
        color: "#BCC3D1",
        index: 24,
        isLunisolarColor : true,
        isDisplay: false
      },
      {
        category: "BAPC_BP",
        displayName: "BAPC BP",
        color: "#BCC3D1",
        index: 22,
        isDisplay: false
      },
      {
        category: "BAPC_LY",
        displayName: "BAPC LY",
        color: "#BCC3D1",
        index: 22,
        isDisplay: false
      },
      {
        category: "NSR_BP",
        displayName: "NSR BP",
        color: "#BCC3D1",
        index: 22,
        isDisplay: false
      },
      {
        category: "NSR_LY",
        displayName: "NSR LY",
        color: "#BCC3D1",
        index: 22,
        isDisplay: false
      },
      {
        category: "GP_BP",
        displayName: "GP BP",
        color: "#BCC3D1",
        index: 22,
        isDisplay: false
      },
      {
        category: "GP_LY",
        displayName: "GP LY",
        color: "#BCC3D1",
        index: 22,
        isDisplay: false
      },
  ];
}

 static ListColumnHQLTimeline(){
  return [
      {
      category: "FIRST_DAY_OF_WEEK",
      displayName: "FIRST_DAY_OF_WEEK",
      color: "#8B90A3",
      index: 1
      },
      {
      category: "WEEK_OF_YEAR",
      displayName: "WEEK OF YEAR",
      color: "#C69C83",
      index: 2
      },
      {
      category: "GP_VS_LY",
      displayName: "GP VS LY",
      color: "#93AFB9",
      index: 3
      },
      {
        category: "GP_TY",
        displayName: "GP TY",
        color: "#4DA2D6",
        index: 4
      },
      {
        category: "NSR_VS_LY",
        displayName: "NSR VS LY",
        color: "#FCCF4D",
        index: 5
      },
      {
        category: "NSR_VS_BP",
        displayName: "NSR VS BP",
        color: "#9A64A7",
        index: 6
      },
      {
        category: "NSR_TY",
        displayName: "NSR TY",
        color: "#4FB9C1",
        index: 7
      },
      {
        category: "BAPC_VS_LY",
        displayName: "BAPC VS LY",
        color: "#F08258",
        index: 8
      },
      {
        category: "BAPC_VS_BP",
        displayName: "BAPC VS BP",
        color: "#F40009",
        index: 9
      },
      {
        category: "BAPC_TY",
        displayName: "BAPC TY",
        color: "#A09498",
        index: 11
      }
  ];
 }


 static  arraysAreEqual(arr1: any[], arr2: any[]): boolean {
      if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
        return false;
      }

      if (arr1.length !== arr2.length) {
          return false;
      }

      for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) {
              return false;
          }
      }

      return true;
  }
}

const isAllSelected = (arr1: any) => {
  return JSON.stringify(arr1) === JSON.stringify(['All']);
}
