<div class="outter">
    
    <div class="top d-lg-none" data-bs-toggle="collapse" data-bs-target=".sidebarMenu" >
        <button class="navbar-toggler collapsed" type="button" aria-controls="sidebarMenu" aria-expanded="false"
            aria-label="Toggle navigation">
            <img src="assets/img/close-button.png" class="close-button" alt="close">
        </button>
        <span data-bs-toggle="collapse"
        data-bs-target=".sidebarMenu" >Close</span>
    </div>

    <!-- Start Sidemenu Area -->
    <div class="sidemenu-area">
        <div class="sidemenu-body">
            <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
                <ng-container *ngFor="let item of menuSections; index as i">
                    <div class="group">
                        <li class="nav-item-title {{item.name != 'SALES PERFORMANCE' ? 'coming-soon-section' : ''}}">
                            {{item.name}}
                            <ng-container *ngIf="item.isBeta">
                                <img src="assets/img/beta-version.png" class="coming-soon-icon" title="Coming soon">
                            </ng-container>
                        </li>
    
                        <!-- Desktop -->
                        <li class="desktop nav-item d-none d-lg-block" routerLinkActive="active" *ngFor="let data of item.menus">
                            <a routerLink="{{data.link.internalLink.url}}" class="nav-link">
                                <ng-icon name="{{data.iconCssClassV2}}" size="16px" strokeWidth="46px"></ng-icon>
                                <span class="menu-title">{{data.link.title}}</span>
                                <ng-container *ngIf="data.isBeta">
                                    <img src="assets/img/beta-version.png" class="coming-soon-icon" title="Coming soon">
                                </ng-container>
                            </a>
                        </li>

                        <!-- Mobile -->
                        <li class="mobile nav-item d-lg-none" routerLinkActive="active" *ngFor="let data of item.menus" data-bs-toggle="collapse"
                        data-bs-target=".sidebarMenu">
                            <a routerLink="{{data.link.internalLink.url}}" class="nav-link">
                                <ng-icon name="{{data.iconCssClassV2}}" size="16px" strokeWidth="46px"></ng-icon>
                                <span class="menu-title">{{data.link.title}}</span>
                            </a>
                        </li>
                                                
                    </div>
                </ng-container>
            </ul>
        </div>
    </div>
    <!-- End Sidemenu Area -->

</div>