import { Component, ViewChild, ElementRef, Input, OnInit } from '@angular/core';
import { CsvService } from 'src/app/services/csv.services';
import { FaceshareQueries } from 'src/app/model/constants';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
  selector: 'faceshare-by-sku',
  templateUrl: './by-sku.component.html',
  styleUrls: ['./by-sku.component.scss']
})

export class FaceShareBySkuComponent implements OnInit {
  @Input() lstOptionSelectedSalesDate: any;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('observedElement', { static: true }) observedElement: ElementRef;

  constructor(private csvService: CsvService, private mixPanelTrackingService: MixPanelTrackingService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.mixPanelTrackingService.observeElement(this.observedElement);
  }

  public downloadExcel() {
    const filterQuery = {
      CacheKey: `SALES_YM=${encodeURIComponent(this.lstOptionSelectedSalesDate)}`,
      Selected: {
        SALES_YM: this.lstOptionSelectedSalesDate
      }
    };

    this.csvService.getCsvUrl(FaceshareQueries.bySkuCSV, filterQuery, '')
      .subscribe((data) => {
        const csvUrl = data?.Csv_Url;

        if (csvUrl) {
          this._downloadExcel(csvUrl);
        }
      });
  }

  private _downloadExcel(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }
}
