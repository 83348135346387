import { Component, OnInit, OnDestroy,HostListener } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AnalyticsQueries, tokens } from 'src/app/model/constants'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DashboardComponent } from 'angular-google-charts';
import { DatePipe } from '@angular/common';
import { AnalyticsHelper } from './helpers/analytics';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult } from '@azure/msal-browser';
declare let $: any;
declare var mixpanel;


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    location: any;
    routerSubscription: any;
    standalone: boolean;
    lastUpdated: string;
    isIntage: boolean;
    oldDomainRedirect: boolean;
    userinfo: any;
    isloginredirect: boolean;
    message: any;

    constructor(private router: Router, private http: HttpClient, public datepipe: DatePipe,private authService: MsalService, private msalBroadcastService: MsalBroadcastService) {
        this.standalone = location.href.includes('/authentication/') || location.href.includes('/miscellaneous/') || location.href.includes('/error/') || location.href.includes('processing'); 
    }

    async ngOnInit(){  
        this.msalBroadcastService.msalSubject$
        .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
          )
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          this.authService.instance.setActiveAccount(payload.account);
          if (this.authService.instance.getActiveAccount()) {
            this.authService.instance.acquireTokenSilent({
              scopes: ['email', 'profile',environment.authenserver]
            }).then(async (response) => {
              localStorage.setItem(tokens.accessToken, response.accessToken);   
              let username = response.account.username.slice(0,response.account.username.indexOf('@'));
              var email = response.account.username;
              localStorage.setItem('username', username); 
              localStorage.setItem('email', email);

              let queryUrl = environment.hygraphGetCustomer + "?email=" + email;
              let httpOptions: Object = {
              headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
              responseType: 'text'
              }
              this.http.get<any>(queryUrl, httpOptions)
              .subscribe(
                  response => {
                    let res = JSON.parse(response);
                    if (res == null || res.data[environment.customer].length == 0)
                    {
                        location.href = 'authentication/login';
                    }
                    else
                    {
                        this.userinfo = res.data;
                        localStorage.setItem("userinfo", JSON.stringify(this.userinfo));
                        mixpanel.identify(email);
                        mixpanel.people.set({ "Username": username, "$name" : username, "$email" : email });
                        const name = "Sign In";
                        const user = {
                            "user": username
                        };
                        mixpanel.track(name, user);
                    }    
                  },
                  error => 
                  {
                      console.log(error);                        
                  }
              );          
            })}})
                      
        if(document.referrer.indexOf('sense-by-ccbji.click') > 0){
            AnalyticsHelper.openSweetAlert("You are being redirected to Sense new domain https://sense.koreda.app\n\nPlease note that Intage data is not ready in this new domain.",
            false, '', location.href);
            this.oldDomainRedirect = true;
            return;
        }
        this.recallJsFuntions();
        //Check notification
        this.handleNotification();

        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                setTimeout(() => {
                    const event = new CustomEvent('page_change', {
                        detail: {
                            ...e
                        }
                    });
                    document.dispatchEvent(event);
                }, 2000);
            }
        });
    }

    scrollEvent(){
        let verticalOffset = window.pageYOffset 
          || document.documentElement.scrollTop 
          || document.body.scrollTop || 0;
        if(verticalOffset > 0){
            let sideMenu = document.getElementsByClassName('sidemenu-area').length > 0 ? document.getElementsByClassName('sidemenu-area')[0] : null;
            if(sideMenu != null){
                sideMenu.classList.add('scrolled');
            }
            let nav = document.getElementsByClassName('top-navbar').length > 0 ? document.getElementsByClassName('top-navbar')[0] : null;
            // if(nav != null){
            //     nav.classList.add('scrolled', 'is-sticky');
            //     var el = document.getElementsByClassName('height-scrolled');
            //     if (el && el.length == 0) {
            //         let div = document.createElement('div');
            //         div.className = 'height-scrolled';
            //         // FPT-MyNT15 [2023/2/21][SENSE][1203954047586789] Fix bug scrolling is not smooth
            //         div.style.height = '70px';
            //         nav.before(div);
            //     }
            // }
            let weekSelector = document.getElementsByClassName('week-select').length > 0 ? document.getElementsByClassName('week-select')[0] : null;
            if(weekSelector != null){
                weekSelector.classList.add('sticky-date');
            }
            // if(document.getElementsByClassName("process-img").length > 0){
            //     document.getElementsByClassName("process-img")[0].classList.add('sticky-bottle');
            //     document.getElementsByClassName("process-detail")[0].classList.add('sticky-bottle');
            // }
        }
        else
        {
            let sideMenu = document.getElementsByClassName('sidemenu-area').length > 0 ? document.getElementsByClassName('sidemenu-area')[0] : null;
            if(sideMenu != null){
                sideMenu.classList.remove('scrolled');
            }
            let nav = document.getElementsByClassName('top-navbar').length > 0 ? document.getElementsByClassName('top-navbar')[0] : null;
            // if(nav != null){
            //     // nav.classList.remove('scrolled', 'is-sticky');
            //     var el = document.getElementsByClassName('height-scrolled');                
            //     if (el && el.length > 0) {
            //         el[0].remove(); 
            //     }
            // }
            let weekSelector = document.getElementsByClassName('week-select').length > 0 ? document.getElementsByClassName('week-select')[0] : null;
            if(weekSelector != null){
                weekSelector.classList.remove('sticky-date');
            }
            // if(document.getElementsByClassName("process-img").length > 0){
            //     document.getElementsByClassName("process-img")[0].classList.remove('sticky-bottle');
            //     document.getElementsByClassName("process-detail")[0].classList.remove('sticky-bottle');
            // }
        }
    }

    handleNotification(){
        let t=this;
        window.addEventListener('scroll', this.scrollEvent, true);
        setTimeout(function(){
            if(document.getElementsByClassName('notification-bar').length > 0){
                let sideMenu = document.getElementsByClassName('sidemenu-area').length > 0 ? document.getElementsByClassName('sidemenu-area')[0] : null;
                if(sideMenu != null){
                    sideMenu.classList.add('with-notification');
                }
                let nav = document.getElementsByClassName('top-navbar').length > 0 ? document.getElementsByClassName('top-navbar')[0] : null;
                if(nav != null){
                    nav.classList.add('with-notification');
                }
            }
            else{
                let sideMenu = document.getElementsByClassName('sidemenu-area').length > 0 ? document.getElementsByClassName('sidemenu-area')[0] : null;
                if(sideMenu != null){
                    sideMenu.classList.remove('with-notification');
                }
                let nav = document.getElementsByClassName('top-navbar').length > 0 ? document.getElementsByClassName('top-navbar')[0] : null;
                if(nav != null){
                    nav.classList.remove('with-notification');
                }
            }
        }, 200);      
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/custom.js');
                if (event instanceof NavigationEnd)
                {
                    this.location = event.url;
                    this.standalone = this.location.includes('/authentication/') || this.location.includes('/miscellaneous/') || this.location.includes('/error/') || location.href.includes('processing');
                    if (!(event instanceof NavigationEnd)) {
                        return;
                    }
                    window.scrollTo(0, 0)
                }
            });
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }

    public intageCheckLastUpdate()
    {
        if (localStorage.getItem('accessToken') != null)
        {            
            let query_param = "";
            if (location.href.includes("/dashboard") || location.href.includes('/daily-report'))
            {   
                query_param = AnalyticsQueries.lastUpdated;
            }
            else if (location.href.includes("/intage-flash-vm"))
            {
                query_param = AnalyticsQueries.lastUpdatedIntageVM;
            }
            else if (location.href.includes("/intage-flash") || location.href.includes('/intage'))
            {
                query_param = AnalyticsQueries.lastUpdatedIntageOTC;
            }
            else if (['/hq-progress', '/district-route-progress', '/sku-deepdive', '/area-deepdive', '/progress-ranking'].some(path => location.href.includes(path))) {
                query_param = AnalyticsQueries.lastUpdatedSE;
            }
            else if (['/sovi', '/poc'].some(path => location.href.includes(path))) {
                query_param = AnalyticsQueries.lastUpdatedTraxReport;
            }
            else
            {
                query_param = AnalyticsQueries.lastUpdated;
            }
            let queryUrl = environment.snowflakeProxy + "?code=" + query_param;
            let httpOptions: Object = {
            headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
            responseType: 'text'
            }
            this.http.get<any>(queryUrl, httpOptions)
            .subscribe(
                response => {
                    const updatedDate = new Date(0);
                    updatedDate.setUTCSeconds(parseInt(response));

                    const date = this.datepipe.transform(updatedDate, "yyyy-MM-dd HH:mm:ss");
                    this.lastUpdated = date;
                    this.saveLastUpdate(query_param, date);
                },
                error =>
                {
                    console.log(error);
                }
            );
        }
    }

    private saveLastUpdate(name: string, date: string) {
        localStorage.setItem('lastUpdated', JSON.stringify({ name, date }));
    }

    private seCheckLastUpdate(updatedAt: string) {
        const checkPaths = ['/hq-progress', '/district-route-progress', '/sku-deepdive', '/area-deepdive', '/progress-ranking'];

        if (checkPaths.some(path => location.href.includes(path))) {
            const userinfo = localStorage.getItem('userinfo');
            const storageUpdatedAt = userinfo ? JSON.parse(userinfo)?.[environment.customer]?.[0]?.updatedAt : '';

            if (storageUpdatedAt && storageUpdatedAt !== updatedAt) {
                this.router.navigateByUrl('authentication/login');
            }
        }
    }
}
