import { Component, OnInit } from "@angular/core";
import { Router, Event, NavigationEnd } from "@angular/router";
import { environment } from "src/environments/environment";
import { tokens } from "src/app/model/constants";
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare let $: any;

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
    
  constructor(private http: HttpClient, private router: Router) {
        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                $(".responsive-burger-menu").removeClass("active");
                $(".sidemenu-area").removeClass("active-sidemenu-area");
            }
        });
    }

    sessionIdInfo: any;
    public menuSections = [];
    ngOnInit() {
        if (localStorage.getItem(tokens.accessToken) != null && localStorage.getItem("userinfo") != null) {   
            let menu = localStorage.getItem('menu');
            if (menu == null)
            {
            this.getUser(); 
            }
            else
            {
            let res = JSON.parse(menu);
            this.menuSections = res.data[environment.menuSection];
            this.menuSections = this.menuSections.filter(x => x.name != 'EXECUTION TRACKING');
            }        
        } 
        else {
            this.router.navigateByUrl('authentication/login');
        }
    }

    public getUser() {
        let queryUrl = environment.hygraphGetMenus + "?url=" + location.pathname;
        let httpOptions: Object = {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
        responseType: 'text'
        }
        this.http.get<any>(queryUrl, httpOptions)
        .subscribe(
            response => {
                let res = JSON.parse(response);
                localStorage.setItem('menu', response);
                this.menuSections = res.data[environment.menuSection];
                this.menuSections = this.menuSections.filter(x => x.name != 'EXECUTION TRACKING');
            },
            error => 
            {
                console.log(error);                        
            }
        );
    }

    ngOnDestroy() {
        // this.querySubscription.unsubscribe();
    }


}
