<div class="poc-component card">
  <div class="card-header">
    <h3 #observedElement class="component-title">{{ sectionName }}</h3>
  </div>

  <app-photo-report-filter
    [filterModuleName]="'poc-by-account'"
    [filters]="filters"
    [lstOption_IsUsed]="lstOption_IsUsed"
    [lstAllOptionsData]="lstAllOptionsData"
    [lstOption_Selected]="lstOptionSelected"
    [sectionName]="sectionName"
    (onUpdateChart)="onUpdateChart($event)"
  ></app-photo-report-filter>

  <div *ngIf="isLoading" class="text-center spinner-wrapper">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <!-- start Account Table -->
  <div class="row" *ngIf="!isLoading">
    <div class="col-lg-12 col-md-12">
      <div #scroll class="market-size">
        <div class="account-table__content">
          <ng-container *ngIf="chartData?.length">
            <table #screen>
              <thead>
                <tr class="row__first">
                  <th colspan="2" rowspan="2" class="col" scope="col"></th>
                  <th colspan="11" class="bg all_category" scope="col">All Category</th>
                  <th colspan="11" class="bg cola_ssd" scope="col">Cola SSD</th>
                  <th colspan="11" class="bg other_ssd" scope="col">Other SSD</th>
                  <th colspan="11" class="bg coffee" scope="col">Coffee</th>
                  <th colspan="11" class="bg juice" scope="col">Juice</th>
                  <th colspan="11" class="bg ns_tea" scope="col">NS Tea</th>
                  <th colspan="11" class="bg black_tea" scope="col">Black Tea</th>
                  <th colspan="11" class="bg sports" scope="col">Sports</th>
                  <th colspan="11" class="bg energy" scope="col">Energy</th>
                  <th colspan="11" class="bg water" scope="col">Water</th>
                </tr>
                <tr class="row__second">
                  <th colspan="11" class="bg all_category" scope="col">Ave. # of POC per outlet</th>
                  <th colspan="11" class="bg cola_ssd" scope="col">Ave. # of POC per outlet</th>
                  <th colspan="11" class="bg other_ssd" scope="col">Ave. # of POC per outlet</th>
                  <th colspan="11" class="bg coffee" scope="col">Ave. # of POC per outlet</th>
                  <th colspan="11" class="bg juice" scope="col">Ave. # of POC per outlet</th>
                  <th colspan="11" class="bg ns_tea" scope="col">Ave. # of POC per outlet</th>
                  <th colspan="11" class="bg black_tea" scope="col">Ave. # of POC per outlet</th>
                  <th colspan="11" class="bg sports" scope="col">Ave. # of POC per outlet</th>
                  <th colspan="11" class="bg energy" scope="col">Ave. # of POC per outlet</th>
                  <th colspan="11" class="bg water" scope="col">Ave. # of POC per outlet</th>
                </tr>
                <tr class="row__third">
                  <th class="col account" rowspan="2" scope="col">Account</th>
                  <th class="col segment text-center" rowspan="2" scope="col">Segment</th>
                  <ng-container *ngFor="let category of categories; let i = index; trackBy: trackByFn">
                    <th *ngIf="!isConsecutiveTotal(i)" [class]="category['className'] + ' bg'" [attr.colspan]="isTotalWithColspan(i) ? 2 : 1">{{ category['label'] }}</th>
                  </ng-container>
                </tr>
                <tr class="row__fourth">
                  <th *ngFor="let _ of [].constructor(10)" class="bg all_category" scope="col">TM</th><th class="bg all_category" scope="col">vs.LY</th>
                  <th *ngFor="let _ of [].constructor(10)" class="bg cola_ssd" scope="col">TM</th><th class="bg cola_ssd" scope="col">vs.LY</th>
                  <th *ngFor="let _ of [].constructor(10)" class="bg other_ssd" scope="col">TM</th><th class="bg other_ssd" scope="col">vs.LY</th>
                  <th *ngFor="let _ of [].constructor(10)" class="bg coffee" scope="col">TM</th><th class="bg coffee" scope="col">vs.LY</th>
                  <th *ngFor="let _ of [].constructor(10)" class="bg juice" scope="col">TM</th><th class="bg juice" scope="col">vs.LY</th>
                  <th *ngFor="let _ of [].constructor(10)" class="bg ns_tea" scope="col">TM</th><th class="bg ns_tea" scope="col">vs.LY</th>
                  <th *ngFor="let _ of [].constructor(10)" class="bg black_tea" scope="col">TM</th><th class="bg black_tea" scope="col">vs.LY</th>
                  <th *ngFor="let _ of [].constructor(10)" class="bg sports" scope="col">TM</th><th class="bg sports" scope="col">vs.LY</th>
                  <th *ngFor="let _ of [].constructor(10)" class="bg energy" scope="col">TM</th><th class="bg energy" scope="col">vs.LY</th>
                  <th *ngFor="let _ of [].constructor(10)" class="bg water" scope="col">TM</th><th class="bg water" scope="col">vs.LY</th>
                </tr>
              </thead>

              <tbody>
                <!--Loop here-->
                <ng-container *ngFor="let item of chartData; let i = index">
                  <tr *ngIf="item['CUSTOMER_GROUP_NAME'] === 'SM TTL'">
                    <td colspan="2" class="channel-ttl">
                      <span>{{lstOptionApplied['CHANNEL'][0]}} TTL</span>
                    </td>
                    <td *ngFor="let category of categories" class="channel-ttl">
                      <ng-container *ngIf="category.value.includes('_LY'); else defaultTemplate">
                        <span [ngClass]="{ 'text-red': isNegative(item[category.value]) }">{{ getLyValueDisplay(item[category.value], item[category.value + '_Display'])  }}</span>
                      </ng-container>
                      <ng-template #defaultTemplate>
                        <span>{{ item[category.value + '_Display'] }}</span>
                      </ng-template>
                    </td>
                  </tr>

                  <tr *ngIf="item['CUSTOMER_GROUP_NAME'] !== 'SM TTL'">
                    <td class="account">
                      <ng-container *ngIf="item['CUSTOMER_GROUP_NAME'] as customerGroupName">
                        <div>
                          <span [ngbTooltip]="customerGroupName"
                                [container]="'body'"
                                placement="top-left">{{ customerGroupName }}</span>
                        </div>
                      </ng-container>
                    </td>
                    <td class="segment">{{ item['ACCOUNT_SEGMENT'] }}</td>
                    <td *ngFor="let category of categories">
                      <ng-container *ngIf="category.value.includes('_LY'); else defaultTemplate">
                        <span [ngClass]="{ 'text-red': isNegative(item[category.value]) }">{{ getLyValueDisplay(item[category.value], item[category.value + '_Display'])  }}</span>
                      </ng-container>
                      <ng-template #defaultTemplate>
                        <span>{{ item[category.value + '_Display'] }}</span>
                      </ng-template>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <a #downloadLink style="display: none;"></a>
  <div class="d-inline-flex section-download">
    <button type="button" class="btn btn-download-excel download-button-tracking" (click)="downloadExcel()">
      <span>Download Excel</span>
      <ng-icon name="IonDownloadOutline" size="16px" strokeWidth="46px"></ng-icon>
    </button>
  </div>
</div>
