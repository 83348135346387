import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { authorize } from 'src/app/helpers/AuthorizePage';
import { dispatchDateSelectChange } from 'src/app/helpers/tracking';
import * as moment from 'moment';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
@Component({
  selector: "app-analytics-table",
  templateUrl: "./analytics-ctg-pkg-table.component.html",
  styleUrls: ["./analytics-ctg-pkg-table.component.scss"]
})
export class AnalyticsCategoryPackageTable implements OnInit {

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  dataupdate: string;
  activeTab: number = 1;
  startDate: any;
  selectedDate: any;
  isshowDownloadButton: boolean = true;

  ready: boolean = false;

  data_categories_wtd: {};
  data_categories_mtd: {};
  data_categories_qtd: {};
  data_categories_ytd: {};

  data_packages_wtd: {};
  data_packages_mtd: {};
  data_packages_qtd: {};
  data_packages_ytd: {};

  ngOnInit() {
    if (!authorize())
    {
      this.router.navigateByUrl('unauthorized');
    }
    else
    {
      const yesterday = moment(new Date()).add(-1, 'day');
      const yesterday_format = yesterday.format('YYYYMMDD');

      let queryUrl = environment.hygraphGetCategoryPackageJobUpdate;
      let httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
      }
      this.http.get<any>(queryUrl, httpOptions)
      .subscribe(
          response => {
              let res = JSON.parse(response);
              if (res.data[environment.clearCategoryPackageCacheJobStatus] != null && res.data[environment.clearCategoryPackageCacheJobStatus].length > 0) {
                let latest_date = res.data[environment.clearCategoryPackageCacheJobStatus][0].date;
                if (yesterday_format == latest_date) {
                  this.dataupdate = 'true';
                }
                else {
                  this.dataupdate = 'false';
                }
                let year = parseInt(latest_date.substring(0, 4));
                let month = parseInt(latest_date.substring(4, 6));
                let day = parseInt(latest_date.substring(6, 8));
                this.selectedDate = {
                  year: year,
                  month: month,
                  day: day
                };
                this.startDate = {
                  year: year,
                  month: month,
                  day: day
                };
      
                const date = `${this.selectedDate.year}${String(this.selectedDate.month).padStart(2, '0')}${String(this.selectedDate.day).padStart(2, '0')}`;
                this.getData(date);
              }
      
              document.body.style.overflowY = 'hidden';
          },
          error => 
          {
              console.log(error);                        
          }
      );
    }
  }

  showDownloadButton()
  {
    const selectedDate = moment(`${this.selectedDate.year}-${this.selectedDate.month}-${this.selectedDate.day}`, 'YYYY-MM-DD');
    const yesterday = moment(new Date()).add(-1, 'day');
    const endOfMonth = selectedDate.clone().endOf('month');
    if (selectedDate.day() == 5 || selectedDate.isSame(endOfMonth, 'day') || selectedDate.isSame(yesterday, 'day'))
    {
      this.isshowDownloadButton = true;
    }
    else
    {
      this.isshowDownloadButton = false;
    }
  }

  ngOnDestroy() {
    document.body.style.overflowY = 'auto';
  }

  public onDateSelect(event) {
    dispatchDateSelectChange({ ...event, ...{ element: "daily-ctg-pkg-report" } });

    let date = `${this.selectedDate.year}${String(this.selectedDate.month).padStart(2, '0')}${String(this.selectedDate.day).padStart(2, '0')}`;
    this.getData(date);
    this.showDownloadButton();
  }

  getData(date: string) {

    this.ready = false;

    const queryUrl = environment.dailyCategoryPackageReportUrl.replace('{date}', date);
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken');
    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', accessToken),
      responseType: 'text'
    }
    this.http.get<any>(queryUrl, httpOptions)
      .subscribe(
        response => {
          const data = JSON.parse(response);

          data.units.forEach(element => {
            if (element.unitName === "wtd") {
              this.data_categories_wtd = element.categories
              this.data_packages_wtd = element.packages
            }
            if (element.unitName === "mtd") {
              this.data_categories_mtd = element.categories
              this.data_packages_mtd = element.packages
            }
            if (element.unitName === "qtd") {
              this.data_categories_qtd = element.categories
              this.data_packages_qtd = element.packages
            }
            if (element.unitName === "ytd") {
              this.data_categories_ytd = element.categories
              this.data_packages_ytd = element.packages
            }
          });

          this.ready = true;

        },
        error => {
          console.log(error);
        }
      );
  }

  getCategoryPackageReport()
  {
    let date = `${this.selectedDate.year}${String(this.selectedDate.month).padStart(2, '0')}${String(this.selectedDate.day).padStart(2, '0')}`;
    let queryUrl = environment.dailyCategoryPackageCheckFileReportOnS3Url.replace('{date}', date);
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken');
    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', accessToken),
      responseType: 'text'
    }
    this.http.get<any>(queryUrl, httpOptions)
    .subscribe(
      response => {
        if (response == "")
        {
          AnalyticsHelper.openSweetAlert(`<p class="line1">Important Notice</p><p class="line2">Report file doesn't exist.</p>`, false);
        }
        else
        {
          location.href = response;
        }          
      },
      error => {
        console.log(error);
      }
    );
  }

}