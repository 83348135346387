import {
    Component,
    OnChanges,
    SimpleChanges,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { DatePipe } from "@angular/common";
import { downloadAsPDF } from "../../../../helpers/pdfExtend";
import moment from "moment";
import { HQProgressHelper } from "src/app/components/pages/hq-progress/hq_progress_helper";
import { HttpClient } from "@angular/common/http";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import * as htmlToImage from 'html-to-image';
import { PDFProperty } from "src/app/model/pdfProperty.model";
import { FilterHelper } from 'src/app/components/elements/add-more-filter-hq/filter-helper-hq';
import { FILTER_CONFIGS_HQ, AnalyticsQueries } from 'src/app/model/constants';
import lodashClonedeep from 'lodash.clonedeep';
import { CsvService } from 'src/app/services/csv.services';
import { UserService } from 'src/app/services/user.services';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
    selector: "hq-progress-timeline",
    templateUrl: "./hq-progress-timeline.component.html",
    styleUrls: ["./hq-progress-timeline.component.scss"],
})
export class ProgressTimelineComponent implements OnChanges {
    @Input() fromDate: any;
    @Input() toDate: any;
    @Output() ready = new EventEmitter<boolean>();
    @ViewChild("screen") screen: ElementRef;
    @ViewChild("canvas") canvas: ElementRef;
    @ViewChild("downloadLink") downloadLink: ElementRef;
    @ViewChild("scroll") scroller: ElementRef;
    @ViewChild('observedElement', { static: true }) observedElement: ElementRef;

    sectionName: string = 'Progress Timeline';
    lstAllOptionsFilteredData : any;
    hqTimelineData:any;
    hqTimelineTempData:any;
    saleDate: string = '';
    hqTimelineData_CSV:any;

    FILTERS: any        = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.KEY_NAME || [];
    HQ_PROGRESS_FILTERS = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.HQ_PROGRESS_TIMELINE?.COLLECTIONS;
    defaultVisibleFilters: number = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.HQ_PROGRESS_TIMELINE?.VISIBLE_COUNT ?? 4;

    listData_Filter: any = null;
    lstOption_States: any = {};
    lstOption_States_Applied: any = {};
    lstOption_Selected: any = {};
    lstOption_IsUsed: any = {};
    lstOption_Selected_Applied: any = {};

    lstAllOptionsCalendarData: any = {CHANNEL_LEV1_NAME: [], CHANNEL_LEV2_NAME: [], CHANNEL_LEV3_NAME: []};
    lstAllOptionsAreaData: any = {SALES_HQ_LEV1_NAME: [], SALES_HQ_LEV2_NAME: []};
    analyticsQuery: object = {};

    populated: boolean;
    listColumnHQTimeline: any[];
    hqTimelineDataHorizontal: any[];
    listRowIndex: any[];

    arrBAPCTY: any[];
    hozBarBAPCTY: any;
    hozBarOppBAPCTY: any;
    hozBarTitleBAPCTY: string;
    hozBarIndexBAPCTY: string;
    isBAPCTYHasNegativeElement: boolean;

    arrBAPCVSBP: any[];
    hozBarBAPCVSBP: any;
    hozBarOppBAPCVSBP: any;
    hozBarTitleBAPCVSBP: string;
    hozBarTitleOppBAPCVSBP: string;

    arrBAPCVSLY: any[];
    hozBarBAPCVSLY: any;
    hozBarOppBAPCVSLY: any;
    hozBarTitleBAPCVSLY: string;
    hozBarTitleOppBAPCVSLY: string;

    arrNSRTY: any[];
    hozBarNSRTY: any;
    hozBarOppNSRTY: any;
    hozBarTitleNSRTY: string;
    hozBarTitleOppNSRTY: string;
    hozBarIndexNSRTY: string;
    isNSRTYHasNegativeElement: boolean;

    arrNSRVSBP: any[];
    hozBarNSRVSBP: any;
    hozBarOppNSRVSBP: any;
    hozBarTitleNSRVSBP: string;
    hozBarTitleOppNSRVSBP: string;

    arrNSRVSLY: any[];
    hozBarNSRVSLY: any;
    hozBarOppNSRVSLY: any;
    hozBarTitleNSRVSLY: string;
    hozBarTitleOppNSRVSLY: string;

    arrGPTY: any[];
    hozBarGPTY: any;
    hozBarOppGPTY: any;
    hozBarTitleGPTY: string;
    hozBarTitleOppGPTY: string;
    hozBarIndexGPTY: string;
    isGPTYHasNegativeElement: boolean;

    arrGPVSBP: any[];
    hozBarGPVSBP: any;
    hozBarOppGPVSBP: any;
    hozBarTitleGPVSBP: string;
    hozBarTitleOppGPVSBP: string;

    arrGPVSLY: any[];
    hozBarGPVSLY: any;
    hozBarOppGPVSLY: any;
    hozBarTitleGPVSLY: string;
    hozBarTitleOppGPVSLY: string;

    arrDays: any[];
    aggregateBy: string = "daily";
    aggregateByItems: any[] = [{value: 'daily', name: 'By day'},
        {value: 'weekly', name: 'By week'},
        {value: 'monthly', name: 'By month'},
        {value: 'quarterly', name: 'By quarter'},
        {value: 'yearly', name: 'By year'}];

    isLoading: boolean = false;
    isHasFistLoad: boolean = false;
    
	constructor(public datepipe: DatePipe, private http: HttpClient, private csvService: CsvService, private userService: UserService,
                private mixPanelTrackingService: MixPanelTrackingService) {}

    ngOnInit() {
        const lstOption = FilterHelper.init_lstOption_States(`ProgressTimelineComponent_${this.userService.getUserFilterName()}`, this.FILTERS, this.HQ_PROGRESS_FILTERS, this.defaultVisibleFilters, this.userService.getUserInfo()) || {};
        this.lstOption_States   = {...lstOption?.lstOption_States};
        this.lstOption_Selected = {...lstOption?.lstOption_Selected};
        this.listData_Filter  = {...lstOption.listData_Filter};
        if(lstOption?.lstOption_Selected?.AggregateBy){
            this.aggregateBy = lstOption?.lstOption_Selected?.AggregateBy ;
        }
        this.lstOption_IsUsed   = FilterHelper.getListIsUsedFilter(this.FILTERS, this.HQ_PROGRESS_FILTERS) || {};
        this.lstOption_States_Applied = {...lstOption?.lstOption_States};
        this.lstOption_Selected_Applied = {...lstOption?.lstOption_Selected}
        this.analyticsQuery = HQProgressHelper.HQTimeLineSetQueryString(this.aggregateBy, this.lstOption_Selected_Applied);
    }

    ngOnChanges(changes: SimpleChanges) {
        if((changes['fromDate'] || changes['toDate']) && this.fromDate && this.toDate)
        {
            this.saleDate = moment(this.fromDate).format('YYYY/MM/DD') + ' to ' + moment(this.toDate).format('YYYY/MM/DD');
            this.onSearchData();
            this.handleonReady();
        }
    }

    ngAfterViewInit(): void {
        this.mixPanelTrackingService.observeElement(this.observedElement);
    }

    onUpdateChart(event: any) {
        const {lstOption_States, lstOption_Selected} = event;

        if (lstOption_States && lstOption_Selected) {
            this.lstOption_Selected = {};
            this.lstOption_Selected = lstOption_Selected;
            this.lstOption_States   = {};
            this.lstOption_States   = lstOption_States;
            this.onUpdateCurrent_Filter_Applied();
            this.updateVsLyByCategoryData();
        }
    }

    onUpdateCurrent_Filter_Applied() {
        this.lstOption_States_Applied = lodashClonedeep(this.lstOption_States);
        this.lstOption_Selected_Applied = lodashClonedeep(this.lstOption_Selected);
    }

    public updateVsLyByCategoryData(): void {
        this.onSearchData();
    }

    onResetFiltersGroup(event: any) {
        const { data } = event;

        if (data && Array.isArray(data) && data.length > 0) {
        data.map(key => {
            if (key && key.length > 0) {
            this.lstOption_Selected[key] = ['All'];
            }
        });
        }
    }

    onAggregateChange($event: any) {
        this.aggregateBy = $event;
        this.lstOption_Selected.AggregateBy = $event;
        this.onSearchData();
    }

    handleonReady(){
        let t1= this;
        setTimeout(() => {
          t1.ready.emit(true);
        });
    }

    onSearchData() {
        if (this.fromDate === undefined || this.toDate === undefined) {
            return;
        }

        this.isLoading = true;
        this.analyticsQuery = HQProgressHelper.HQTimeLineSetQueryString(this.aggregateBy, this.lstOption_Selected_Applied);

        HQProgressHelper.GetHQTimelineDataByAggregateKey(
            this.http,
            this.analyticsQuery,
            moment(this.fromDate).format("YYYY-MM-DD"),
            moment(this.toDate).format("YYYY-MM-DD")
        ).then((hqTimelineData) => {
            this.hqTimelineTempData = hqTimelineData?.Data;
            this.hqTimelineTempData.forEach(element => {
                if (this.aggregateBy === "weekly"){
                    element.BY_FILTER = this.getFirstDayOfWeekAsString(element.BY_FILTER);
                }
                if (this.aggregateBy === "monthly"){
                    element.BY_FILTER = moment(new Date(element.BY_FILTER)).format("YYYY/MM");
                }
            });

            if(this.aggregateBy === "weekly"){ // now accept sort for weekly,   we can allow sort for all aggregateBy
                this.hqTimelineTempData.sort((a, b) => {
                  if (a.BY_FILTER < b.BY_FILTER) {
                      return -1;
                  } else if (a.BY_FILTER > b.BY_FILTER) {
                      return 1;
                  } else {
                      return 0;
                  }
                 });
            }

            this.hqTimelineData = this.defaultGroupByFilter(this.hqTimelineTempData);
            this.transformData();
            this.isLoading = false;
        });
    }

    getFirstDayOfWeekAsString(inputString: string): string {
        const yearString: string = inputString.slice(0, 4);
        const weekString: string = inputString.slice(4);

        const year: number = parseInt(yearString, 10);
        const week: number = parseInt(weekString, 10);

        const januaryFirst = moment(`${year}-01-01`);
        const firstWeekDay = januaryFirst.clone().add((week - 1) * 7 - januaryFirst.day() + 1, 'days');
        const saturday = firstWeekDay.clone().day(6);
        return saturday.format('YYYY/MM/DD');
    }

    defaultGroupByFilter(hqTimelineData: any[]) {
        var result = [];
        Object.values(
          hqTimelineData.reduce((res, value) => {
            const groupKey = value.BY_FILTER;
            if (!res[groupKey]) {
              res[groupKey] = { BY_FILTER: groupKey, GP_VS_LY: 0, GP_VS_BP: 0, GP_TY: 0, NSR_VS_LY: 0, NSR_VS_BP: 0, NSR_TY: 0, BAPC_VS_LY: 0, BAPC_VS_BP: 0, BAPC_TY: 0 };
              result.push(res[groupKey]);
            }
            const numericFields = ["GP_VS_LY", "GP_VS_BP", "GP_TY", "NSR_VS_LY", "NSR_VS_BP", "NSR_TY", "BAPC_VS_LY", "BAPC_VS_BP", "BAPC_TY"];
            numericFields.forEach(field => {
              switch (field) {
                case "GP_VS_LY":
                case "GP_VS_BP":
                case "NSR_VS_BP":
                case "NSR_VS_LY":
                case "BAPC_VS_LY":
                case "BAPC_VS_BP":
                case "GP_TY":
                case "NSR_TY":
                case "BAPC_TY":
                  var fieldValue = value[field] === "" ? 0 : parseFloat(value[field]);
                  if (!res[groupKey]) {
                    res[groupKey] = {};
                  }
                  if (!res[groupKey][field]) {
                    res[groupKey][field] = 0;
                  }
                  res[groupKey][field] += fieldValue;
                  res[groupKey][field] = parseFloat(res[groupKey][field].toFixed(3));
                  break;
              }
            });
            return res;
          }, {})
        );
        return result;
    }

    kFormatter(num: any) {
        return Number(num / 1000);
    }

    mFormatter(num: any) {
        return Number(num / 1000000);
    }

    roundMaxValue(value: number): number {
        if (value <= 100) {
            return 100;
        } else {
            const orderOfMagnitude = Math.pow(10, Math.floor(Math.log10(value) - 1));
            let result = Math.ceil(value / orderOfMagnitude) * orderOfMagnitude;
            let secondDigit = parseInt(result.toString().charAt(1));
            if (secondDigit % 2 !== 0) {
              secondDigit += 1;
              const firstDigit = parseInt(result.toString().charAt(0));
              if (secondDigit === 10) {
                secondDigit = 0;
                result = (firstDigit + 1) * Math.pow(10, Math.floor(Math.log10(value)));
              } else {
                const resultString = result.toString();
                result = parseInt(resultString.charAt(0) + secondDigit + resultString.substring(2));
              }
            }
            return result;
        }
    }

    transformData() {
        //BAPCTY data
        const bapctyData: {
                BY_FILTER: string;
                BAPC_TY: number }[] =
            this.hqTimelineData.map((item: any) => ({
                BY_FILTER: item.BY_FILTER,
                BAPC_TY: item.BAPC_TY,
        }));
        const objBAPCTY = this.formatChartData("BAPC_TY", bapctyData);
        this.arrBAPCTY = objBAPCTY.result;
        this.hozBarBAPCTY = objBAPCTY.hozBarCss;
        this.hozBarOppBAPCTY = objBAPCTY.hozBarOppCss;
        this.hozBarTitleBAPCTY = `${this.kFormatter(objBAPCTY.hozBarTitle).toLocaleString()}`;
        this.hozBarIndexBAPCTY = `${'0'}`;
        this.isBAPCTYHasNegativeElement = objBAPCTY.result.some(e => !e.isPositive);

        //BAPCVSBP data
        const bapcvsbpData: {
            BY_FILTER: string;
            BAPC_VS_BP: number;
        }[] = this.hqTimelineData.map((item: any) => ({
            BY_FILTER: item.BY_FILTER,
            BAPC_VS_BP: (parseFloat(item.BAPC_VS_BP)*100).toFixed(1) ,
        }));
        const objBAPCVSBP = this.formatChartData("BAPC_VS_BP", bapcvsbpData);
        this.arrBAPCVSBP = objBAPCVSBP.result;
        this.hozBarBAPCVSBP = objBAPCVSBP.hozBarCss;
        this.hozBarOppBAPCVSBP = objBAPCVSBP.hozBarOppCss;
        this.hozBarTitleBAPCVSBP = `+${objBAPCVSBP.hozBarTitle.toFixed(1)}`;
        this.hozBarTitleOppBAPCVSBP = `-${objBAPCVSBP.hozBarTitle.toFixed(1)}`;

        //BAPCVSLY data
        const bapcvslyData: {
            BY_FILTER: string;
            BAPC_VS_LY: number;
        }[] = this.hqTimelineData.map((item: any) => ({
            BY_FILTER: item.BY_FILTER,
            BAPC_VS_LY: (parseFloat(item.BAPC_VS_LY)*100).toFixed(1)  ,
        }));
        const objBAPCVSLY = this.formatChartData("BAPC_VS_LY", bapcvslyData);
        this.arrBAPCVSLY = objBAPCVSLY.result;
        this.hozBarOppBAPCVSLY = objBAPCVSLY.hozBarOppCss;
        this.hozBarTitleBAPCVSLY = `+${objBAPCVSLY.hozBarTitle.toFixed(1)}`;
        this.hozBarTitleOppBAPCVSLY = `-${objBAPCVSLY.hozBarTitle.toFixed(1)}`;

        //NSRTY data
        const nsrtyData: {
            BY_FILTER: string;
            NSR_TY: number;
        }[] = this.hqTimelineData.map((item: any) => ({
            BY_FILTER: item.BY_FILTER,
            NSR_TY: item.NSR_TY,
        }));
        const objNSRTY = this.formatChartData("NSR_TY", nsrtyData);
        this.arrNSRTY = objNSRTY.result;
        this.hozBarNSRTY = objNSRTY.hozBarCss;
        this.hozBarOppNSRTY = objNSRTY.hozBarOppCss;
        this.hozBarTitleNSRTY = `${this.mFormatter(objNSRTY.hozBarTitle).toLocaleString()}`;
        this.hozBarIndexNSRTY = `${ '0' }`;
        this.isNSRTYHasNegativeElement = objNSRTY.result.some(e => !e.isPositive);

        //NSRVSBP data
        const nsrvsbpData: {
            BY_FILTER: string;
            NSR_VS_BP: number;
        }[] = this.hqTimelineData.map((item: any) => ({
            BY_FILTER: item.BY_FILTER,
            NSR_VS_BP: (parseFloat(item.NSR_VS_BP)*100).toFixed(1) ,
        }));
        const objNSRVSBP = this.formatChartData("NSR_VS_BP", nsrvsbpData);
        this.arrNSRVSBP = objNSRVSBP.result;
        this.hozBarNSRVSBP = objNSRVSBP.hozBarCss;
        this.hozBarOppNSRVSBP = objNSRVSBP.hozBarOppCss;
        this.hozBarTitleNSRVSBP = `+${objNSRVSBP.hozBarTitle.toFixed(1)}`;
        this.hozBarTitleOppNSRVSBP = `-${objNSRVSBP.hozBarTitle.toFixed(1)}`;

        //NSRVSLY data
        const nsrvslyData: {
            BY_FILTER: string;
            NSR_VS_LY: number;
        }[] = this.hqTimelineData.map((item: any) => ({
            BY_FILTER: item.BY_FILTER,
            NSR_VS_LY: (parseFloat(item.NSR_VS_LY)*100).toFixed(1) ,
        }));
        const objNSRVSLY = this.formatChartData("NSR_VS_LY", nsrvslyData);
        this.arrNSRVSLY = objNSRVSLY.result;
        this.hozBarNSRVSLY = objBAPCVSLY.hozBarCss;
        this.hozBarOppNSRVSLY = objNSRVSLY.hozBarOppCss;
        this.hozBarTitleNSRVSLY = `+${objNSRVSLY.hozBarTitle.toFixed(1)}`;
        this.hozBarTitleOppNSRVSLY = `-${objNSRVSLY.hozBarTitle.toFixed(1)}`;

        //GPTY data
        const gptyData: {
            BY_FILTER: string;
            GP_TY: number;
        }[] = this.hqTimelineData.map((item: any) => ({
            BY_FILTER: item.BY_FILTER,
            GP_TY: item.GP_TY,
        }));
        const objGPTY = this.formatChartData("GP_TY", gptyData);
        this.arrGPTY = objGPTY.result;
        this.hozBarGPTY = objGPTY.hozBarCss;
        this.hozBarOppGPTY = objGPTY.hozBarOppCss;
        this.hozBarTitleGPTY = `${this.mFormatter(objGPTY.hozBarTitle).toLocaleString()}`;
        this.hozBarIndexGPTY = `${'0'}`;
        this.isGPTYHasNegativeElement = objGPTY.result.some(e => !e.isPositive);

        //GPVSBP data
        const gpvsbpData: {
            BY_FILTER: string;
            GP_VS_BP: number;
        }[] = this.hqTimelineData.map((item: any) => ({
            BY_FILTER: item.BY_FILTER,
            GP_VS_BP: (parseFloat(item.GP_VS_BP)*100).toFixed(1) ,
        }));
        const objGPVSBP = this.formatChartData("GP_VS_BP", gpvsbpData);
        this.arrGPVSBP = objGPVSBP.result;
        this.hozBarGPVSBP = objBAPCVSLY.hozBarCss;
        this.hozBarOppGPVSBP = objGPVSBP.hozBarOppCss;
        this.hozBarTitleGPVSBP = `+${objGPVSBP.hozBarTitle.toFixed(1)}`;
        this.hozBarTitleOppGPVSBP = `-${objGPVSBP.hozBarTitle.toFixed(1)}`;

        //GPVSLY data
        const gpvslyData: {
            BY_FILTER: string;
            GP_VS_LY: number;
        }[] = this.hqTimelineData.map((item: any) => ({
            BY_FILTER: item.BY_FILTER,
            GP_VS_LY: (parseFloat(item.GP_VS_LY)*100).toFixed(1) ,
        }));
        const objGPVSLY = this.formatChartData("GP_VS_LY", gpvslyData);
        this.arrGPVSLY = objGPVSLY.result;
        this.hozBarGPVSLY = objGPVSLY.hozBarCss;
        this.hozBarOppGPVSLY = objGPVSLY.hozBarOppCss;
        this.hozBarTitleGPVSLY = `+${objGPVSLY.hozBarTitle.toFixed(1)}`;
        this.hozBarTitleOppGPVSLY = `-${objGPVSLY.hozBarTitle.toFixed(1)}`;

        this.arrDays = this.hqTimelineData.map((item: any) => ({
            value: item.BY_FILTER,
        }));
    }

    formatChartData(category: string,data: any[]): {result: any[]; hozBarCss: any; hozBarOppCss: any; hozBarTitle: number;} {
        let result: any[];
        let maxValue: number;
        let hozBarCss: any;
        let hozBarOppCss: any;
        let hozBarTitle: number;
        let isHasNegative: boolean;
        switch (category) {
            case "BAPC_TY":
                maxValue = this.roundMaxValue(Math.abs(Math.ceil(Math.max(...data.map((item) => item.BAPC_TY)))));
                isHasNegative = data.some(item => item.BAPC_TY < 0);
                result = data.map(item => {
                    const percentage = isHasNegative ? (Math.abs(item.BAPC_TY) / (maxValue / 2) * 100) : (parseFloat(item.BAPC_TY) / maxValue * 100);
                    const formattedNumber = this.kFormatter(item.BAPC_TY);
                    const formattedValue = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(Math.round(formattedNumber));
                    const value = `${formattedValue}`; 

                    const height = Math.min(Math.abs(percentage), isHasNegative ? 195 : 99);
                    const isPositive = item.BAPC_TY >= 0;
                    return { value, height, isPositive };
                });

                hozBarCss = {
                    top: "auto",
                    bottom: `${50}%`,
                };
                hozBarTitle = maxValue / 2;
                return { result, hozBarCss, hozBarOppCss, hozBarTitle };
            case "NSR_TY":
            case "GP_TY":
                maxValue = this.roundMaxValue(Math.abs(Math.ceil(Math.max(...data.map((item) => Math.abs(item[category]))))));
                isHasNegative = data.some(item => item[category] < 0);
                result = data.map(item => {
                    const percentage = isHasNegative ? (Math.abs(item[category]) / (maxValue / 2) * 100) : (parseFloat(item[category]) / maxValue * 100);
                    const formattedNumber = this.mFormatter(item[category]);
                    const formattedValue = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(Math.round(formattedNumber));
                    const value = `${formattedValue}`;
                    const height =  Math.min(Math.abs(percentage), isHasNegative ? 195 : 99);
                    const isPositive = item[category] >= 0;
                    return { value, height, isPositive };
                });

                hozBarCss = {
                    top: "auto",
                    bottom: `${50}%`,
                };
                hozBarOppCss = {
                    top: "auto",
                    bottom: `-${52}%`,
                };
                hozBarTitle = maxValue / 2;
                return { result, hozBarCss, hozBarOppCss, hozBarTitle };
            case "BAPC_VS_BP":
            case "BAPC_VS_LY":
            case "NSR_VS_BP":
            case "NSR_VS_LY":
            case "GP_VS_BP":
            case "GP_VS_LY":
                maxValue = 100;
                result = data.map((item) => {
                const percentage = (parseFloat(item[category]) / maxValue * 100);
                const height = Math.min(Math.abs(percentage), 195);
                return {
                    value: isNaN(parseFloat(item[category])) ? '' : `${parseFloat(item[category]).toFixed(1)}`,
                    height,
                    isPositive: item[category] >= 0,
                };
                });
                hozBarCss = {
                    top: "auto",
                    bottom: `${50}%`,
                };
                hozBarOppCss = {
                    top: "auto",
                    bottom: `-${52}%`,
                };
                hozBarTitle = maxValue;
                return { result, hozBarCss, hozBarOppCss, hozBarTitle };
            default:
                result = data;
                return { result, hozBarCss, hozBarOppCss, hozBarTitle };
        }
    }
    formatAggregateBy(value:string){
        var arr = [{value: 'daily', name: 'By day'},
                {value: 'weekly', name: 'By week'},
                {value: 'monthly', name: 'By month'},
                {value: 'quarterly', name: 'By quarter'},
                {value: 'yearly', name: 'By year'}];
        return arr.find((item)=>item.value == value).name;
    }

    @ViewChild('horizontalScrollTable') horizontalScrollTable: ElementRef;
    initPrintTimeline(){
      const table    = this.horizontalScrollTable.nativeElement;
      const head     = table.getElementsByClassName('table-row__head vertial_chart__head')[0] || null;
      const cells    = table.getElementsByClassName('bar-item') || null;
      const daycells = table.getElementsByClassName('bar-item-canvas') || null;

      const tableRect = table?.getBoundingClientRect() || null;
      const headRect  = head?.getBoundingClientRect() || null;
      const visibleHead = (headRect?.right >= tableRect?.left) ? (headRect?.right - tableRect?.left) : 0;

      cells.forEach(function(cell) {
        const cellRect = cell.getBoundingClientRect();
        if (
          cellRect.left >= tableRect.left + visibleHead - 10 &&
          cellRect.right <= tableRect.right
        ) {
          cell.classList.add('visible-cell');
          cell.classList.remove('hidden-cell');
        } else {
          cell.classList.remove('visible-cell');
          cell.classList.add('hidden-cell');
        }
      });

      daycells.forEach(function(daycell) {
        const cellRect = daycell.getBoundingClientRect();
        if (
          cellRect.left >= tableRect.left + visibleHead - 10 &&
          cellRect.right <= tableRect.right
        ) {
          daycell.classList.add('visible-cell');
          daycell.classList.remove('hidden-cell');
        } else {
          daycell.classList.remove('visible-cell');
          daycell.classList.add('hidden-cell');
        }
      });
    }


    @ViewChild('pdf_print_hq_timeline', { static: false }) pdf_print_hq_timeline: ElementRef;
    exportPDF(name){
        this.initPrintTimeline();

        const $ = window["jQuery"];
        $(".hidden-cell").css('display', 'none');
        $("body").addClass("pdf-printing-se pdf-printing");
        $(".table-container").css('height', 'auto');
        $(".table-container").css('overflow-y', 'scroll');
        $(".filter-section").css('display', 'none');

        htmlToImage.toCanvas(this.pdf_print_hq_timeline.nativeElement, { quality: 1 })
            .then(function (canvas) {
                let pdfProperty: PDFProperty = {
                    option: { margin: [50, 0, 46, 0] },
                    canvas: canvas
                }
                downloadAsPDF(name, null, false, pdfProperty, 'p');
            });
    }

    exportPT_CSV_by_Url() {
        const obj = HQProgressHelper.HQTimeLineSetQueryString(
            this.aggregateBy,
            this.lstOption_Selected_Applied
        );

        const startDate = moment(this.fromDate).format("YYYY-MM-DD");
        const endDate   = moment(this.toDate).format("YYYY-MM-DD");

        this.analyticsQuery = {
            ...obj,
            'CacheKey' : `${obj['CacheKey']}&StartDate=${startDate}&EndDate=${endDate}`,
            'StartDate': startDate,
            'EndDate'  : endDate
        }

        this.csvService.getCsvUrl(AnalyticsQueries.hqProgressTimeline, this.analyticsQuery, '')
            .subscribe((data) => {
                if (data) {
                    this._downloadCsv(data?.Csv_Url);
                }
            });
    }

    exportPT_CSV() {
        this.analyticsQuery = HQProgressHelper.HQTimeLineSetQueryString(this.aggregateBy, this.lstOption_Selected_Applied);
        HQProgressHelper.GetHQTimelineDataByAggregateKey(
            this.http,
            this.analyticsQuery,
            moment(this.fromDate).format("YYYY-MM-DD"),
            moment(this.toDate).format("YYYY-MM-DD"),
            true
        ).then((hqTimelineData) => {
            this.hqTimelineData_CSV = hqTimelineData;
            this.hqTimelineData_CSV.forEach(element => {
                if (this.aggregateBy === "weekly"){
                    element.BY_FILTER = this.getFirstDayOfWeekAsString(element.BY_FILTER);
                }
                if (this.aggregateBy === "monthly"){
                    element.BY_FILTER = moment(new Date(element.BY_FILTER)).format("YYYY/MM");
                }
            });

            if(this.aggregateBy === "weekly"){ // now accept sort for weekly,   we can allow sort for all aggregateBy
                this.hqTimelineData_CSV.sort((a, b) => {
                  if (a.BY_FILTER < b.BY_FILTER) {
                      return -1;
                  } else if (a.BY_FILTER > b.BY_FILTER) {
                      return 1;
                  } else {
                      return 0;
                  }
                 });
            }


            var fileName = "Progress Timeline"
            var data = [];
            var filters: any;
            filters = {
                saleDate: moment(this.fromDate).format("YYYY-MM-DD") + ' to ' + moment(this.toDate).format("YYYY-MM-DD") ,
                saleHQLev1: String(this.lstOption_Selected_Applied.SALES_HQ_LEV1_NAME),
                saleHQLev2: String(this.lstOption_Selected_Applied.SALES_HQ_LEV2_NAME),
                channelLev1: String(this.lstOption_Selected_Applied.CHANNEL_LEV1_NAME),
                channelLev2: String(this.lstOption_Selected_Applied.CHANNEL_LEV2_NAME),
                channelLev3: String(this.lstOption_Selected_Applied.CHANNEL_LEV3_NAME),
                channel_NL: String(this.lstOption_Selected_Applied.CHANNEL_NL),
                aggregateBy: String(this.aggregateBy == null ? 'By day' : this.aggregateBy)
            };
    
            this.hqTimelineData_CSV.forEach(items => {
                data.push({
                    saleDate: filters.saleDate,
                    saleHQLev1: filters.saleHQLev1,
                    saleHQLev2: filters.saleHQLev2,
                    channelLev1: filters.channelLev1,
                    channelLev2: filters.channelLev2,
                    channelLev3: filters.channelLev3,
                    channel_NL : filters.channel_NL,
                    aggregateBy: this.formatAggregateBy(filters.aggregateBy),
                    "Date": items['BY_FILTER_Display']  === null ? '' : items['BY_FILTER_Display'] ,
                    "BAPC_TY": items['BAPC_TY_Display']  === null ? '' : items['BAPC_TY_Display'],
                    "BAPC_VS_BP": items['BAPC_VS_BP_Display']  === null ? '' : items['BAPC_VS_BP_Display'],
                    "BAPC_VS_LY": items['BAPC_VS_LY_Display']  === null ? '' : items['BAPC_VS_LY_Display'],
                    "NSR_TY": items['NSR_TY_Display']  === null ? '' : items['NSR_TY_Display'],
                    "NSR_VS_BP": items['NSR_VS_BP_Display']  === null ? '' : items['NSR_VS_BP_Display'],
                    "NSR_VS_LY": items['NSR_VS_LY_Display']  === null ? '' : items['NSR_VS_LY_Display'],
                    "GP_TY": items['GP_TY_Display']  === null ? '' : items['GP_TY_Display'],
                    "GP_VS_BP": items['GP_VS_BP_Display']  === null ? '' : items['GP_VS_BP_Display'],
                    "GP_VS_LY": items['GP_VS_LY_Display']  === null ? '' : items['GP_VS_LY_Display']
                })
              });
    
    
            new AngularCsv(data, fileName, {
                showLabels: true,
                headers: ['Sales Date', 'Sales HQ Lev1', 'Sales HQ Lev2', 'Channel Lev1', 'Channel Lev2', 'Channel Lev3','National/Local', 'Aggregate By', 'Date', 
                    'BAPC TY', 'BAPC% VS BP',  'BAPC% VS LY', 'NSR TY',  'NSR% VS BP', 'NSR% VS LY', 'GP TY', 'GP% VS BP','GP% VS LY'  
                ],
            });
        });


    }

    private _downloadCsv(fileUrl: any) {
        if (fileUrl && fileUrl.length > 0) {
          const link = this.downloadLink.nativeElement;
          link.href = fileUrl;
          link.download = '';
          link.click();
        }
    }
}
