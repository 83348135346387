import { Injectable, ElementRef } from '@angular/core';

declare const mixpanel: any;

@Injectable({
  providedIn: 'root'
})
export class MixPanelTrackingService {
  private observer       : IntersectionObserver;
  private elementsToTrack: Map <Element, {isVisible: boolean; wasTracked: boolean; previousIsVisible: boolean}> = new Map();
  private scrollTimeout  : any;

  private readonly TRACKING_NAMES = {
    APPLY_BUTTON          : 'Apply Button Clicked',
    AGGREGATE_DROPDOWN    : 'Select Dropdown Aggregated',
    CHANGE_TAB            : 'Tab Change Clicked',
    SCROLL_IN_VIEW        : 'Scrolled End',
    ENABLE_FILTER         : 'Filter Enabled',
    DISABLE_FILTER        : 'Filter Disabled',
    OPEN_ADD_MORE_FILTERS : 'Add more filters Opened',
    CLOSE_ADD_MORE_FILTERS: 'Add more filters Closed',
    RESET_ADD_MORE_FILTERS: 'Add more filters Reseted',
    CHANGE_PAGING         : 'Paging Clicked',
    SORT                  : 'Sort Clicked',
  };

  private readonly PAGE_NAMES = {
    HQ_PROGRESS            : 'Region Progress',
    DISTRICT_ROUTE_PROGRESS: 'District - Route Progress',
    SKU_DEEPDIVE           : 'Product Breakdown',
    AREA_DEEPDIVE          : 'Area Breakdown',
    PROGRESS_RANKING       : 'Progress Ranking',
    FACESHARE              : 'SOVI',
    POC                    : 'PoC'
  };

  constructor() {
    this.createObserver();
    window.addEventListener('scroll', this.handleScrollStop.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.handleScrollStop);
  }

  public onTrackFilterApplyBtn(sectionName: string, selectedFilter: object): void {
    const name = this.TRACKING_NAMES.APPLY_BUTTON;
    const data = {
      page   : this.getPageNameByPathname(location.pathname),
      url    : location.pathname,
      section: sectionName
    };

    for (const key in selectedFilter) {
      if (selectedFilter.hasOwnProperty(key)) {
        data[key] = selectedFilter[key].join(', ');
      }
    }

    console.log(name, data);
    mixpanel.track(name, data);
  }

  public onTrackDropdownAggregate(sectionName: string, filterName: string, selectedFilter: string[]) {
    const name = this.TRACKING_NAMES.AGGREGATE_DROPDOWN;
    const data = {
      page   : this.getPageNameByPathname(location.pathname),
      url    : location.pathname,
      section: sectionName,
      title  : filterName,
      value  : selectedFilter.join(', ')
    };

    console.log(name, data);
    mixpanel.track(name, data);
  }

  public onTrackEnableDisableFilter(sectionName: string, filterName: string, isEnable: boolean) {
    const name = isEnable ? this.TRACKING_NAMES.ENABLE_FILTER : this.TRACKING_NAMES.DISABLE_FILTER;
    const data = {
      page   : this.getPageNameByPathname(location.pathname),
      url    : location.pathname,
      section: sectionName,
      title  : filterName,
    };

    console.log(name, data);
    mixpanel.track(name, data);
  }

  public onTrackAddMoreFilter(sectionName: string, isOpen: boolean) {
    const name = isOpen ? this.TRACKING_NAMES.OPEN_ADD_MORE_FILTERS : this.TRACKING_NAMES.CLOSE_ADD_MORE_FILTERS;
    const data = {
      page   : this.getPageNameByPathname(location.pathname),
      url    : location.pathname,
      section: sectionName,
    };

    console.log(name, data);
    mixpanel.track(name, data);
  }

  public onTrackResetAddMoreFilter(sectionName: string) {
    const name = this.TRACKING_NAMES.RESET_ADD_MORE_FILTERS;
    const data = {
      page   : this.getPageNameByPathname(location.pathname),
      url    : location.pathname,
      section: sectionName,
    };

    console.log(name, data);
    mixpanel.track(name, data);
  }

  public onTrackingTabChange(sectionName: string, tabName: string) {
    const name = this.TRACKING_NAMES.CHANGE_TAB;
    const data = {
      page   : this.getPageNameByPathname(location.pathname),
      url    : location.pathname,
      value  : tabName,
      section: sectionName,
    };

    console.log(name, data);
    mixpanel.track(name, data);
  }

  public onTrackingPagingChange(sectionName: string, currentPage: number) {
    const name = this.TRACKING_NAMES.CHANGE_PAGING;
    const data = {
      page   : this.getPageNameByPathname(location.pathname),
      url    : location.pathname,
      section: sectionName,
      value  : currentPage,
    };

    console.log(name, data);
    mixpanel.track(name, data);
  }

  public onTrackingSortChange(sectionName: string, sortByName: string, sortTypeName: string) {
    const name = this.TRACKING_NAMES.SORT;
    const data = {
      page   : this.getPageNameByPathname(location.pathname),
      url    : location.pathname,
      section: sectionName,
      sortBy  : sortByName,
      sortType: sortTypeName
    };

    console.log(name, data);
    mixpanel.track(name, data);
  }

  createObserver() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const isVisible   = entry.isIntersecting;
        const trackedData = this.elementsToTrack.get(entry.target);

        if (trackedData) {
          trackedData.isVisible = isVisible;

          if (!isVisible) {
            trackedData.wasTracked = false;
          }
        }
      });
    }, { threshold: [0.1, 1.0] });
  }

  observeElement(element: ElementRef) {
    if (element && element.nativeElement) {
      this.elementsToTrack.set(element.nativeElement, {
        isVisible : false,
        wasTracked: false,
        previousIsVisible: false
      });
      this.observer.observe(element.nativeElement);
    }
  }

  handleScrollStop() {
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      this.elementsToTrack.forEach((data, element) => {
        if (data.isVisible && !data.wasTracked) {
          const name = this.TRACKING_NAMES.SCROLL_IN_VIEW;
          const trackingData = {
            page   : this.getPageNameByPathname(location.pathname),
            url    : location.pathname,
            section: element.innerHTML,
          };

          console.log(name, trackingData);
          mixpanel.track(name, trackingData);
          data.wasTracked = true;
        }
      });
    }, 300);
  }

  // Helper
  private getPageNameByPathname(url: string) {
    let pageName = '';

    if (url.indexOf('/hq-progress') !== -1) {
      pageName = this.PAGE_NAMES.HQ_PROGRESS;
    }

    if (url.indexOf('/district-route-progress') !== -1) {
      pageName = this.PAGE_NAMES.DISTRICT_ROUTE_PROGRESS;
    }

    if (url.indexOf('/sku-deepdive') !== -1) {
      pageName = this.PAGE_NAMES.SKU_DEEPDIVE;
    }

    if (url.indexOf('/area-deepdive') !== -1) {
      pageName = this.PAGE_NAMES.AREA_DEEPDIVE;
    }

    if (url.indexOf('/progress-ranking') !== -1) {
      pageName = this.PAGE_NAMES.PROGRESS_RANKING;
    }

    if (url.indexOf('/sovi') !== -1) {
      pageName = this.PAGE_NAMES.FACESHARE;
    }

    if (url.indexOf('/poc') !== -1) {
      pageName = this.PAGE_NAMES.POC;
    }

    return pageName
  }
}
