<div>
  <div class="filter-section even-wrapper">
    <ng-container *ngIf="lstOption_IsUsed?.CHANNEL">
      <div class="ng-section summary-section select-single-dropdown">
        <b>Channel</b>
        <ng-select #ngFilterDateType class="form-control filter-by-category sense-single-dropdown"
          [searchable]="false"
          [virtualScroll]="true"
          [(ngModel)]="lstOption_Selected?.CHANNEL[0]"
          [multiple]="false"
          [closeOnSelect]="false"
          (blur)="onSingleDropdownBlur(true, 'CHANNEL', 'Channel')"
          [clearable]="false">
              <ng-option *ngFor="let item of lstOptions?.CHANNEL" [value]="item.value">
                  <input type="checkbox" class="cb-multi-select"/>
                  <ng-container *ngIf="item?.child">&nbsp;&nbsp;&nbsp;</ng-container>
                  {{item.value}}
              </ng-option>
              <ng-template ng-footer-tmp>
                  <button type="submit" class="btn btn-primary" [disabled]="lstOption_Selected?.CHANNEL.length == 0"
                  (click)="onSingleDropdownBlur(false, 'CHANNEL', 'Channel');ngFilterDateType.close()">Aggregate</button>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items">
                  <ng-container *ngFor="let item of items; index as i">
                      <ng-container *ngIf="i==0">
                          <div class="text-over-dropdown">{{item}}</div>
                      </ng-container>
                  </ng-container>
                  <ng-container *ngIf="items.length > 1">
                      ...
                  </ng-container>
              </ng-template>
          </ng-select>
      </div>
    </ng-container>

    <ng-container *ngIf="lstOption_IsUsed?.SALES_DISTRICT_GROUP">
      <div class="ng-section summary-section select-single-dropdown">
        <b>Area</b>
        <ng-select #ngFilterDateType class="form-control filter-by-category sense-single-dropdown"
          [searchable]="false"
          [virtualScroll]="true"
          [(ngModel)]="lstOption_Selected?.SALES_DISTRICT_GROUP[0]"
          [multiple]="false"
          [closeOnSelect]="false"
          (blur)="onSingleDropdownBlur(true, 'SALES_DISTRICT_GROUP', 'Area')"
          [clearable]="false">
              <ng-option *ngFor="let item of lstOptions?.SALES_DISTRICT_GROUP" [value]="item.value">
                  <input type="checkbox" class="cb-multi-select"/>{{item.value}}
              </ng-option>
              <ng-template ng-footer-tmp>
                  <button type="submit" class="btn btn-primary" [disabled]="lstOption_Selected?.SALES_DISTRICT_GROUP.length == 0"
                  (click)="onSingleDropdownBlur(false, 'SALES_DISTRICT_GROUP', 'Area');ngFilterDateType.close()">Aggregate</button>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items">
                  <ng-container *ngFor="let item of items; index as i">
                      <ng-container *ngIf="i==0">
                          <div class="text-over-dropdown">{{item}}</div>
                      </ng-container>
                  </ng-container>
                  <ng-container *ngIf="items.length > 1">
                      ...
                  </ng-container>
          </ng-template>
        </ng-select>
      </div>
    </ng-container>

    <ng-container *ngIf="lstOption_IsUsed?.SALES_LOCATION_TYPE_NAME_EN">
      <div class="ng-section summary-section select-single-dropdown">
        <b>Teiban Type</b>
        <ng-select #ngFilterDateType class="form-control filter-by-category sense-single-dropdown"
          [searchable]="false"
          [virtualScroll]="true"
          [(ngModel)]="lstOption_Selected?.SALES_LOCATION_TYPE_NAME_EN[0]"
          [multiple]="false"
          [closeOnSelect]="false"
          (blur)="onSingleDropdownBlur(true, 'SALES_LOCATION_TYPE_NAME_EN', 'Teiban Type')"
          [clearable]="false">
              <ng-option *ngFor="let item of lstOptions?.SALES_LOCATION_TYPE_NAME_EN" [value]="item.value">
                  <input type="checkbox" class="cb-multi-select"/>
                  <ng-container *ngIf="item?.child">&nbsp;&nbsp;&nbsp;</ng-container>
                  {{item.value}}
              </ng-option>
              <ng-template ng-footer-tmp>
                  <button type="submit" class="btn btn-primary" [disabled]="lstOption_Selected?.SALES_LOCATION_TYPE_NAME_EN.length == 0"
                  (click)="onSingleDropdownBlur(false, 'SALES_LOCATION_TYPE_NAME_EN', 'Teiban Type');ngFilterDateType.close()">Aggregate</button>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items">
                  <ng-container *ngFor="let item of items; index as i">
                      <ng-container *ngIf="i==0">
                          <div class="text-over-dropdown">{{item}}</div>
                      </ng-container>
                  </ng-container>
                  <ng-container *ngIf="items.length > 1">
                      ...
                  </ng-container>
              </ng-template>
          </ng-select>
      </div>
    </ng-container>
  </div>
</div>
