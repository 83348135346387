<div id="pdf_print_channel_2" #pdf_print_channel_2 class="row">
  <div class="col-lg-12 col-md-12">
    <div class="card mb-30">

      <div class="filter-section filter-section__area-deepdive even-wrapper" [ngClass]="isLoading ? 'unclickable' : ''">
        <single-dropdown
          title="Channel Lev"
          [items]="lstOption_ChannelLev"
          [model]="lstOption_Selected_ChannelLev"
          (change)="onSaveChannelLevChange($event)">
        </single-dropdown>
      </div>

      <div *ngIf="isLoading" class="text-center spinner-wrapper">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div class="filter-container-pdf">
        <div class="filter-list row">
            <b class="filter-list-header col-1half">Date Type: </b>
            <div class="filter-list-content col-10half">
                <span>{{this.lstOption_Selected_DateType}}</span>
            </div>
        </div>
        <div class="filter-list row">
            <b class="filter-list-header col-1half">Sales Date: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_SalesDate}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.CHANNEL_LEV1_NAME == 'enable'">
            <b class="filter-list-header col-1half">Channel Lev1:</b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.CHANNEL_LEV1_NAME}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.CHANNEL_LEV2_NAME == 'enable'">
            <b class="filter-list-header col-1half">Channel Lev2:</b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.CHANNEL_LEV2_NAME}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.CHANNEL_LEV3_NAME == 'enable'">
            <b class="filter-list-header col-1half">Channel Lev3:</b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.CHANNEL_LEV3_NAME}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.SALES_HQ_LEV1_NAME == 'enable'">
            <b class="filter-list-header col-1half">Sales HQ Lev1: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.SALES_HQ_LEV1_NAME}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.CHANNEL_LEV2_NAME == 'enable'">
            <b class="filter-list-header col-1half">Sales HQ Lev2: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.CHANNEL_LEV2_NAME}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.CHANNEL_LEV3_NAME == 'enable'">
            <b class="filter-list-header col-1half">Sales HQ Lev3: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.CHANNEL_LEV3_NAME}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.SALES_CENTER_NAME == 'enable'">
            <b class="filter-list-header col-1half">Sales Center: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.SALES_CENTER_NAME}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.SALES_ROUTE_NAME == 'enable'">
            <b class="filter-list-header col-1half">Sales Route: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.SALES_ROUTE_NAME}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.KAM_DEPT_LV2_NAME == 'enable'">
            <b class="filter-list-header col-1half">KamDeptLev2: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.KAM_DEPT_LV2_NAME}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.KAM_DEPT_LV3_NAME == 'enable'">
            <b class="filter-list-header col-1half">KamDeptLev3: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.KAM_DEPT_LV3_NAME}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.KAM_DEPT_LV4_NAME == 'enable'">
            <b class="filter-list-header col-1half">KamDeptLev4: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.KAM_DEPT_LV4_NAME}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.KAM_CONTACT_PERSON_NAME == 'enable'">
            <b class="filter-list-header col-1half">KAM Account Mgr: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.KAM_CONTACT_PERSON_NAME}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.ACCOUNT_LEV2_NAME_EN == 'enable'">
            <b class="filter-list-header col-1half">Account lev2: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.ACCOUNT_LEV2_NAME_EN}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.ACCOUNT_LEV3_NAME_EN == 'enable'">
            <b class="filter-list-header col-1half">Account lev3: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.ACCOUNT_LEV3_NAME_EN}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.CATEGORY_NAME_EN == 'enable'">
            <b class="filter-list-header col-1half">Category: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.CATEGORY_NAME_EN}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.SUBCATEGORY_NAME_EN == 'enable'">
            <b class="filter-list-header col-1half">Subcategory: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.SUBCATEGORY_NAME_EN}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.BRAND_NAME_EN == 'enable'">
            <b class="filter-list-header col-1half">Brand: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.BRAND_NAME_EN}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.PACKAGE_LEV1_NAME_EN == 'enable'">
            <b class="filter-list-header col-1half">Package lev1: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.PACKAGE_LEV1_NAME_EN}}</span>
            </div>
        </div>
        <div class="filter-list row" *ngIf="lstOption_States_Applied.PACKAGE_LEV2_NAME_EN == 'enable'">
            <b class="filter-list-header col-1half">Package lev2: </b>
            <div class="filter-list-content col-10half">
                <span>{{lstOption_Selected_Applied.PACKAGE_LEV2_NAME_EN}}</span>
            </div>
        </div>
      </div>

      <!-- Data chart -->
      <div class="gap"></div>
      <h3 class="title-hidden" #observedElement>{{ sectionName }}</h3>
      <div class="row" *ngIf="!isLoading">
        <div class="col-lg-12 col-md-12">
          <div>
            <div class="card-header d-flex justify-content-between align-items-center hq-progress-title">
              <h3>{{ sectionName }}</h3>
            </div>
            <div class="card-body">
              <div class="card-body-container">
                <table [class]="'table table-content' + ' ' + lstOption_Selected_ChannelLev.toLowerCase()">
                  <thead>
                    <tr>
                      <th class="group-name" scope="col">
                        <span>Channel Lev2 Name</span>
                      </th>
                      <th  class="group-name" scope="col" *ngIf="['CHANNEL_LEV3_NAME', 'AGG_BUSINESS_TYPE_NAME'].includes(lstOption_Selected_ChannelLev)">
                        <span>Channel Lev3 Name</span>
                      </th>
                      <th class="group-name" scope="col" *ngIf="['AGG_BUSINESS_TYPE_NAME'].includes(lstOption_Selected_ChannelLev)">
                        <span>Agg Business Type Name</span>
                      </th>
                      <th class="header-container pb-2 border-start border-end">
                        <div class="header-column" (click)="sortTable('BAPC_TY')">
                          <div class="sort-column">
                            BAPC (K case)
                          </div>
                          <div class="icon-container sort-column">
                            <span class='icon-up-arrow'  [ngClass]="sortType == 'asc' && sortColumn =='BAPC_TY' ? 'red' : ''"></span>
                            <span class='icon-down-arrow' [ngClass]="sortType == 'desc' && sortColumn =='BAPC_TY' ? 'red' : ''"></span>
                          </div>
                        </div>
                        <div class="row justify-content-between">
                          <div class="col-lg-3 col-sm-3 d-flex">
                            <p class="table-mark-top" *ngIf="!isNoDataMode">0</p>
                          </div>
                          <div class="col-lg-3 col-sm-3">
                            <p class="table-mark-top" *ngIf="!isNoDataMode">{{BAPC_TYHalfColumn}}</p>
                          </div>
                          <div class="col-lg-3 col-sm-3 d-flex flex-row-reverse">
                            <p class="table-mark-top" *ngIf="!isNoDataMode">{{BAPC_TYFullColumn}}</p>
                          </div>
                        </div>
                      </th>
                      <th class="header-container pb-2 border-start border-end">
                        <div class="header-column" (click)="sortTable('NSR_TY')">
                          <div class="sort-column">
                            NSR (MM JPY)
                          </div>
                          <div class="icon-container sort-column">
                            <span  class='icon-up-arrow' [ngClass]="sortType == 'asc' && sortColumn =='NSR_TY' ? 'red' : ''"></span>
                            <span class='icon-down-arrow' [ngClass]="sortType == 'desc' && sortColumn =='NSR_TY' ? 'red' : ''"></span>
                          </div>
                        </div>
                        <div class="row justify-content-between">
                          <div class="col-lg-3 col-sm-3 d-flex">
                            <p class="table-mark-top" *ngIf="!isNoDataMode">0</p>
                          </div>
                          <div class="col-lg-3 col-sm-3">
                            <p class="table-mark-top" *ngIf="!isNoDataMode">{{NSR_TYHalfColumn}}</p>
                          </div>
                          <div class="col-lg-3 col-sm-3 d-flex flex-row-reverse">
                            <p class="table-mark-top" *ngIf="!isNoDataMode">{{NSR_TYFullColumn}}</p>
                          </div>
                        </div>
                      </th>
                      <th class="header-container pb-2 border-start border-end">
                        <div class="header-column" (click)="sortTable('GP_TY')">
                          <div class="sort-column">
                            GP (MM JPY)
                          </div>
                          <div class="icon-container sort-column">
                            <span  class='icon-up-arrow' [ngClass]="sortType == 'asc' && sortColumn =='GP_TY' ? 'red' : ''"></span>
                            <span  class='icon-down-arrow' [ngClass]="sortType == 'desc' && sortColumn =='GP_TY' ? 'red' : ''"></span>
                          </div>
                        </div>
                        <div class="row justify-content-between">
                          <div class="col-lg-3 col-sm-3 d-flex">
                            <p class="table-mark-top" *ngIf="!isNoDataMode">0</p>
                          </div>
                          <div class="col-lg-3 col-sm-3">
                            <p class="table-mark-top" *ngIf="!isNoDataMode">{{GP_TYHalfColumn}}</p>
                          </div>
                          <div class="col-lg-3 col-sm-3 d-flex flex-row-reverse">
                            <p class="table-mark-top" *ngIf="!isNoDataMode">{{GP_TYFullColumn}}</p>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                      <!--CHANNEL_LEV2_NAME-->
                    <ng-container *ngIf="!isNoDataMode && lstOption_Selected_ChannelLev === 'CHANNEL_LEV2_NAME'">
                      <tr>
                        <td class="col-lg-2 p-0 group-name">
                          <div class="th-header-content">
                            <div class="sort-column" *ngFor="let item of listGroupCategoryDetail;index  as j;">
                              <p class="px-2 item-content">{{item.CHANNEL_LEV2_NAME ?? 'NULL' }}</p>
                            </div>
                          </div>
                        </td>

                        <td class="col-lg-3 border-end p-0">
                          <div class="line-div d-flex h-100">
                            <div class="table-row__body-third__line type-bapc_ty"></div>
                            <div class="table-row__body-second__line type-bapc_ty"></div>
                            <div class="table-row__body-middle__line type-bapc_ty"></div>
                            <div class="table-row__body-start type-bapc_ty">
                              <ng-container *ngFor="let item of listGroupCategoryDetail;index  as j;">
                                <div class="bar-item" [ngStyle]="{'width.%': item.WithBAPC_TY}">
                                  <p>{{item.BAPC_TY_Format}}</p>
                                </div>
                              </ng-container>

                            </div>
                            <div class="table-line">
                                <canvas [id]="listChartId[0]?.chartBAPC">{{ listChart[0]?.chartBAPC }}</canvas>
                            </div>
                          </div>
                        </td>

                        <td class="col-lg-3 border-start border-end p-0">
                          <div class="line-div d-flex h-100">
                            <div class="table-row__body-third__line type-nsr-ty"></div>
                            <div class="table-row__body-second__line type-nsr-ty"></div>
                            <div class="table-row__body-middle__line type-nsr-ty"></div>
                            <div class="table-row__body-start type-nsr-ty">
                              <ng-container *ngFor="let item of listGroupCategoryDetail; index  as j;">
                                <div class="bar-item" [ngStyle]="{'width.%': item.WithNSR_TY}">
                                  <p>{{item.NSR_TY_Format}}</p>
                                </div>
                              </ng-container>

                            </div>
                            <div class="table-line">
                                <canvas [id]="listChartId[0]?.chartNRS">{{ listChart[0]?.chartNRS }}</canvas>
                            </div>
                          </div>
                        </td>

                        <td class="col-lg-3 border-start border-end p-0">
                          <div class="line-div d-flex h-100">
                            <div class="table-row__body-third__line type-gp_ty"></div>
                            <div class="table-row__body-second__line type-gp_ty"></div>
                            <div class="table-row__body-middle__line type-gp_ty"></div>
                            <div class="table-row__body-start type-gp_ty">
                              <ng-container *ngFor="let item of listGroupCategoryDetail; index as j;">
                                <div class="bar-item" [ngStyle]="{'width.%': item.WithGP_TY}">
                                  <p>{{item.GP_TY_Format}}</p>
                                </div>
                              </ng-container>

                            </div>
                            <div class="table-line">
                                <canvas [id]="listChartId[0]?.chartGP">{{ listChart[0]?.chartGP }}</canvas>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-container>

                    <!--CHANNEL_LEV3_NAME-->
                    <ng-container *ngIf="!isNoDataMode && lstOption_Selected_ChannelLev === 'CHANNEL_LEV3_NAME' && listGroupCategoryDetail.length > 0">
                      <tr *ngFor="let item of listGroupCategoryDetail; index  as j;" >
                          <td class="col-lg-2 p-0 group-name border-0">
                              <div class="th-header-content">
                                  <div class="sort-column">
                                      <p class="px-2 item-content">{{item.CHANNEL_LEV2_NAME ?? 'NULL' }}</p>
                                  </div>
                              </div>
                          </td>

                          <td class="col-lg-1 p-0 group-name border-0" >
                              <div class="th-header-content">
                                <div class="sort-column" *ngFor="let childItem of item.ChildItems;index  as k;">
                                  <p class="px-2">{{childItem.CHANNEL_LEV3_NAME ?? 'NULL' }}</p>
                                </div>
                              </div>
                          </td>

                          <td class="col-lg-3 border-end p-0">
                              <div class="line-div d-flex h-100">
                                  <div class="table-row__body-third__line type-bapc_ty"></div>
                                  <div class="table-row__body-second__line type-bapc_ty"></div>
                                  <div class="table-row__body-middle__line type-bapc_ty"></div>
                                  <div class="table-row__body-start type-bapc_ty">
                                      <ng-container *ngFor="let childItem of item.ChildItems;index  as k;">
                                          <div class="bar-item" [ngStyle]="{'width.%': childItem.WithBAPC_TY}">
                                            <p>{{childItem.BAPC_TY_Format}}</p>
                                          </div>
                                        </ng-container>
                                  </div>
                                  <div class="table-line">
                                      <canvas [id]="listChartId[j].chartBAPC">{{ (listChart &&listChart[j])  ? listChart[j].chartBAPC : null }}</canvas>
                                  </div>
                              </div>
                          </td>

                          <td class="col-lg-3 border-start border-end p-0">
                              <div class="line-div d-flex h-100">
                                  <div class="table-row__body-third__line type-nsr-ty"></div>
                                  <div class="table-row__body-second__line type-nsr-ty"></div>
                                  <div class="table-row__body-middle__line type-nsr-ty"></div>
                                  <div class="table-row__body-start type-nsr-ty">
                                      <ng-container *ngFor="let childItem of item.ChildItems;index  as k;">
                                          <div class="bar-item" [ngStyle]="{'width.%': childItem.WithNSR_TY}">
                                          <p>{{childItem.NSR_TY_Format}}</p>
                                      </div>
                                      </ng-container>

                                  </div>
                                  <div class="table-line">
                                      <canvas [id]="listChartId[j].chartNRS" >{{ (listChart && listChart[j]) ? listChart[j].chartNRS : null}}</canvas>
                                  </div>
                              </div>
                          </td>

                          <td class="col-lg-3 border-start border-end p-0">
                              <div class="line-div d-flex h-100">
                                  <div class="table-row__body-third__line type-gp_ty"></div>
                                  <div class="table-row__body-second__line type-gp_ty"></div>
                                  <div class="table-row__body-middle__line type-gp_ty"></div>
                                  <div class="table-row__body-start type-gp_ty">
                                      <ng-container *ngFor="let childItem of item.ChildItems;index  as k;">
                                          <div class="bar-item" [ngStyle]="{'width.%': childItem.WithGP_TY}">
                                              <p>{{childItem.GP_TY_Format}}</p>
                                          </div>
                                      </ng-container>

                                  </div>
                                  <div class="table-line">
                                      <canvas [id]="listChartId[j].chartGP" >{{ (listChart && listChart[j]) ? listChart[j].chartGP : null }}</canvas>
                                  </div>
                              </div>
                          </td>
                      </tr>
                    </ng-container>
                    <!--AGG_BUSINESS_TYPE_NAME-->
                    <ng-container
                    *ngIf="!isNoDataMode && lstOption_Selected_ChannelLev === 'AGG_BUSINESS_TYPE_NAME'">
                    <tr
                      *ngFor="let item of listGroupCategoryDetail; index  as j;">
                      <td class="col-lg-2 p-0 group-name border-0">
                        <div class="th-header-content">
                          <div class="sort-column">
                            <p
                              class="px-2 item-content">{{item.CHANNEL_LEV2_NAME
                              ?? 'NULL' }}</p>
                          </div>
                        </div>
                      </td>

                      <td class="col-lg-2 p-0 group-name border-0">
                        <div class="th-header-content">
                          <div class="sort-column">
                            <p class="px-2 item-content">{{
                              item.CHANNEL_LEV3_NAME ?? 'NULL' }}</p>
                          </div>
                        </div>
                      </td>
                      <td class="col-lg-1 p-0 group-name border-0">
                        <div class="th-header-content">
                          <div class="sort-column"
                            *ngFor="let childItem of item.ChildItems;index  as k;">
                            <p
                              class="px-2 px-2 item-content">{{childItem.AGG_BUSINESS_TYPE_NAME
                              ?? 'NULL' }}</p>
                          </div>
                        </div>
                      </td>
                      <td class="col-lg-3 border-end p-0">
                        <div class="line-div d-flex h-100">
                          <div
                            class="table-row__body-third__line type-bapc_ty"></div>
                          <div
                            class="table-row__body-second__line type-bapc_ty"></div>
                          <div
                            class="table-row__body-middle__line type-bapc_ty"></div>
                          <div class="table-row__body-start type-bapc_ty">
                            <ng-container
                              *ngFor="let childItem of item.ChildItems;index  as k;">
                              <div class="bar-item"
                                [ngStyle]="{'width.%': childItem.WithBAPC_TY}">
                                <p>{{childItem.BAPC_TY_Format}}</p>
                              </div>
                            </ng-container>
                          </div>
                          <div class="table-line">
                            <canvas [id]="listChartId[j].chartBAPC">{{
                              (listChart &&listChart[j]) ?
                              listChart[j].chartBAPC : null }}</canvas>
                          </div>
                        </div>
                      </td>

                      <td class="col-lg-3 border-start border-end p-0">
                        <div class="line-div d-flex h-100">
                          <div
                            class="table-row__body-third__line type-nsr-ty"></div>
                          <div
                            class="table-row__body-second__line type-nsr-ty"></div>
                          <div
                            class="table-row__body-middle__line type-nsr-ty"></div>
                          <div class="table-row__body-start type-nsr-ty">
                            <ng-container
                              *ngFor="let childItem of item.ChildItems;index  as k;">
                              <div class="bar-item"
                                [ngStyle]="{'width.%': childItem.WithNSR_TY}">
                                <p>{{childItem.NSR_TY_Format}}</p>
                              </div>
                            </ng-container>

                          </div>
                          <div class="table-line">
                            <canvas [id]="listChartId[j].chartNRS">{{
                              (listChart && listChart[j]) ?
                              listChart[j].chartNRS : null}}</canvas>
                          </div>
                        </div>
                      </td>

                      <td class="col-lg-3 border-start border-end p-0">
                        <div class="line-div d-flex h-100">
                          <div
                            class="table-row__body-third__line type-gp_ty"></div>
                          <div
                            class="table-row__body-second__line type-gp_ty"></div>
                          <div
                            class="table-row__body-middle__line type-gp_ty"></div>
                          <div class="table-row__body-start type-gp_ty">
                            <ng-container
                              *ngFor="let childItem of item.ChildItems;index  as k;">
                              <div class="bar-item"
                                [ngStyle]="{'width.%': childItem.WithGP_TY}">
                                <p>{{childItem.GP_TY_Format}}</p>
                              </div>
                            </ng-container>

                          </div>
                          <div class="table-line">
                            <canvas [id]="listChartId[j].chartGP">{{
                              (listChart && listChart[j]) ?
                              listChart[j].chartGP : null }}</canvas>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th class="group-name" scope="col"></th>
                      <th class="group-name" scope="col" *ngIf="['CHANNEL_LEV3_NAME', 'AGG_BUSINESS_TYPE_NAME'].includes(lstOption_Selected_ChannelLev)">
                      </th>
                      <th class="group-name" scope="col" *ngIf="['AGG_BUSINESS_TYPE_NAME'].includes(lstOption_Selected_ChannelLev)">
                      </th>
                      <th class="header-container pt-2 border-start border-end">
                        <div class="row justify-content-center">
                          <div class="col-lg-3 col-sm-3">
                            <p class="table-mark-bot">-100%</p>
                          </div>
                          <div class="col-lg-3 col-sm-3">
                            <p class="table-mark-bot">0%</p>
                          </div>
                          <div class="col-lg-3 col-sm-3">
                            <p class="table-mark-bot">100%</p>
                          </div>
                        </div>
                        <div class="header-column" (click)="sortTable('BAPC_VS_LY')">
                          <div class="sort-column">
                            % vs LY
                          </div>
                          <div class="icon-container sort-column">
                            <span id="NSR_DIFF_VS_BPASC" class='icon-up-arrow' [ngClass]="sortType == 'asc' && sortColumn =='BAPC_VS_LY' ? 'red' : ''" ></span>
                            <span id="NSR_DIFF_VS_BPDESC" class='icon-down-arrow' [ngClass]="sortType == 'desc' && sortColumn =='BAPC_VS_LY' ? 'red' : ''"></span>
                          </div>
                        </div>
                      </th>
                      <th class="header-container pt-2 border-start border-end">
                        <div class="row justify-content-center">
                          <div class="col-lg-3 col-sm-3">
                            <p class="table-mark-bot">-100%</p>
                          </div>
                          <div class="col-lg-3 col-sm-3">
                            <p class="table-mark-bot">0%</p>
                          </div>
                          <div class="col-lg-3 col-sm-3">
                            <p class="table-mark-bot">100%</p>
                          </div>
                        </div>
                        <div class="header-column" (click)="sortTable('NSR_VS_LY')">
                          <div class="sort-column">
                            % vs LY
                          </div>
                          <div class="icon-container sort-column">
                            <span id="NSR_DIFF_VS_BPASC" class='icon-up-arrow' [ngClass]="sortType == 'asc' && sortColumn =='NSR_VS_LY' ? 'red' : ''" ></span>
                            <span id="NSR_DIFF_VS_BPDESC" class='icon-down-arrow' [ngClass]="sortType == 'desc' && sortColumn =='NSR_VS_LY' ? 'red' : ''"></span>
                          </div>
                        </div>
                      </th>
                      <th class="header-container pt-2 border-start border-end">
                        <div class="row justify-content-center">
                          <div class="col-lg-3 col-sm-3">
                            <p class="table-mark-bot">-100%</p>
                          </div>
                          <div class="col-lg-3 col-sm-3">
                            <p class="table-mark-bot">0%</p>
                          </div>
                          <div class="col-lg-3 col-sm-3">
                            <p class="table-mark-bot">100%</p>
                          </div>
                        </div>
                        <div class="header-column" (click)="sortTable('GP_VS_LY')">
                          <div class="sort-column">
                            % vs LY
                          </div>
                          <div class="icon-container sort-column">
                            <span  class='icon-up-arrow' [ngClass]="sortType == 'asc' && sortColumn =='GP_VS_LY' ? 'red' : ''" ></span>
                            <span  class='icon-down-arrow' [ngClass]="sortType == 'desc' && sortColumn =='GP_VS_LY' ? 'red' : ''"></span>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-row-reverse mt-4 hide-pdf-print">
        <download-button [ngClass]=" isLoading ? 'disable-button' :''">
            <a #downloadLink style="display: none;"></a>
            <button *ngIf="!isLoading" ngbDropdownItem (click)="exportCVS_by_Url()">Download CSV</button>
            <!--<button *ngIf="!isLoading" ngbDropdownItem (click)="exportPDF('vs LY by Channel Business Type')">Download PDF</button>-->
        </download-button>
      </div>

    </div>
  </div>
</div>
