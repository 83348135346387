<button type="button" data-bs-toggle="modal" [attr.data-bs-target]="'#commentModal_' + chartId + '_' + type" class="comment-button" (click)="onOpen()" (mouseover)="loadNonApex()" *ngIf="canRead">

    <ng-icon *ngIf="type!='OwnerComments'" name="IonChatbubbleEllipsesOutline" size="20px"></ng-icon>
    <div id="summaryRemarks_{{chartId}}_{{type}}"  *ngIf="type!='OwnerComments'" (refresh)="getSelfComments()">{{type === 'SummaryRemarks' ? 'Summary Remarks' : type}} <span>({{getCommentsCount()}})</span></div>
    
    <p id="ownerComments_{{chartId}}_{{type}}" *ngIf="type==='OwnerComments'" class="report-comments" (refresh)="getSelfComments()">
        <ng-icon name="IonChatbubbleEllipsesOutline" size="18px"></ng-icon><span class="owner-comment" [innerHTML]="ownerComment"></span>
    </p>

</button>

<div class="modal commentModal" id="commentModal_{{chartId}}_{{type}}" role="dialog" aria-hidden="true" *ngIf="canRead">
    <div class="modal-dialog" role="document">
        <div class="modal-content comment-content">

            <div class="close" data-bs-dismiss="modal">
                <span class="icon">&times;</span>
                <span class="title">Close</span>
            </div>

            <div class="headings">
                <h1 class="heading1">{{title1}}</h1>
                <h2 class="heading2">{{title2}}</h2>
            </div>

            <div class="image" *ngIf="image">
                <img src={{image}} alt="">
            </div>

            <div class="chat">
                <div class="top no-comments" *ngIf="getCommentsCount() === 0 && canRead">
                    <ng-icon class="icon" name="IonChatbubbleEllipsesOutline" size="24px"></ng-icon>
                    <div class="title">Leave a comment</div>
                    <div class="desc">Your insights and perspectives are valuable.</div>
                </div>
                <div class="top with-comments" *ngIf="getCommentsCount() > 0 && canRead">
                    <ng-icon class="icon" name="IonChatbubbleEllipsesOutline" size="24px"></ng-icon>
                    <div class="title">Comments ({{getCommentsCount()}})</div>
                </div>
                <div class="top no-comments no-permission" *ngIf="!canRead">
                    <ng-icon class="icon" name="IonChatbubbleEllipsesOutline" size="24px"></ng-icon>
                    <div class="title">You don't have permission to read the comments</div>
                </div>                
                <div class="bottom">                 
                    <div class="listing" *ngIf="canRead">
                        <div *ngFor="let comment of comments">
                            <div class="name-header">
                                <div class="icon">
                                    <avatar email={{comment.email}}></avatar>
                                </div>
                                <div class="text">{{getDisplayName(comment.email)}}</div>
                                <div class="time">{{getDisplayTime(comment.updatedAt)}}</div>
                                <div class="action-menu">
                                    <div ngbDropdown *ngIf="username === getDisplayName(comment.email)">
                                        <button type="button" class="btn" ngbDropdownToggle>
                                            <ng-icon name="IonEllipsisHorizontalOutline" size="16px"></ng-icon>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <button *ngIf="editCommentId===comment.id" ngbDropdownItem class="edit"
                                                (click)="editCommentId=''"><ng-icon name="IonCreateOutline"
                                                    size="16px"></ng-icon>&nbsp;Close Edit</button>
                                            <button *ngIf="editCommentId!==comment.id" ngbDropdownItem class="edit"
                                                (click)="editCommentId=comment.id;editCommentText=comment.rawMessage;"><ng-icon
                                                    name="IonCreateOutline" size="16px"></ng-icon>&nbsp;Edit</button>
                                            <div class="hr">
                                                <div class="inner"></div>
                                            </div>
                                            <button ngbDropdownItem class="delete"
                                                (click)="deleteComment(comment.id)"><ng-icon name="IonTrashOutline"
                                                    size="16px"></ng-icon>&nbsp;Delete</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <p class="comment-container" *ngIf="editCommentId !== comment.id" [innerHTML]="comment.message"></p>
                            <div class="input-outter edit" *ngIf="editCommentId===comment.id">
                                <div class="input">
                                    <div class="left">
                                        <textarea [id]="comment.id" rows="5"
                                            placeholder="Leave a comment" [(ngModel)]="editCommentText">{{editCommentText}}</textarea>
                                    </div>
                                    <div class="right">
                                        <div *ngIf="this.editCommentText?.length > 0" (click)="clearEditCommentText()"
                                            class="clear-button">
                                            <ng-icon name="IonCloseSharp" size="16px" color="#1A2D4F"></ng-icon>
                                        </div>                                        
                                        <div (click)="validateEditCommentText() && !isLoading && editComment(comment.id, editCommentText)"
                                            class="send-button {{validateEditCommentText() && !isLoading ? 'active' : ''}}">
                                            <ng-icon name="IonPaperPlaneSharp" size="16px" color="white"></ng-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="adding">
                        <div class="name-header">
                            <div class="icon">
                                <avatar email={{username}}></avatar>
                            </div>
                            <div class="text">{{username}}</div>
                        </div>
                        <div class="input-outter add">
                            <div class="input">
                                <div class="left" *ngIf="canWrite">
                                    <textarea rows="5" placeholder="Leave a comment"
                                        [(ngModel)]="newCommentText"
                                        (itemSelected)="onItemSelected($event)"></textarea>
                                </div>
                                <div class="left" *ngIf="!canWrite">
                                    <textarea rows="5" placeholder="You don't have permission to leave a comment"
                                        [(ngModel)]="newCommentText" 
                                        (itemSelected)="onItemSelected($event)" disabled=""></textarea>
                                </div>                                
                                <div class="right">
                                    <div *ngIf="this.newCommentText?.length > 0" (click)="clearNewCommentText()"
                                        class="clear-button">
                                        <ng-icon name="IonCloseSharp" size="16px" color="#1A2D4F"></ng-icon>
                                    </div>
                                    <div (click)="validateNewCommentTextCancel() && canWrite && !isLoading && onSubmit()"
                                        class="send-button {{validateNewCommentTextCancel() && !isLoading ? 'active' : ''}}">
                                        <ng-icon name="IonPaperPlaneSharp" size="16px" color="white"></ng-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="input-outter error" *ngIf="validationError">
                            <p class="red">{{validationError}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <button type="button" class="btn btn-outline-primary">
                <span>Send "{{chatId}}" via Email </span><ng-icon name="IonArrowRedoOutline" size="16px"
                    strokeWidth="46px"></ng-icon>
            </button> -->

        </div>
    </div>
</div>

<!-- <ng-template let-item="item">
    <div class="left">
        <avatar email={{item.email}}></avatar>
    </div>
    <div class="right">
        <div class="displayName">{{item.displayName}}</div>
        <div class="email">{{item.email}}</div>
    </div>
</ng-template> -->