import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import lodashClonedeep from 'lodash.clonedeep';
import * as htmlToImage from 'html-to-image';

import { FILTER_CONFIGS, AreaDeepdiveQueries } from 'src/app/model/constants';
import { AreaDeepdiveHelper } from 'src/app/components/pages/area-deepdive/area-deepdive-helper';
import { FilterHelper }       from 'src/app/components/elements/add-more-filter/filter-helper';
import { downloadAsPDF }      from 'src/app/helpers/pdfExtend';
import { PDFProperty }        from 'src/app/model/pdfProperty.model';
import { CsvService }         from 'src/app/services/csv.services';
import { ChartServices }      from 'src/app/services/chart.services';
import { ProcessGroupedDataService } from 'src/app/services/process-grouped-data.services';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
  selector: 'app-area-deepdive-vs-ly-dealer',
  templateUrl: './vs-ly-dealer.component.html',
  styleUrls: ['./vs-ly-dealer.component.scss']
})
export class VsLyDealerComponent implements OnInit {
  FILTERS: any = FILTER_CONFIGS?.ADD_MORE_FILTER?.DEFAULT || [];
  AREA_DEEPDIVE_FILTERS = FILTER_CONFIGS?.ADD_MORE_FILTER?.COLLECTIONS?.AREA_DEEPDIVE;
  defaultVisibleFilters: number = FILTER_CONFIGS?.ADD_MORE_FILTER?.VISIBLE_COUNT || 4;

  @Input() lstOption_Selected_DateType: any;
  @Input() lstOption_Selected_SalesDate: any;
  @Input() lstAllOptionsCalendarData: any;
  @Input() lstAllOptionsAreaData: any;
  @Input() lstAllOptionsAccountData: any;
  @Input() lstAllOptionsProductData: any;
  @Input() lstOption_Selected: any;
  @Input() lstOption_States: any;
  @Input() lstOption_IsUsed: any;
  @Input() listData_Filter: any;
  @Input() updatedFilters: any;
  @Output() ready = new EventEmitter<boolean>();
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('observedElement', { static: true }) observedElement: ElementRef;

  sectionName: string = 'vs LY by Dealer';
  vsLYByDealerData: any = null;
  listGroupDealerDetail: any[] = null;

  isLoading: boolean = false;
  isNoDataMode = true;
  lstOption_States_Applied: any   = {};
  lstOption_Selected_Applied: any = {};
  areaFilterQuery: object = {};

  public listChart: any[];
  public maxBAPC_TY: number;
  public maxNSR_TY: number;
  public maxGP_TY: number;

  public BAPC_TYHalfColumn: any;
  public BAPC_TYFullColumn: any;
  public NSR_TYHalfColumn: any;
  public NSR_TYFullColumn: any;
  public GP_TYHalfColumn: any;
  public GP_TYFullColumn: any;

  sortType        : string = "desc";
  sortColumn      : string = "NSR_TY";
  chartBAPC_Dealer: any;
  chartNRS_Dealer : any;
  chartGP_Dealer  : any;

  isHasFistLoad: boolean = false;

  constructor(private http: HttpClient, private csvService: CsvService, private chartServices: ChartServices, private dataProcessingService: ProcessGroupedDataService, private mixPanelTrackingService: MixPanelTrackingService) {}

  ngOnInit() {
    this.areaFilterQuery = AreaDeepdiveHelper.skuSetQueryString(this.lstOption_Selected);
    this.lstOption_IsUsed = FilterHelper.getListIsUsedFilter(this.FILTERS, this.AREA_DEEPDIVE_FILTERS) || {};
  }

  ngOnChanges(changes: SimpleChanges) {
    if (((changes['lstOption_Selected_DateType'] && this.lstOption_Selected_DateType && this.lstOption_Selected_DateType.length > 0)
        || (changes['lstOption_Selected_SalesDate'] && this.lstOption_Selected_SalesDate && this.lstOption_Selected_SalesDate.length > 0))) {
      if (this.isHasFistLoad) {
        this.onSearchData();
      }

      this.handleonReady();
    }

    if (changes['lstOption_Selected'] || changes['lstOption_States'] || changes['updatedFilters']) {
      this.applyFilters();

      if (this.isHasFistLoad) {
        this.onSearchData();
      }
    }

    this.isHasFistLoad = true;
  }

  ngAfterViewInit(): void {
    this.mixPanelTrackingService.observeElement(this.observedElement);
  }

  applyFilters() {
    this.lstOption_States_Applied = lodashClonedeep(this.lstOption_States);
    this.lstOption_Selected_Applied = lodashClonedeep(this.lstOption_Selected);
  }

  ngOnDestroy() {
    this.destroyCharts();
  }

  onSearchData() {
    if (!this.lstOption_Selected_DateType || !this.lstOption_Selected_DateType?.length || !this.lstOption_Selected_SalesDate || !this.lstOption_Selected_SalesDate.length) {
      return;
    }

    this.isLoading = true;
    this.areaFilterQuery = AreaDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied);
    AreaDeepdiveHelper.GetDataByDealer(this.http, this.lstOption_Selected_DateType ? this.lstOption_Selected_DateType : 'MTD', !AreaDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [], this.areaFilterQuery).then((data) => {
      if (data) {
        const { Data } = data;

        if (Data) {
          this.vsLYByDealerData = Data;
          this.processData();
          this.sortTable('');
          this.isLoading = false;
        }
      }
    });
  }

  processData() {
    this.isNoDataMode = this.vsLYByDealerData.length == 0;
    const metrics     = this.dataProcessingService.processMetrics(this.vsLYByDealerData);
    Object.assign(this, metrics);

    this.listGroupDealerDetail = this.dataProcessingService.processLevel2Data(
      this.vsLYByDealerData,
      this
    );
  }

  handleonReady() {
    let t = this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }

  createChart() {
    this.destroyCharts();
    setTimeout(() => {
      const labels = this.listGroupDealerDetail.map(e => e.REP_DEALER_NAME || 'No-category');
      const bapcData = this.listGroupDealerDetail.map(e => parseFloat((e.BAPC_VS_LY * 100).toFixed(1)));
      const nsrData = this.listGroupDealerDetail.map(e => parseFloat((e.NSR_VS_LY * 100).toFixed(1)));
      const gpData = this.listGroupDealerDetail.map(e => parseFloat((e.GP_VS_LY * 100).toFixed(1)));
      try {
        this.listChart.push({
          chartBAPC_Dealer: this.chartServices.createLineChart('chartBAPC_Dealer', labels, bapcData, -200, 200),
          chartNRS_Dealer: this.chartServices.createLineChart('chartNRS_Dealer', labels, nsrData, -200, 200),
          chartGP_Dealer: this.chartServices.createLineChart('chartGP_Dealer', labels, gpData, -200, 200),
        })
      } catch (error) {
        console.error('Error creating charts:', error);
      }
    }, 500);
  }

  destroyCharts() {
    if (this.listChart?.length > 0) {
      this.listChart.forEach((chart: any) => {
        if (chart.chartBAPC_Dealer) {
          chart.chartBAPC_Dealer.destroy();
        }
        if (chart.chartNRS_Dealer) {
          chart.chartNRS_Dealer.destroy();
        }
        if (chart.chartGP_Dealer) {
          chart.chartGP_Dealer.destroy();
        }
      });
    }

    this.listChart = [];
  }

  sortTable(column: string) {
    if (column == this.sortColumn && column) {
      this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortType = "desc";
    }
    this.sortColumn = column ? column : 'NSR_TY';

    this.listGroupDealerDetail = this.sortType == 'asc' ? this.listGroupDealerDetail.slice().sort((a, b) => a[this.sortColumn] - b[this.sortColumn]) : this.listGroupDealerDetail.slice().sort((a, b) => b[this.sortColumn] - a[this.sortColumn])

    this.createChart();
  };

  // CSV Export
  exportAreaDealer_CVS_by_Url() {
    const obj       = AreaDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied);
    const dateType  = this.lstOption_Selected_DateType ? this.lstOption_Selected_DateType : 'MTD';
    const salesDate = !AreaDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [];

    this.areaFilterQuery = {
      ...obj,
      CacheKey: `DATE_TYPE=${encodeURIComponent(dateType)}&SALES_DATE=${salesDate}&${obj['CacheKey']}`,
      Selected: {
        ...obj['Selected'],
        DATE_TYPE: dateType,
        SALES_DATE: salesDate
      }
    };

    this.csvService.getCsvUrl(AreaDeepdiveQueries.byDealer, this.areaFilterQuery, '')
      .subscribe((data) => {
        if (data) {
          this._downloadCsv(data?.Csv_Url);
        }
      });
  }

  private _downloadCsv(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }

  // PDF Export
  @ViewChild('pdf_print_dealer_2', { static: false }) pdf_print_dealer_2: ElementRef;
  exportPDF(name) {
    const $ = window["jQuery"];
    $("body").addClass("pdf-printing");
    $(".table-container").css('height', 'auto');
    $(".table-container").css('overflow-y', 'scroll');
    $(".filter-section").css('display', 'none');

    const area = this.pdf_print_dealer_2.nativeElement;
    htmlToImage.toCanvas(this.pdf_print_dealer_2.nativeElement, { quality: 1 })
      .then(function (canvas) {
        const pdfProperty: PDFProperty = {
          option: { margin: [50, 0, 46, 0] },
          canvas: canvas
        }
        downloadAsPDF(name, area, false, pdfProperty);
      });
  }
}
