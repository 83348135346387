import { Component, Renderer2, OnInit, OnChanges,SimpleChanges, Input, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';
import { HQProgressHelper } from 'src/app/components/pages/hq-progress/hq_progress_helper';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { getSortColumnName } from 'src/app/helpers/sort';
import { downloadAsPDF as _downloadAsPDF } from '../../../../helpers/pdfExtend';
import { PDFProperty } from 'src/app/model/pdfProperty.model';
import { FILTER_CONFIGS_HQ, AnalyticsQueries } from 'src/app/model/constants';
import { FilterHelper } from 'src/app/components/elements/add-more-filter-hq/filter-helper-hq';
import lodashClonedeep from 'lodash.clonedeep';
import { CsvService } from 'src/app/services/csv.services';
import { UserService } from 'src/app/services/user.services';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
  selector: 'hq-national-local',
  templateUrl: './hq-national-local.component.html',
  styleUrls: ['./hq-national-local.component.scss']
})

export class HQNationalLocalComponent implements OnInit, OnChanges{
  @Input() fromDate: any;
  @Input() toDate: any;
  hqNationalLocalRawData : any;
  listColumn: any = [];
  listGroupNameRoot1: any[];
  listGroupNameRoot2: any[];
  sortType     : string = "desc";
  sortColumn   : string  = "NSR_DIFF_VS_BP";
  sortColumnName: string = '';
  totalRoot1   : any[];
  totalRoot2   : any[];
  saleDate: string = '';

  @Input() lstOption_Selected_SalesHQLev1: any= {};

  sectionName: string = 'Progress by National/Local';
  FILTERS: any        = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.KEY_NAME || [];
  HQ_PROGRESS_FILTERS = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.HQ_PROGRESS_NATION_LOCAL?.COLLECTIONS;
  defaultVisibleFilters: number = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.HQ_PROGRESS_NATION_LOCAL?.VISIBLE_COUNT ?? 4;

  listData_Filter: any = null;
  lstOption_States: any = {};
  lstOption_States_Applied: any = {};
  lstOption_Selected: any = {};
  lstOption_IsUsed: any = {};
  lstOption_Selected_Applied: any = {};

  lstOption_ChannelLev: any;
  analyticsQuery: object = {};
  isLoading:boolean = false;

  @Output() ready = new EventEmitter<boolean>();
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  @ViewChild('observedElement', { static: true }) observedElement: ElementRef;

  weeks: any;
  populated: boolean;
  aggregateBy: any;
  isHasFistLoad: boolean = false;

constructor(public datepipe: DatePipe, private http: HttpClient, private csvService: CsvService, private userService: UserService,
              private mixPanelTrackingService: MixPanelTrackingService) {}
              
  ngOnInit(): void {
    const lstOption = FilterHelper.init_lstOption_States(`HQNationalLocalComponent_${this.userService.getUserFilterName()}`, this.FILTERS, this.HQ_PROGRESS_FILTERS, this.defaultVisibleFilters, this.userService.getUserInfo()) || {};
    this.lstOption_States   = {...lstOption?.lstOption_States};
    this.lstOption_Selected = {...lstOption?.lstOption_Selected};
    this.listData_Filter  = {...lstOption.listData_Filter};
    if(lstOption?.lstOption_Selected?.AggregateBy){
        this.aggregateBy = lstOption?.lstOption_Selected?.AggregateBy ;
    }

    this.lstOption_IsUsed   = FilterHelper.getListIsUsedFilter(this.FILTERS, this.HQ_PROGRESS_FILTERS) || {};
    this.lstOption_States_Applied = {...lstOption?.lstOption_States};
    this.lstOption_Selected_Applied = {...lstOption?.lstOption_Selected}
    this.analyticsQuery = HQProgressHelper.HQLevel1SetQueryString(
      this.lstOption_Selected_Applied,
      1,
      this.sortType,
      this.sortColumn
    );

    this.sortColumnName = getSortColumnName('HQ_NATIONALLOCAL', this.sortColumn);
    this.listColumn = HQProgressHelper.ListColumnNationalLocal();
    this.onSearchData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['fromDate'] || changes['toDate']) && this.fromDate && this.toDate) {
      this.saleDate = moment(this.fromDate).format('YYYY/MM/DD') + ' to ' + moment(this.toDate).format('YYYY/MM/DD');
      if(this.isHasFistLoad){
        this.onSearchData();
      }
    }
    this.isHasFistLoad = true;
  }

  ngAfterViewInit(): void {
    this.mixPanelTrackingService.observeElement(this.observedElement);
  }

  onUpdateChart(event: any) {
    const {lstOption_States, lstOption_Selected} = event;
    if (lstOption_States && lstOption_Selected) {
        this.lstOption_Selected = {};
        this.lstOption_Selected = lstOption_Selected;
        this.lstOption_States   = {};
        this.lstOption_States   = lstOption_States;
        this.onUpdateCurrent_Filter_Applied();
        this.updateHQLevel2Data();
    }
  }

  onResetFiltersGroup(event: any) {
    const { data } = event;

    if (data && Array.isArray(data) && data.length > 0) {
    data.map(key => {
        if (key && key.length > 0) {
        this.lstOption_Selected[key] = ['All'];
        }
    });
    }
  }

  onUpdateCurrent_Filter_Applied() {
    this.lstOption_States_Applied = lodashClonedeep(this.lstOption_States);
    this.lstOption_Selected_Applied = lodashClonedeep(this.lstOption_Selected);
  }

  sortTable(defaultSort: string,column: string) {
    if(column == this.sortColumn){
      this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortType = "desc";
    }

    this.sortColumn = column;
    this.sortColumnName = getSortColumnName('HQ_NATIONALLOCAL', this.sortColumn);

    this.onSearchData();
  }

  formartNumber(category:string, number : any){
    return HQProgressHelper.FormartNumber(category,number);
  }

  formartClassForColumn(isLunisolarColor: boolean, value : any) {
    return HQProgressHelper.formartClassForColumn(isLunisolarColor,value);
  }

  public onAggregateChange(aggregateBy: string) {
    this.aggregateBy = aggregateBy;
  }

  handleonReady(){
    let t1= this;

    setTimeout(() => {
      t1.ready.emit(true);
    });
  }

  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }

  onSearchData() {
    if (this.fromDate === undefined || this.toDate === undefined) {
      return;
    }

    this.isLoading = true;

    this.analyticsQuery = HQProgressHelper.HQLevel1SetQueryString(
      this.lstOption_Selected_Applied,
      1,
      this.sortType,
      this.sortColumn,
      false
    );

    const startDate = moment(this.fromDate).format("YYYY-MM-DD");
    const endDate = moment(this.toDate).format("YYYY-MM-DD");

    HQProgressHelper.GetHQNationalLocalDataBySaleDate(
          this.http,
          startDate,
          endDate,
          this.analyticsQuery
      ).then((data) => {
        this.hqNationalLocalRawData = data;
        this.handleonReady();
          this.isLoading = false;
      });
  }

  public updateHQLevel2Data():void {
    this.onSearchData();
  }


  filteredRoots2(listRoot2: any, nameRoot1: string) {
    return listRoot2.filter(p => p["CHANNEL_NL"] === nameRoot1);
  }

  filteredRoots3(listRoot3: any,nameRoot1: string, nameRoot2 : string) {
    return listRoot3.filter(p => p["CHANNEL_NL"] === nameRoot1  && p["CHANNEL_LEV1_NAME_NL"] === nameRoot2);
  }

  filteredRoots4(listRoot4: any,nameRoot1: string, nameRoot2 : string, nameRoot3 : string) {
    return listRoot4.filter(p => p["CHANNEL_NL"] === nameRoot1  && p["CHANNEL_LEV1_NAME_NL"] === nameRoot2 && p["CHANNEL_LEV2_NAME"] === nameRoot3);
  }

  public exportHqLevel2_Excel_by_Url() {
    const obj = HQProgressHelper.HQLevel1SetQueryString(
      this.lstOption_Selected_Applied,
      1,
      this.sortType,
      this.sortColumn,
      false
    );

    const startDate = moment(this.fromDate).format("YYYY-MM-DD");
    const endDate   = moment(this.toDate).format("YYYY-MM-DD");

    const analyticsQuery = {
      ...obj,
      'CacheKey' : `${obj['CacheKey']}&StartDate=${startDate}&EndDate=${endDate}`,
      'StartDate': startDate,
      'EndDate'  : endDate
    }

    this.csvService.getCsvUrl(AnalyticsQueries.hqprogressnationallocalSaleDate, analyticsQuery, '')
      .subscribe((data) => {
        if (data) {
          this._downloadCsv(data?.Csv_Url);
        }
    });
  }

  private _downloadCsv(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }

  @ViewChild('pdf_print_hq_lev2', { static: false }) pdf_print_hq_lev2: ElementRef;
  exportHQLEV_PDF(name) {
    const $ = window["jQuery"];
    $("body").addClass("pdf-printing-se pdf-printing");

    const pdfProperty:PDFProperty={
      option: {margin: [40, 0, 40, 40]}
    }
    const area = this.pdf_print_hq_lev2.nativeElement;
    return _downloadAsPDF(name, area,false, pdfProperty );
  }
}
