import { Component, OnInit, OnChanges, Input, Output, SimpleChanges, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SalesDateService } from 'src/app/components/elements/sales-date-filter/sales-date-filter.service';
import { CsvService } from 'src/app/services/csv.services';
import { RequestService } from 'src/app/services/request.service';
import { isAllSelected, skuSetQueryString } from 'src/app/helpers/utils';
import { PocHelper } from 'src/app/components/pages/poc/poc.helper';
import { PocQueries } from 'src/app/model/constants';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
  selector: 'poc-by-account',
  templateUrl: './by-account.component.html',
  styleUrls: ['./by-account.component.scss']
})

export class PocByAccountComponent {
  @Input() lstOptionSelectedDateType: any;
  @Input() lstOptionSelectedSalesDate: any;

  @Output() ready = new EventEmitter<boolean>();
  @Output() filterReady = new EventEmitter<boolean>();
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('observedElement', { static: true }) observedElement: ElementRef;

  private salesDateSubscription: Subscription;

  sectionName = 'By Account';
  lstOption_IsUsed = {
    CHANNEL: true,
  };
  lstOptionSelected = {
    CHANNEL: ['SM'],
  };
  lstOptionApplied = {
    CHANNEL: ['SM'],
  };
  filters = { CHANNEL: 'CHANNEL' };
  categories: any[] = [];
  chartData : any[]   = [];
  isLoading : boolean = false;
  isHasFirstLoad: boolean = false;
  lstAllOptionsData: any = {};
  filterQuery: Record<string, any> = {};

  constructor(private requestService: RequestService,
              private salesDateService: SalesDateService,
              private csvService: CsvService,
              private mixPanelTrackingService: MixPanelTrackingService) {}

  ngOnInit(): void {
    this.categories = PocHelper.getAccountCategories();
    this.salesDateSubscription = this.salesDateService.getTraxSalesDate().subscribe((date: string[]) => {
      if (date && date.length > 0) {
        this.requestService.sendRequest(PocQueries.byAccountFilter, {}, true).then((res) => {
          const data = JSON.parse(res) || {};

          this.lstAllOptionsData = {
            CHANNEL: data?.CHANNEL || [],
          };

          this._handleonFilterReady();
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const hasDateTypeChange  = changes['lstOptionSelectedDateType'] && this.lstOptionSelectedDateType?.length > 0;
    const hasSalesDateChange = changes['lstOptionSelectedSalesDate'] && this.lstOptionSelectedSalesDate?.length > 0;

    if (hasDateTypeChange || hasSalesDateChange) {
        this._onSearchData();
    }

    this.isHasFirstLoad = true;
  }

  ngOnDestroy(): void {
    if (this.salesDateSubscription) {
      this.salesDateSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.mixPanelTrackingService.observeElement(this.observedElement);
  }

  // Update Chart
  public onUpdateChart(event: any) {
    const { lstOption_Selected: lstOptionSelected } = event;

    if (!lstOptionSelected) {
      return;
    }

    if (this.lstOptionSelected) {
      this.lstOptionSelected = { ...lstOptionSelected };
      this.lstOptionApplied  = { ...lstOptionSelected };
      this._onSearchData();
    }
  }

  private _handleonFilterReady() {
    let t = this;
    setTimeout(() => {
      t.filterReady.emit(true);
    });
  }

  private _onSearchData() {
    if (!this.lstOptionSelectedDateType?.length || !this.lstOptionSelectedSalesDate?.length) {
      return;
    }

    this.isLoading = true;

    try {
      const code = PocQueries.byAccount;
      this._getQueryObject();

      this.requestService.sendRequest(code, this.filterQuery, true)
        .then((res) => {
          const data = JSON.parse(res)?.ListAll || [];
          this.chartData = [...this._moveNullAccountSegmentToEnd(data)];
          this._handleonReady();
        })
        .catch((error) => {
          console.error(`Request failed: ${error}`);
        })
        .finally(() => {
          this.isLoading = false;
        })
    } catch (error) {
      console.error(`Error in _onSearchData: ${error}`);
      this.isLoading = false;
    }
  }

  private _getQueryObject() {
    const queryObject = skuSetQueryString(this.lstOptionApplied) || {};
    const salesDate   = this.lstOptionSelectedSalesDate;
    const isSalesDateSelected = !isAllSelected(salesDate);
    const encodedSalesDate    = isSalesDateSelected ? encodeURIComponent(salesDate) : '';

    this.filterQuery = {
      ...queryObject,
      CacheKey: `SALES_YM=${encodedSalesDate}${queryObject['CacheKey']}`,
      Selected: {
        ...queryObject['Selected'],
        SALES_YM: isSalesDateSelected ? salesDate : [],
      }
    };
  }

  isConsecutiveTotal(index: number): boolean {
    if (index === 0 || this.categories[index].label !== 'Total') {
      return false;
    }
    return this.categories[index - 1].label === 'Total';
  }

  isTotalWithColspan(index: number): boolean {
    return this.categories[index].label === 'Total' &&
           (index === 0 || this.categories[index - 1].label !== 'Total');
  }

  trackByFn(index: number, item: any) {
    return index;
  }

  public isNegative(value: number) {
    const isNegative = value < 0;
    return isNegative;
  }

  public getLyValueDisplay(value: number, displayValue: string) {
    if (!value || !displayValue) {
      return displayValue;
    }

    const isNegative = value < 0;
    const isPositive = value > 0;

    if (isNegative && !displayValue.startsWith('-')) {
      return `-${displayValue}`;
    }

    if (isPositive && !displayValue.startsWith('+')) {
      return `+${displayValue}`;
    }

    return displayValue;
  }

  private _moveNullAccountSegmentToEnd(array) {
    return array.sort((a, b) => {
        if (a.ACCOUNT_SEGMENT === null && b.ACCOUNT_SEGMENT !== null) {
            return 1;
        } else if (a.ACCOUNT_SEGMENT !== null && b.ACCOUNT_SEGMENT === null) {
            return -1;
        } else {
            return 0;
        }
    });
}

  private _handleonReady() {
    let t = this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }

  public downloadExcel() {
    const filterQuery = {
      CacheKey: `SALES_YM=${encodeURIComponent(this.lstOptionSelectedSalesDate)}`,
      Selected: {
        SALES_YM: this.lstOptionSelectedSalesDate
      }
    };

    this.csvService.getCsvUrl(PocQueries.byAccountCSV, filterQuery, '')
      .subscribe((data) => {
        const csvUrl = data?.Csv_Url;

        if (csvUrl) {
          this._downloadExcel(csvUrl);
        }
      });
  }

  private _downloadExcel(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }
}