import { Component, Renderer2, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { IntageHelper } from '../intage_helper';
import { downloadAsPDF as _downloadAsPDF } from './../../../../helpers/pdf';
import { IntageHelper as IntageHelperVM } from 'src/app/components/pages/intage-vm/intage_helper';
import { IndexDbHelper } from 'src/app/helpers/indexDb';
import { authorize } from 'src/app/helpers/AuthorizePage';
import { Router } from '@angular/router';
import { DropdownValueToNgSelectFormat2 } from 'src/app/helpers/dropdown';
import { getSelectedWeekFromUrl } from 'src/app/helpers/queryString';
import { UserService } from 'src/app/services/user.services';

@Component({
  selector: 'app-intage_flash',
  templateUrl: './intage_flash.component.html',
  styleUrls: ['./intage_flash.component.scss']
})
export class IntageFlashComponent implements OnInit {
  activeTab: number = 1;
  intageData: any;
  isScoreCardsReady: boolean;
  isOtcMarketSizeReady: boolean;
  isOtcMarketShareByPackageReady: boolean;
  isOtcMarketShareByRegionReady: boolean;
  isValueShareSummaryReady: boolean;
  weeks: any;
  selectedWeek: string;
  categoryColors: any;
  channelOrder: any;
  intageweek: any;
  intageDataVM: any;
  intageMonthData: any;
  users: any;
  constructor(private router: Router, public datepipe: DatePipe, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient, private userservice: UserService) {}

  async ngOnInit() {
    if (!authorize())
    {
      this.router.navigateByUrl('unauthorized');
    }
    else
    {
    
    this.userservice.GetUsers(location.pathname).then((res) => {
      this.users = res;
    });
    this.categoryColors = IntageHelper.CategoryColors();
    this.channelOrder = IntageHelper.ChannelOrders();     
    this.intageDataVM = await IndexDbHelper.Get('IntageVmData');
    this.weeks = await IndexDbHelper.Get('IntageWeeks');
    IntageHelper.GetIntageWeekData(this.http, this.router).then(async (weeks) => {
      if (this.weeks == null || this.weeks.length != weeks.length)
      {
        this.selectedWeek = weeks[0].week;
        IntageHelper.GetIntageDataFlash(this.http, this.router, this.selectedWeek).then((intage) => {
          if (this.intageData == null)
          {
            this.intageData = intage;
          }    
          IndexDbHelper.Set('IntageFlashData_' + this.selectedWeek, intage);
          this.weeks = [...new Set(weeks.map(x => x.week))];
          IndexDbHelper.Set('IntageWeeks', this.weeks);
          var weekList = this.weeks.map(x => "'" + x + "'");
          IntageHelper.GetIntageMonthFromWeek(this.http, this.router, weekList.toString()).then((intageMonthData) => {
            if(this.intageMonthData == null){
              this.intageMonthData = intageMonthData;
            }
            IndexDbHelper.Set('IntageMonthData', intageMonthData);
          });
        });
        this.getAllDataFlash();
      }
      else
      {
          if (this.selectedWeek == null || this.selectedWeek == '') {
            this.selectedWeek = this.weeks[0] as string;
          }
          var intageData = await IndexDbHelper.Get('IntageFlashData_' + this.selectedWeek);
          if (intageData != null)
          {
            this.intageData = intageData;
          }
          this.intageMonthData = await IndexDbHelper.Get('IntageMonthData');
    
          IntageHelper.GetIntageWeekData(this.http, this.router).then((weeks) => {
            IndexDbHelper.Set('IntageWeeks', [...new Set(weeks.map(x => x.week))]);
          })
          IntageHelper.GetIntageDataFlash(this.http, this.router, this.selectedWeek).then((intage) => {
            if (this.intageData == null)
            {
              this.intageData = intage;
            }            
            IndexDbHelper.Set('IntageFlashData_' + this.selectedWeek, intage);
            var weekList = this.weeks.map(x => "'" + x + "'");
            IntageHelper.GetIntageMonthFromWeek(this.http, this.router, weekList.toString()).then((intageMonthData) => {
              if(this.intageMonthData == null){
                this.intageMonthData = intageMonthData;
              }
              IndexDbHelper.Set('IntageMonthData', intageMonthData);
            });
          });
          this.getAllDataFlash();
        } 
    })
            
    IntageHelperVM.GetIntageVMData(this.http).then((intageVM) => {
      if (this.intageDataVM == null)
      {
        this.intageDataVM = intageVM;
      }
      IndexDbHelper.Set('IntageVmData', intageVM);      
    });
    let params = new URLSearchParams(window.location.search);
    if(params.get('exportFile') == "true"){
      _downloadAsPDF(name, this.pdf_print_area_intage_flash_full.nativeElement, true);
      }

      const selectedWeek = getSelectedWeekFromUrl(this.router);
      if (selectedWeek) {
        this.selectedWeek = selectedWeek;
        this.onSelectedWeekChanged(this.selectedWeek);
      }

    }
  }

  public getAllDataFlash()
  {
    IntageHelper.GetIntageAllDataFlash(this.http, this.router).then((intage) => {
      IndexDbHelper.Set('IntageFlashAllData', intage);
    });
  }

  public onSelectedWeekChanged(selectedWeek: string) {
    IntageHelper.GetIntageDataFlash(this.http, this.router, selectedWeek).then((intage) => {
      this.intageData = intage;               
      this.selectedWeek = selectedWeek; 
      IndexDbHelper.Set('IntageFlashData_' + this.selectedWeek, intage);
    })
    this.isScoreCardsReady = false;
    this.isOtcMarketShareByPackageReady = false;
    this.isOtcMarketShareByRegionReady = false;
    this.isOtcMarketSizeReady = false;
    this.isValueShareSummaryReady = false;
  }
  public scoreCardsReady(ready: boolean) {
    this.isScoreCardsReady = ready;
  }
  public otcMarketSizeReady(ready: boolean) {
    this.isOtcMarketSizeReady = ready;
  }
  public otcMarketShareByPackageReady(ready: boolean) {
    this.isOtcMarketShareByPackageReady = ready;
  }
  public otcMarketShareByRegionReady(ready: boolean) {
    this.isOtcMarketShareByRegionReady = ready;
  }
  public valueShareSummaryReady(ready: boolean) {
    this.isValueShareSummaryReady = ready;
  }

  dropdownValueToNgSelectFormat(value) {
    return DropdownValueToNgSelectFormat2(value)
  }

  @ViewChild('pdf_print_area_intage_flash_full', { static: false }) pdf_print_area_intage_flash_full: ElementRef;
  public downloadAsPDF(name) {
    return _downloadAsPDF(name, this.pdf_print_area_intage_flash_full.nativeElement)
  }

}
