import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiUrl, AnalyticsQueries } from 'src/app/model/constants';

@Injectable({
  providedIn: 'root'
})

export class RequestService {
  private apiUrl = apiUrl;
  sePaths   = ['/hq-progress', '/district-route-progress', '/sku-deepdive', '/area-deepdive', '/progress-ranking'];
  traxPaths = ['/sovi', '/poc'];

  constructor(private http: HttpClient) {}

  private isCurrentPathIncluded(paths: string[]): boolean {
    return paths.some(path => location.href.includes(path));
  }

  sendRequest(code: string, analyticsQuery: {}, isSendLatestDate: boolean = false): Promise<any> {
    let catcheKey = `?code=${code}`;

    if (analyticsQuery['CacheKey']?.length > 0) {
      catcheKey += `&${analyticsQuery['CacheKey']}`;
    }

    // get LatestDate
    if (isSendLatestDate) {
      let dateInJobClearCache = '';

      if (this.isCurrentPathIncluded(this.sePaths)) {
        dateInJobClearCache = localStorage.getItem('se.dateinjobclearcache')?.split(' ')[0] || '';
      }

      if (this.isCurrentPathIncluded(this.traxPaths)) {
        const lastUpdatedStr = localStorage.getItem('lastUpdated');
        let traxLastUpdated: any;

        try {
          traxLastUpdated = lastUpdatedStr ? JSON.parse(lastUpdatedStr) : null;
        } catch (e) {
          console.error('Failed to parse lastUpdated from localStorage:', e);
          traxLastUpdated = null;
        }

        if (traxLastUpdated && traxLastUpdated.name === AnalyticsQueries?.lastUpdatedTraxReport && typeof traxLastUpdated.date === 'string') {
          dateInJobClearCache = traxLastUpdated.date.split(' ')[0] || '';
        } else {
          dateInJobClearCache = '';
        }
      }

      catcheKey += `&LatestUpdate=${dateInJobClearCache}`;
    }

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    const httpBodyObj = {
      ...analyticsQuery,
      CacheKey: catcheKey,
      Code: code,
      // NoCache: true
    };

    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl, httpBodyObj, httpOptions)
        .subscribe(
          response => {
            resolve(response);
          },
          error => {
            console.log('error', error);
            reject(error);
          }
        )
    });
  }
}
