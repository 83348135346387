<ng-container *ngIf="!isSankeyReady && !isShareVsLyReady && !isScoreCardsReady && !isOtcValueVsLyReady && !isValueShareTimelineReady">
    <div class="text-center spinner-wrapper">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-container>

<div [class]="isSankeyReady && isShareVsLyReady && isScoreCardsReady && isOtcValueVsLyReady && isValueShareTimelineReady ? '' : 'not-display' ">
    <div class="flex-two-ends">
        <!-- <div class="ng-section summary-section week native">
            <b>Week</b>
            <select class="form-control" (change)="onSelectedWeekChanged($event.target.value)">
                <option *ngFor="let item of weeks; index as i;" value="{{item}}">{{item}}</option>
            </select>
        </div> -->
        <single-dropdown title="Week" class="single-dropdown-week"
            [items]="dropdownValueToNgSelectFormat(weeks)"
            [model] = "selectedWeek"
            (change)="onSelectedWeekChanged($event)">
        </single-dropdown>
        
        <!-- <comment-button class="d-flex align-items-center" style="flex-grow:1" title1="Intage OTC Dashboard Comments" chartId="IntageOtcDashboard"
        [chatIdDependency]="[{selectedWeek}]" type="SummaryRemarks"></comment-button> -->
        
    </div>
    <p></p>
    <!-- <comment-button title1="Intage OTC Dashboard Comments" chartId="IntageOtcDashboard"
    [chatIdDependency]="[{selectedWeek}]" type="OwnerComments"></comment-button> -->
    <p></p>
    <intage_score_cards [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData" (ready)="scoreCardsReady($event)"></intage_score_cards>
    <ng-container>
        <intage-otc-value-vs-ly [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData" (ready)="otcValueVsLyReady($event)"></intage-otc-value-vs-ly>
    </ng-container>
    <ng-container>
        <intage_value-share-timeline [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData" (ready)="valueShareTimelineReady($event)"></intage_value-share-timeline>
    </ng-container>
    <ng-container>
        <intage-share-vs-ly [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData" (ready)="shareVsLyReady($event)"></intage-share-vs-ly>
    </ng-container>
    <ng-container>
        <intage-sankey-summary [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData" (ready)="sankeyReady($event)"></intage-sankey-summary>
    </ng-container>
</div>