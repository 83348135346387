<div class="row district-progress-by-district">
  <div class="col-lg-12 col-md-12">
    <div class="card mb-30">

    <!-- Filter area -->
    <div class="add-more-filter-section">
      <app-add-more-filter
        [filterModuleName]      = "'ProgressByDistrictComponent'"
        [className]             = "'district-progress-filters'"
        [filters]               = "FILTERS"
        [listData_Filter]       = "listData_Filter"
        [lstOption_Selected]    = "lstOption_Selected"
        [lstOption_States]      = "lstOption_States"
        [lstOption_IsUsed]      = "lstOption_IsUsed"
        [defaultVisibleFilters] = "defaultVisibleFilters"
        [lstAllOptionsData]     = "lstAllOptionsData"
        [sectionName]           = "sectionName"
        (onUpdateChart)         = "updateProgressByDistrictData($event)"
        (onResetFiltersGroup)   = "onResetFiltersGroup($event)"></app-add-more-filter>
    </div>

    <div class="filter-section filter-section__hq-progress even-wrapper">
      <single-dropdown
          title="Channel Lev"
          [items]="lstOption_ChannelLev"
          [model]="lstOption_Selected_ChannelLev"
          (change)="onSaveChannelLevChange($event)">
      </single-dropdown>
    </div>

    <div *ngIf="isLoading" class="text-center spinner-wrapper spinner-wrapper-icon">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div #pdf_print_district>
        <div class="filter-container-pdf">
            <div class="filter-list row">
                <b class="filter-list-header col-1half">Sales Year Month: </b>
                <div class="filter-list-content col-10half">
                  <span>{{this.saleYearMonth}}</span>
                </div>
            </div>
            <div class="filter-list row" *ngIf="lstOption_States_Applied.SALES_HQ_LEV1_NAME == 'enable'">
              <b class="filter-list-header col-1half">Sales HQ Lev1: </b>
              <div class="filter-list-content col-10half">
                  <span>{{lstOption_Selected_Applied.SALES_HQ_LEV1_NAME}}</span>
              </div>
            </div>
            <div class="filter-list row" *ngIf="lstOption_States_Applied.SALES_HQ_LEV2_NAME == 'enable'">
              <b class="filter-list-header col-1half">Sales HQ Lev2: </b>
              <div class="filter-list-content col-10half">
                  <span>{{lstOption_Selected_Applied.SALES_HQ_LEV2_NAME}}</span>
              </div>
            </div>
            <div class="filter-list row" *ngIf="lstOption_States_Applied.SALES_HQ_LEV3_NAME == 'enable'">
              <b class="filter-list-header col-1half">Sales HQ Lev3: </b>
              <div class="filter-list-content col-10half">
                  <span [class]="lstOption_Selected_Applied.SALES_HQ_LEV3_NAME!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.SALES_HQ_LEV3_NAME}}</span>
              </div>
            </div>
            <div class="filter-list row" *ngIf="lstOption_States_Applied.SALES_CENTER_NAME == 'enable'">
              <b class="filter-list-header col-1half">Sales Center: </b>
              <div class="filter-list-content col-10half">
                  <span [class]="lstOption_Selected_Applied.SALES_CENTER_NAME!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.SALES_CENTER_NAME}}</span>
              </div>
            </div>
            <div class="filter-list row" *ngIf="lstOption_States_Applied.SALES_CONTROL_BASE_NAME == 'enable'">
              <b class="filter-list-header col-1half">Sale Control Base: </b>
              <div class="filter-list-content col-10half">
                  <span [class]="lstOption_Selected_Applied.SALES_CONTROL_BASE_NAME!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.SALES_CONTROL_BASE_NAME}}</span>
              </div>
            </div>
            <div class="filter-list row" *ngIf="lstOption_States_Applied.SALES_CONTROL_BASE_ROUTE_CODE == 'enable'">
              <b class="filter-list-header col-1half">Sales Route: </b>
              <div class="filter-list-content col-10half">
                  <span>{{lstOption_Selected_Applied.SALES_CONTROL_BASE_ROUTE_CODE}}</span>
              </div>
            </div>
            <div class="filter-list row" *ngIf="lstOption_States_Applied.CHANNEL_LEV1_NAME == 'enable'">
              <b class="filter-list-header col-1half">Channel Lev1:</b>
              <div class="filter-list-content col-10half">
                  <span>{{lstOption_Selected_Applied.CHANNEL_LEV1_NAME}}</span>
              </div>
            </div>
            <div class="filter-list row" *ngIf="lstOption_States_Applied.CHANNEL_LEV2_NAME == 'enable'">
              <b class="filter-list-header col-1half">Channel Lev2:</b>
              <div class="filter-list-content col-10half">
                  <span>{{lstOption_Selected_Applied.CHANNEL_LEV2_NAME}}</span>
              </div>
            </div>
            <div class="filter-list row" *ngIf="lstOption_States_Applied.CHANNEL_LEV3_NAME == 'enable'">
              <b class="filter-list-header col-1half">Channel Lev3:</b>
              <div class="filter-list-content col-10half">
                  <span>{{lstOption_Selected_Applied.CHANNEL_LEV3_NAME}}</span>
              </div>
            </div>
            <div class="filter-list row">
              <b class="filter-list-header col-1half">Current Page:</b>
              <div class="filter-list-content col-10half">
                <span>{{paging.current}}/{{paging.totalPage}} sorted by &#40;{{sortColumnName}} {{sortType=="asc" ? "ascending" : "descending"}}&#41;</span>
              </div>
          </div>
        </div>

      <!-- Header area -->
      <div class="card-header d-flex justify-content-between align-items-center hq-progress-title">
        <h3 #observedElement>{{ sectionName }}</h3>
      </div>

      <!-- Progress By District Data area -->
      <div class="card-body">
        <div class="market-size-district-route-by-district">
          <table class="intage-market-size district-progress">
            <thead>
              <tr class="row__first">
                <ng-container *ngFor="let item of listColumn; index as j;">
                  <th [class]="item.category.toLowerCase()"  *ngIf="item.isDisplay!==false && item.enableSort==false" rowspan="2">
                    <div>{{item.displayName}}</div>
                  </th>
                </ng-container>
                <th colspan="4"><div class="text-center"><span>BAPC (cases)</span></div></th>
                <th colspan="4"><div class="text-center"><span>NSR (K JPY)</span></div></th>
                <th colspan="3"><div class="text-center"><span>NSR per Case (JPY)</span></div></th>
                <th colspan="4"><div class="text-center"><span>GP (K JPY)</span></div></th>
                <th colspan="3"><div class="text-center"><span>GP per Case (JPY)</span></div></th>
            </tr>
              <tr class="row__second">
                <ng-container *ngFor="let item of listColumn; index as j;">
                  <ng-container>
                    <th [class]="item.category.toLowerCase()" *ngIf="item.isDisplay!==false && item.enableSort!=false">
                      <div class="th-header-content">
                        <div class="text" [innerHTML]="item.displayName"></div>
                        <!-- <div class="sort-column">
                          <div class="icon-container sort-column">
                            <span id="NSR_DIFF_VS_BPASC" class='icon-up-arrow' [ngClass]="sortType == 'asc' && sortColumn ==item.category ? 'red' : ''"></span>
                            <span id="NSR_DIFF_VS_BPDESC" class='icon-down-arrow' [ngClass]="sortType == 'desc' && sortColumn ==item.category ? 'red' : ''"></span>
                          </div>
                        </div>-->
                      </div>
                    </th>
                  </ng-container>
                </ng-container>
              </tr>
            </thead>

            <tbody *ngIf="!isLoading">
              <ng-container *ngFor="let root1 of progressbyDistrictData?.ListRoot1; index as i;">
                <tr>
                  <ng-container *ngFor="let item of listColumn; index as j;">
                    <td class="item-chanel-1" [class]="' ' + item.category.toLowerCase()" *ngIf="item.isDisplay!==false">
                      <span>
                        <b
                          [class]="(!item.isLunisolarColor|| !root1[item.category]) ? '': root1[item.category].toString().indexOf('-') == 0 ? 'text-red' : 'text-green'">
                          {{root1[item.category +'_Display']}}
                        </b>
                      </span>
                    </td>
                  </ng-container>
                </tr>
                <ng-container
                  *ngFor="let root2 of progressbyDistrictData.ListRoot2| filterRoots: ['SALES_HQ_LEV2_NAME'] : [root1['SALES_HQ_LEV2_NAME']]  as lstRoot2; index as k;">
                  <tr>
                    <ng-container *ngFor="let item2 of listColumn; index as l;">
                      <td class="item-chanel-2"
                        [ngClass]="l === 1 ? 'first-item-chanel-2' : (l === 2 ? 'second-item-chanel-2' : '') + ' ' + item2.category.toLowerCase()"
                        *ngIf="item2.isDisplay !== false">
                        <span
                          [class]="(!item2.isLunisolarColor|| !root2[item2.category]) ? '': root2[item2.category].toString().indexOf('-') == 0 ? 'text-red' : 'text-green'"
                          [ngClass]="item2.root2hasJapaneseCharacters == true ? 'japanese-text' : ''">
                          {{root2[item2.category +'_Display']}}
                        </span>
                      </td>
                    </ng-container>
                  </tr>

                  <ng-container *ngFor="let root3 of  progressbyDistrictData.ListRoot3 | filterRoots: ['SALES_HQ_LEV2_NAME','SALES_HQ_LEV3_NAME'] : [root1['SALES_HQ_LEV2_NAME'], root2['SALES_HQ_LEV3_NAME']] as lstRoot3; index as m;">
                    <tr>
                      <ng-container *ngFor="let item3 of listColumn; index as j;">

                        <td class="item-chanel-3"
                            [class]="' ' + item3.category.toLowerCase()"
                            [ngClass]="{'item-chanel-3-last-row': k === lstRoot3.length - 1}"
                          *ngIf="item3.isDisplay!==false">
                          <span
                            [class]="(!item3.isLunisolarColor|| !root3[item3.category]) ? '': root3[item3.category].toString().indexOf('-') == 0 ? 'text-red' : 'text-green'">
                            {{ root3[item3.category +'_Display']}}
                          </span>
                        </td>
                      </ng-container>
                    </tr>
                  </ng-container>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

    </div>

      <!-- Paging -->
      <div *ngIf="paging?.totalPage > 1" class="paging-section">
        <ngb-pagination class="ngb-pagination"
          [collectionSize]="paging?.totalPage *10"
          [(page)]="paging.current"
          [rotate]="true"
          [maxSize]="paging.maxSize"
          [directionLinks]="true"
          [boundaryLinks]="true"
          (pageChange)="onPageChange($event); onTrackingPaging($event)">
        </ngb-pagination>
      </div>

      <!-- Download CSV area -->
      <div class="d-flex flex-row-reverse mt-4">
        <download-button [ngClass]=" isLoading ? 'disable-button' :''">
          <a #downloadLink style="display: none;"></a>
          <button *ngIf="!isLoading" ngbDropdownItem (click)="export_CSV_by_Url()">Current Page CSV</button>
          <!--<button *ngIf="!isLoading" ngbDropdownItem (click)="exportHQDistrict_PDF('Progress by District')">Current Page PDF</button>-->
          <button *ngIf="!isLoading" ngbDropdownItem (click)="exportAll_CSV_by_Url()">All pages CSV</button>
        </download-button>
      </div>
    </div>
  </div>
</div>
