import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { FILTER_CONFIGS, SkuDeepdiveQueries } from 'src/app/model/constants';
import { Chart } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { SkuDeepdiveHelper } from 'src/app/components/pages/sku-deepdive/sku-deepdive-helper';
import { FilterHelper } from 'src/app/components/elements/add-more-filter/filter-helper';
import { HttpClient } from '@angular/common/http';
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import * as htmlToImage from 'html-to-image';
import { downloadAsPDF } from 'src/app/helpers/pdfExtend';
import { PDFProperty } from 'src/app/model/pdfProperty.model';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import lodashClonedeep from 'lodash.clonedeep';
import { Router } from '@angular/router';
import { CsvService } from 'src/app/services/csv.services';
import { UserService } from 'src/app/services/user.services';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
  selector: 'app-sku-deepdive-vs-ly-sku',
  templateUrl: './vs-ly-sku.component.html',
  styleUrls: ['./vs-ly-sku.component.scss']
})
export class VsLySKUComponent implements OnInit {
  FILTERS: any                  = [];
  SKU_DEEPDIVE_FILTERS          = FILTER_CONFIGS?.ADD_MORE_FILTER?.COLLECTIONS?.SKU_DEEPDIVE;
  defaultVisibleFilters: number = FILTER_CONFIGS?.ADD_MORE_FILTER?.VISIBLE_COUNT ?? 4;

  @Input() lstOption_Selected_DateType: any;
  @Input() lstOption_Selected_SalesDate: any;

  @Input() lstAllOptionsCalendarData: any;
  @Input() lstAllOptionsAreaData: any;
  @Input() lstAllOptionsAccountData: any;
  @Input() lstAllOptionsProductData: any;
  @Output() ready = new EventEmitter<boolean>();
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('observedElement', { static: true }) observedElement: ElementRef;

  sectionName: string = 'vs LY by SKU';
  vsLYBySKUData: any = null;
  listGroupSKUDetail: any[] = null;

  vsLYBySKUData_CSV: any = null;

  isLoading: boolean = false;
  isNoDataMode = true;
  listData_Filter: any = null;
  lstOption_States: any = {};
  lstOption_States_Applied: any = {};
  lstOption_Selected: any = {};
  lstOption_IsUsed: any = {};
  lstOption_Selected_Applied: any = {};
  skuFilterQuery: object = {};

  public listChart: any[];

  public maxBAPC_TY: number;
  public maxNSR_TY: number;
  public maxGP_TY: number;

  public BAPC_TYHalfColumn: any;
  public BAPC_TYFullColumn: any;
  public NSR_TYHalfColumn: any;
  public NSR_TYFullColumn: any;
  public GP_TYHalfColumn: any;
  public GP_TYFullColumn: any;

  sortType: string = "desc";
  sortColumn: string = "NSR_TY";
  chartBAPC_SKU: any;
  chartNRS_SKU: any;
  chartGP_SKU: any;

  private searchSubject = new Subject<string>();
  searchObj: any = {
    loading: false,
    keyName: '',
    data: [],
  }

  isHasFistLoad: boolean = false;

  constructor(private http: HttpClient, private router: Router, private csvService: CsvService, private mixPanelTrackingService: MixPanelTrackingService, private userService: UserService) {}

  ngOnInit() {
    this.FILTERS    = FilterHelper.sortObjectByVisibleItems(FILTER_CONFIGS?.ADD_MORE_FILTER?.DEFAULT, this.SKU_DEEPDIVE_FILTERS.slice(0, this.defaultVisibleFilters)) || [];
    const lstOption = FilterHelper.init_lstOption_States(`VsLySKUComponent_${this.userService.getUserFilterName()}`,this.FILTERS, this.defaultVisibleFilters, this.userService.getUserInfo()) || {};
    this.lstOption_States   = lstOption.lstOption_States;
    this.lstOption_Selected = lstOption.lstOption_Selected;
    this.listData_Filter  =lstOption.listData_Filter;
    this.skuFilterQuery     = SkuDeepdiveHelper.skuSetQueryString(lstOption.lstOption_Selected);
    this.lstOption_IsUsed   = FilterHelper.getListIsUsedFilter(this.FILTERS, this.SKU_DEEPDIVE_FILTERS) || {};
    this.onUpdateCurrent_Filter_Applied();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (((changes['lstOption_Selected_DateType'] && this.lstOption_Selected_DateType && this.lstOption_Selected_DateType.length > 0)
        || (changes['lstOption_Selected_SalesDate'] && this.lstOption_Selected_SalesDate && this.lstOption_Selected_SalesDate.length > 0))) {
      if(this.isHasFistLoad){
        this.onSearchData();
      } 
      this.handleonReady();
    }
    this.isHasFistLoad = true;
  }

  ngOnDestroy(){
    if (this.chartNRS_SKU) {
      this.chartNRS_SKU.destroy();
    }
    if (this.chartGP_SKU) {
      this.chartGP_SKU.destroy();
    }

    if (this.chartBAPC_SKU) {
      this.chartBAPC_SKU.destroy();
    }
  }

  ngAfterViewInit(): void {
    this.mixPanelTrackingService.observeElement(this.observedElement);
  }

  onResetFiltersGroup($event: any) {
    const {data} = $event;
    if (data && Array.isArray(data) && data.length > 0) {
      data.map(key => {
        if (key && key.length > 0) {
          this.lstOption_Selected[key] = ['All'];
        }
      });
    }
  }

  onUpdateChart(event: any) {
    const {lstOption_States, lstOption_Selected} = event;

    if (lstOption_States && lstOption_Selected) {
      this.lstOption_Selected = {};
      this.lstOption_Selected = lstOption_Selected;
      this.lstOption_States   = {};
      this.lstOption_States   = lstOption_States;
      this.onUpdateCurrent_Filter_Applied()
      this.updateVsLyByPackageData();
    }
  }

  onUpdateCurrent_Filter_Applied() {
    this.lstOption_States_Applied = lodashClonedeep(this.lstOption_States);
    this.lstOption_Selected_Applied = lodashClonedeep(this.lstOption_Selected);
  }


  public updateVsLyByPackageData(): void {
    this.onSearchData();
  }

  onSearchData() {
    if (!this.lstOption_Selected_DateType || !this.lstOption_Selected_DateType?.length || !this.lstOption_Selected_SalesDate || !this.lstOption_Selected_SalesDate.length) {
      return;
    }

    this.isLoading = true;
    this.skuFilterQuery = SkuDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied);

    SkuDeepdiveHelper.GetDataBySKU(this.http, this.lstOption_Selected_DateType ?  this.lstOption_Selected_DateType:  'MTD', !SkuDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [], this.skuFilterQuery).then((data) => {
      if (data) {
        const { Data } = data;

        if (Data) {
          this.vsLYBySKUData = Data;
          this.processData();
          this.sortTable('');
          this.isLoading = false;
        }
      }
    })
  }

  processData() {
    this.isNoDataMode = this.vsLYBySKUData.length == 0;
    this.maxBAPC_TY = Math.max(...this.vsLYBySKUData.map(item => item.BAPC_TY).filter(value => !isNaN(value)));
    this.maxBAPC_TY = SkuDeepdiveHelper.roundMaxValueInChart(this.maxBAPC_TY);
    this.maxNSR_TY = Math.max(...this.vsLYBySKUData.map(item => item.NSR_TY).filter(value => !isNaN(value)));
    this.maxNSR_TY = SkuDeepdiveHelper.roundMaxValueInChart(this.maxNSR_TY);
    this.maxGP_TY = Math.max(...this.vsLYBySKUData.map(item => item.GP_TY).filter(value => !isNaN(value)));
    this.maxGP_TY = SkuDeepdiveHelper.roundMaxValueInChart(this.maxGP_TY);
    this.BAPC_TYHalfColumn = AnalyticsHelper.formatNumber(this.maxBAPC_TY/2000);
    this.BAPC_TYFullColumn = AnalyticsHelper.formatNumber(this.maxBAPC_TY/1000);
    this.NSR_TYHalfColumn = AnalyticsHelper.formatNumber(this.maxNSR_TY/2000000);
    this.NSR_TYFullColumn = AnalyticsHelper.formatNumber(this.maxNSR_TY/1000000);
    this.GP_TYHalfColumn = AnalyticsHelper.formatNumber(this.maxGP_TY/2000000);
    this.GP_TYFullColumn = AnalyticsHelper.formatNumber(this.maxGP_TY/1000000);


    this.listGroupSKUDetail = this.vsLYBySKUData.map(items => {
      return {
        ...items,
        WithBAPC_TY: Math.abs(items.BAPC_TY / this.maxBAPC_TY * 100).toFixed(2),
        WithNSR_TY: Math.abs(items.NSR_TY / this.maxNSR_TY * 100).toFixed(2),
        WithGP_TY: Math.abs(items.GP_TY > 0 ? items.GP_TY / this.maxGP_TY * 100 : 0).toFixed(2),
        BAPC_TY_Format:  AnalyticsHelper.formatNumber((items.BAPC_TY/1000).toFixed(0)),
        NSR_TY_Format: AnalyticsHelper.formatNumber((items.NSR_TY/1000000).toFixed(0)),
        GP_TY_Format: AnalyticsHelper.formatNumber((items.GP_TY/1000000).toFixed(0))
      };
    });
  }

  handleonReady() {
    let t = this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }

  exportSku_CVS() {
    this.skuFilterQuery = SkuDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied);
    SkuDeepdiveHelper.GetDataBySKU(this.http, this.lstOption_Selected_DateType ?  this.lstOption_Selected_DateType:  'MTD', !SkuDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [], this.skuFilterQuery,true).then((data) => {
      if (data) {
        const { Data } = data;
        if (Data) {
          this.vsLYBySKUData_CSV = Data;
          this.sortTable_CSV('');
          const fileName = "vs LY by SKU"
          let data = [];
          var filters: any;
          filters = {
            DateType: this.lstOption_Selected_DateType,
            SaleDateMonth: this.lstOption_Selected_SalesDate,
            channelLv1: this.lstOption_Selected_Applied.CHANNEL_LEV1_NAME,
            channelLv2: this.lstOption_Selected_Applied.CHANNEL_LEV2_NAME,
            channelLv3: this.lstOption_Selected_Applied.CHANNEL_LEV3_NAME,
            saleHQLev1: this.lstOption_Selected_Applied.SALES_HQ_LEV1_NAME,
            saleHQLev2: this.lstOption_Selected_Applied.SALES_HQ_LEV2_NAME,
            saleHQLev3: this.lstOption_Selected_Applied.SALES_HQ_LEV3_NAME,
            SalesCenter: this.lstOption_Selected_Applied.SALES_CENTER_NAME,
            SalesRoute: this.lstOption_Selected_Applied.SALES_ROUTE_NAME,
            ComDistBase: this.lstOption_Selected_Applied.COM_DIST_SALES_REP_NAME,
            DealerName: this.lstOption_Selected_Applied.DEALER_NAME,
            KamDeptLv2: this.lstOption_Selected_Applied.KAM_DEPT_LV2_NAME,
            KamDeptLv3: this.lstOption_Selected_Applied.KAM_DEPT_LV3_NAME,
            KamDeptLv4: this.lstOption_Selected_Applied.KAM_DEPT_LV4_NAME,
            KamContactPerson: this.lstOption_Selected_Applied.KAM_CONTACT_PERSON_NAME,
            AccountLev2: this.lstOption_Selected_Applied.ACCOUNT_LEV2_NAME_EN,
            AccountLev3: this.lstOption_Selected_Applied.ACCOUNT_LEV3_NAME_EN,
            Category: this.lstOption_Selected_Applied.CATEGORY_NAME_EN,
            Subcategory: this.lstOption_Selected_Applied.SUBCATEGORY_NAME_EN,
            Brand: this.lstOption_Selected_Applied.BRAND_NAME_EN,
            PackageLev1: this.lstOption_Selected_Applied.PACKAGE_LEV1_NAME_EN,
            PackageLev2: this.lstOption_Selected_Applied.PACKAGE_LEV2_NAME_EN,
            PackageDtl: this.lstOption_Selected_Applied.PACKAGE_DTL_NAME,
          };
      
      
          this.vsLYBySKUData_CSV.forEach(items => {
            if(this.lstOption_Selected_DateType == "MTD") {
              data.push({
                DateType: filters === null ? '' : String(filters.DateType),
                SaleDateMonth: filters === null ? '' : String(filters.SaleDateMonth),
                ... this.toRowCVS(items, filters)
              })
            } else {
              data.push({
                DateType: filters === null ? '' : String(filters.DateType),
                SaleDateDay:filters === null ? '' : this.lstOption_Selected_SalesDate[0] + ' to ' + this.lstOption_Selected_SalesDate[1],
                ... this.toRowCVS(items, filters)
              })
            }
          });
      
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: [
              'Date Type',	
              'Sales Date',	
              'Channel Lev1',	
              'Channel Lev2',	
              'Channel Lev3',	
              'Sales HQ Lev1',	
              'Sales HQ Lev2',	
              'Sales HQ Lev3',	
              'Sales Center',	
              'Sales Route',	
              'Sales BD',	
              'Dealer',	
              'Kam Dept Lev2',	
              'Kam Dept Lev3',	
              'Kam Dept Lev4',	
              'Kam Account Mgr 4',	
              'Account Lev2',	
              'Account Lev3',	
              'Category',	
              'Subcategory',	
              'Brand',	
              'Package Lev1',	
              'Package Lev2',	
              'Package Detail',	
              'PRODUCT NAME',	
              'BAPC TY',	
              'BAPC LY',	
              'BAPC % vs LY',	
              'BAPC Diff LY',	
              'NSR TY',	
              'NSR LY',	
              'NSR % vs LY',	
              'NSR Diff LY',	
              'GP TY',	
              'GP LY',	
              'GP % vs LY',
              'GP Diff LY'
            ]
          });
        }
      }
    })
  }

  exportSku_CVS_by_Url() {
    const obj = SkuDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied);

    const salesDateQuery = ['MTD', 'QTD', 'YTD', 'Specific Month'].includes(this.lstOption_Selected_DateType ?  this.lstOption_Selected_DateType: 'MTD') ? 'SALES_YM' : 'SALES_DATE';
    this.skuFilterQuery = {
      ...obj,
      CacheKey: `${salesDateQuery}=${!SkuDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : []}&${obj['CacheKey']}`,
      Selected: {
        ...obj['Selected'],
        [salesDateQuery]: !SkuDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [],
        DATE_TYPE       : this.lstOption_Selected_DateType ?  this.lstOption_Selected_DateType:  'MTD'
      }
    };

    this.csvService.getCsvUrl(SkuDeepdiveQueries.bySKU, this.skuFilterQuery, '')
      .subscribe((data) => {
        if (data) {
          this._downloadCsv(data?.Csv_Url);
        }
    });
  }

  toRowCVS(row: any, filters: any) {
    return {
      channelLv1: filters === null ? '' : String(filters.channelLv1),
      channelLv2: filters === null ? '' : String(filters.channelLv2),
      channelLv3: filters === null ? '' : String(filters.channelLv3),
      saleHQLev1: filters === null ? '' : String(filters.saleHQLev1),
      saleHQLev2: filters === null ? '' : String(filters.saleHQLev2),
      saleHQLev3: filters === null ? '' : String(filters.saleHQLev3),
      SalesCenter: filters === null ? '' : String(filters.SalesCenter),
      SalesRoute: filters === null ? '' : String(filters.SalesRoute),
      ComDistBase: filters === null ? '' : String(filters.ComDistBase),
      DealerName: filters === null ? '' : String(filters.DealerName),
      KamDeptLv2: filters === null ? '' : String(filters.KamDeptLv2),
      KamDeptLv3: filters === null ? '' : String(filters.KamDeptLv3),
      KamDeptLv4: filters === null ? '' : String(filters.KamDeptLv4),
      KamContactPerson: filters === null ? '' : String(filters.KamContactPerson),
      AccountLev2: filters === null ? '' : String(filters.AccountLev2),
      AccountLev3: filters === null ? '' : String(filters.AccountLev3),
      Category: filters === null ? '' : String(filters.Category),
      Subcategory: filters === null ? '' : String(filters.Subcategory),
      Brand: filters === null ? '' : String(filters.Brand),
      PackageLev1: filters === null ? '' : String(filters.PackageLev1),
      PackageLev2: filters === null ? '' : String(filters.PackageLev2),
      PackageDtl: filters === null ? '' : String(filters.PackageDtl),

      ProductName: row['PRODUCT_NAME_EN'] === null ? 'NULL' : row['PRODUCT_NAME_EN'],
      BAPC_TY: row['BAPC_TY_Display'] === null ? '' : row['BAPC_TY_Display'],
      BAPC_LY: row['BAPC_LY_Display'] === null ? '' : row['BAPC_LY_Display'],
      BAPC_VS_LY: row['BAPC_VS_LY_Display'] === null ? '' : row['BAPC_VS_LY_Display'],
      BAPC_Diff_LY: row['BAPC_DIFF_LY_Display'] === null ? '' : row['BAPC_DIFF_LY_Display'],
      NSR_TY: row['NSR_TY_Display'] === null ? '' : row['NSR_TY_Display'],
      NSR_LY: row['NSR_LY_Display'] === null ? '' : row['NSR_LY_Display'],
      NSR_VS_LY: row['NSR_VS_LY_Display'] === null ? '' : row['NSR_VS_LY_Display'],
      NSR_Diff_LY: row['NSR_DIFF_LY_Display'] === null ? '' : row['NSR_DIFF_LY_Display'],
      GP_TY: row['GP_TY_Display'] === null ? '' : row['GP_TY_Display'],
      GP_LY: row['GP_LY_Display'] === null ? '' : row['GP_LY_Display'],
      GP_VS_LY: row['GP_VS_LY_Display'] === null ? '' : row['GP_VS_LY_Display'],
      GP_Diff_LY: row['GP_DIFF_LY_Display'] === null ? '' : row['GP_DIFF_LY_Display']
    }
  }

  @ViewChild('pdf_print_area_2', { static: false }) pdf_print_area_2: ElementRef;
  exportPDF(name) {
    const $ = window["jQuery"];
    $("body").addClass("pdf-printing-se pdf-printing");
    $(".table-container").css('height', 'auto');
    $(".table-container").css('overflow-y', 'scroll');
    $(".filter-section").css('display', 'none');

    htmlToImage.toCanvas(this.pdf_print_area_2.nativeElement, { quality: 1 })
      .then(function (canvas) {
        var pdfProperty: PDFProperty = {
          option: { margin: [50, 0, 46, 0] },
          canvas: canvas
        }
        downloadAsPDF(name, null, false, pdfProperty);
      });
  }


  createChart() {
    setTimeout(() => {
      if (this.chartNRS_SKU) {
        this.chartNRS_SKU.destroy();
      }
      if (this.chartGP_SKU) {
        this.chartGP_SKU.destroy();
      }

      if (this.chartBAPC_SKU) {
        this.chartBAPC_SKU.destroy();
      }

      var dataBAPC = {
        labels: this.listGroupSKUDetail.map(e => e.PRODUCT_NAME_EN || 'NULL'),
        datasets: [{
          data: this.listGroupSKUDetail.map(e => (e.BAPC_VS_LY * 100).toFixed(1)), min: -100, max: 100,
          fill: false,
          borderColor: 'rgb(83, 100, 128)',
          tension: 0
        }]
      };
      this.chartBAPC_SKU = new Chart("chartBAPC_SKU", {
        type: 'line',
        data: dataBAPC,
        plugins: [ChartDataLabels],
        options: {
          maintainAspectRatio: false,
          aspectRatio: 1,
          indexAxis: 'y',
          responsive: true,
          scales: {
            x: {
              display: false,
              min: -200,
              max: 200
            },
            y: {
              display: false,
            },
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 15,
              bottom: 15
            }
          },
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              color: 'black',
              align: 'right',
              formatter: function (value) {
                if (value > 0) {
                  return "+" + value;
                } else {
                  return value;
                }
              }
            }
          }
        }
      });


      var dataNRS = {
        labels: this.listGroupSKUDetail.map(e => e.PRODUCT_NAME_EN || 'NULL'),
        datasets: [{
          data: this.listGroupSKUDetail.map(e => (e.NSR_VS_LY * 100).toFixed(1)), min: -100, max: 100,
          fill: false,
          borderColor: 'rgb(83, 100, 128)',
          tension: 0
        }]
      };

      this.chartNRS_SKU = new Chart("chartNRS_SKU", {
        type: 'line',
        data: dataNRS,
        plugins: [ChartDataLabels],
        options: {
          maintainAspectRatio: false,
          aspectRatio: 1,
          indexAxis: 'y',
          responsive: true,
          scales: {
            x: {
              display: false,
              min: -200,
              max: 200
            },
            y: {
              display: false,
            },
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 15,
              bottom: 15
            }
          },
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              color: 'black',
              align: 'right',
              formatter: function (value) {
                if (value > 0) {
                  return "+" + value;
                } else {
                  return value;
                }
              }
            }
          }
        }
      });

      var dataGP = {
        labels: this.listGroupSKUDetail.map(e => e.PRODUCT_NAME_EN || 'NULL'),
        datasets: [{
          data: this.listGroupSKUDetail.map(e => (e.GP_VS_LY * 100).toFixed(1)), min: -100, max: 100,
          fill: false,
          borderColor: 'rgb(83, 100, 128)',
          tension: 0
        }]
      };

      this.chartGP_SKU = new Chart("chartGP_SKU", {
        type: 'line',
        data: dataGP,
        plugins: [ChartDataLabels],
        options: {
          maintainAspectRatio: false,
          aspectRatio: 1,
          indexAxis: 'y',
          responsive: true,
          scales: {
            x: {
              display: false,
              min: -200,
              max: 200
            },
            y: {
              display: false,
            },
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 15,
              bottom: 15
            }
          },
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              color: 'black',
              align: 'right',
              formatter: function (value) {
                if (value > 0) {
                  return "+" + value;
                } else {
                  return value;
                }
              }
            }
          }
        }
      });
    }, 1000);
  }

  sortTable(column: string) {
    if(column == this.sortColumn && column){
      this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortType = "desc";
    }
    this.sortColumn = column ? column : 'NSR_TY';

    this.listGroupSKUDetail = this.sortType== 'asc' ?  this.listGroupSKUDetail.slice().sort((a, b) => a[this.sortColumn] - b[this.sortColumn]) : this.listGroupSKUDetail.slice().sort((a, b) => b[this.sortColumn] - a[this.sortColumn])

    this.createChart();

    if (column?.length > 0) {
      this.mixPanelTrackingService.onTrackingSortChange(this.sectionName, this.sortColumn, this.sortType);
    }
  };


  sortTable_CSV(column: string) {
    if(column == this.sortColumn && column){
      this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortType = "desc";
    }
    this.sortColumn = column ? column : 'NSR_TY';

    this.vsLYBySKUData_CSV = this.sortType== 'asc' ?  this.vsLYBySKUData_CSV.slice().sort((a, b) => a[this.sortColumn] - b[this.sortColumn]) : this.vsLYBySKUData_CSV.slice().sort((a, b) => b[this.sortColumn] - a[this.sortColumn])
  };

  private _downloadCsv(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }
}

