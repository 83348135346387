<div class="poc-component card">
  <div class="card-header">
    <h3 #observedElement class="component-title">{{ sectionName }}</h3>
  </div>

  <app-photo-report-filter
    [filterModuleName]="'poc-by-category'"
    [filters]="filters"
    [lstOption_IsUsed]="lstOption_IsUsed"
    [lstAllOptionsData]="lstAllOptionsData"
    [lstOption_Selected]="lstOptionSelected"
    [sectionName]="sectionName"
    (onUpdateChart)="onUpdateChart($event)"
  ></app-photo-report-filter>

  <div *ngIf="isLoading" class="text-center spinner-wrapper">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <!-- start Marker table -->
  <div class="row" *ngIf="!isLoading">
    <div class="col-lg-12 col-md-12">
      <div #scroll class="category-size">
        <div class="category-size__content">
          <table #screen>
            <ng-container *ngIf="chartData?.length">
              <thead>
                <tr class="row__first">
                  <th rowspan="2" class="area">Area</th>
                  <th rowspan="2" class="category">Category</th>
                  <th colspan="10" class="poc-title">Ave. # of POC per outlet</th>
                </tr>
                <tr class="row__second">
                  <ng-container *ngFor="let category of categories">
                    <th [ngClass]="{ 'highlight': isHighLightCell(category.value) }">{{ category.label }}</th>
                  </ng-container>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td rowspan="21" class="text-center area">
                    <span *ngIf="chartData?.length">{{ chartData[0]?.SALES_DISTRICT_GROUP }}</span><br/>
                    <span>Sample(Store) #</span><br/>
                    <span>サンプル（店舗）</span><br/>
                    <span>{{storeTMDisplay}}</span>
                  </td>
                </tr>
                <ng-container *ngFor="let item of chartData; index as i">
                  <ng-container *ngIf="item?.SALES_DISTRICT_GROUP !== null && item?.CATEGORY_EN?.toLowerCase() !== 'others'">
                    <ng-container *ngIf="item?.TYPE === 'POC_PER_STORE_TM'">
                      <tr class="row__first">
                        <td [class]="getClassName(item?.CATEGORY_EN) + ' text-left bg'">{{ item?.CATEGORY_EN }}</td>
                        <ng-container *ngFor="let category of categories">
                          <td [ngClass]="{ 'highlight': isHighLightCell(category.value) }">{{ item[category.value + '_Display'] }}</td>
                        </ng-container>
                      </tr>
                    </ng-container>

                    <ng-container *ngIf="item?.TYPE === 'POC_PER_STORE_LY'">
                      <tr class="row__second">
                        <td [class]="getClassName(item?.CATEGORY_EN) + ' text-right bg'">vs LY</td>
                        <ng-container *ngFor="let category of categories">
                          <td [ngClass]="{ 'text-red': isNegative(item[category.value]), 'highlight': isHighLightCell(category.value) }">{{ getLyValueDisplay(item[category.value], item[category.value + '_Display']) }}</td>
                        </ng-container>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </tbody>
            </ng-container>
          </table>
        </div>
      </div>
    </div>
  </div>

  <a #downloadLink style="display: none;"></a>
  <div class="d-inline-flex section-download">
    <button type="button" class="btn btn-download-excel download-button-tracking" (click)="downloadExcel()">
      <span>Download Excel</span>
      <ng-icon name="IonDownloadOutline" size="16px" strokeWidth="46px"></ng-icon>
    </button>
  </div>
</div>
