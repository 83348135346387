<!-- Add filter option -->
<div class="add-filter-container">
  <div *ngIf="isShowAddMoreFilter && isShowAddMoreFilterSection" class="filter-addmore-section d-flex flex-row justify-content-end">
    <button *ngIf="isResetFilter" type="button" class="btn btn-primary btn-reset-filter" (click)="onResetFilter(); onMixpanelTrackingResetAddMoreFilter()">Reset filters</button>
    <button *ngIf="isAddMoreFilter" type="button" class="btn btn-primary btn-close-filter" (click)="onClose(); onMixpanelTrackingAddmorefilterClick(false)">Close</button>
    <button *ngIf="!isAddMoreFilter" type="button" class="btn btn-primary btn-addmore-filter" (click)="onAddMoreFilter(); onMixpanelTrackingAddmorefilterClick(true)">Add More filters</button>
  </div>
</div>

<!-- Filter area -->
<div [class]="'filter-section even-wrapper' + ' ' + className">
  <multi-dropdown *ngIf="lstOption_IsUsed?.CHANNEL_LEV1_NAME"
    class="multi-dropdown__channel-lev1"
    dataTitle="Channel Lev1"
    dataDescription="Channel Lev1"
    dataSortName="CHANNEL_LEV1_NAME"
    [keyName]="filters?.CHANNEL_LEV1_NAME"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.CHANNEL_LEV1_NAME"
    [initialSelected]="lstOption_Selected.CHANNEL_LEV1_NAME"
    [initialState]="lstOption_States[filters?.CHANNEL_LEV1_NAME]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.CHANNEL_LEV1_NAME_NL"
    class="multi-dropdown__channel-lev1-nl"
    dataTitle="Channel Lev1"
    dataDescription="Channel Lev1"
    dataSortName="CHANNEL_LEV1_NAME"
    [keyName]="filters?.CHANNEL_LEV1_NAME_NL"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.CHANNEL_LEV1_NAME_NL"
    [initialSelected]="lstOption_Selected.CHANNEL_LEV1_NAME_NL"
    [initialState]="lstOption_States[filters?.CHANNEL_LEV1_NAME_NL]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.SALES_HQ_LEV1_NAME"
    class="multi-dropdown__sales-hq-lev1"
    dataTitle="Sales HQ Lev1"
    dataDescription="Sales HQ Lev1"
    dataSortName="SALES_HQ_LEV1_NAME"
    [keyName]="filters?.SALES_HQ_LEV1_NAME"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.SALES_HQ_LEV1_NAME"
    [initialSelected]="lstOption_Selected.SALES_HQ_LEV1_NAME"
    [initialState]="lstOption_States[filters?.SALES_HQ_LEV1_NAME]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.KAM_DEPT_LV2_NAME"
    class="multi-dropdown__kam-dept-lev2"
    dataTitle="KAM Dept Lev2"
    dataDescription="KAM Dept Lev2"
    [keyName]="filters?.KAM_DEPT_LV2_NAME"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.KAM_DEPT_LV2_NAME"
    [initialSelected]="lstOption_Selected.KAM_DEPT_LV2_NAME"
    [initialState]="lstOption_States[filters?.KAM_DEPT_LV2_NAME]"
    [showTooltip]="false"
    (onFetchDataOnSearch)="onFetchDataOnSearch($event)"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.CATEGORY_NAME_EN"
    class="multi-dropdown__category"
    dataTitle="Category"
    dataDescription="Category"
    [keyName]="filters?.CATEGORY_NAME_EN"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.CATEGORY_NAME_EN"
    [initialSelected]="lstOption_Selected.CATEGORY_NAME_EN"
    [initialState]="lstOption_States[filters?.CATEGORY_NAME_EN]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.CHANNEL_LEV2_NAME"
    class="multi-dropdown__channel-lev2"
    dataTitle="Channel Lev2"
    dataDescription="Channel Lev2"
    dataSortName="CHANNEL_LEV2_NAME"
    [keyName]="filters?.CHANNEL_LEV2_NAME"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.CHANNEL_LEV2_NAME"
    [initialSelected]="lstOption_Selected.CHANNEL_LEV2_NAME"
    [initialState]="lstOption_States[filters?.CHANNEL_LEV2_NAME]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.CHANNEL_LEV3_NAME"
    class="multi-dropdown__channel-lev3"
    dataTitle="Channel Lev3"
    dataDescription="Channel Lev3"
    dataSortName="CHANNEL_LEV3_NAME"
    [keyName]="filters?.CHANNEL_LEV3_NAME"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.CHANNEL_LEV3_NAME"
    [initialSelected]="lstOption_Selected.CHANNEL_LEV3_NAME"
    [initialState]="lstOption_States[filters?.CHANNEL_LEV3_NAME]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

    <multi-dropdown *ngIf="lstOption_IsUsed?.CHANNEL_NL"
    class="multi-dropdown__channel-lev3"
    dataTitle="National/Local"
    dataDescription="National/Local"
    [keyName]="filters?.CHANNEL_NL"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.CHANNEL_NL"
    [initialSelected]="lstOption_Selected.CHANNEL_NL"
    [initialState]="lstOption_States[filters?.CHANNEL_NL]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.SALES_HQ_LEV2_NAME"
    class="multi-dropdown__sales-hq-lev2"
    dataTitle="Sales HQ Lev2"
    dataDescription="Sales HQ Lev2"
    dataSortName="SALES_HQ_LEV2_NAME"
    [keyName]="filters?.SALES_HQ_LEV2_NAME"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.SALES_HQ_LEV2_NAME"
    [initialSelected]="lstOption_Selected.SALES_HQ_LEV2_NAME"
    [initialState]="lstOption_States[filters?.SALES_HQ_LEV2_NAME]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.SALES_HQ_LEV3_NAME"
    class="multi-dropdown__sales-hq-lev3"
    dataTitle="Sales HQ Lev3"
    dataDescription="Sales HQ Lev3"
    dataSortName="SALES_HQ_LEV3_NAME"
    [keyName]="filters?.SALES_HQ_LEV3_NAME"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.SALES_HQ_LEV3_NAME"
    [initialSelected]="lstOption_Selected.SALES_HQ_LEV3_NAME"
    [initialState]="lstOption_States[filters?.SALES_HQ_LEV3_NAME]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.SALES_CENTER_NAME"
    class="multi-dropdown__sales-center"
    dataTitle="Sales Center"
    dataDescription="Sales Center"
    [keyName]="filters?.SALES_CENTER_NAME"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.SALES_CENTER_NAME"
    [initialSelected]="lstOption_Selected.SALES_CENTER_NAME"
    [initialState]="lstOption_States[filters?.SALES_CENTER_NAME]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.SALES_CONTROL_BASE_ROUTE_CODE"
    class="multi-dropdown__sales-route"
    dataTitle="Sales Route"
    dataDescription="Sales Route"
    [keyName]="filters?.SALES_CONTROL_BASE_ROUTE_CODE"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.SALES_CONTROL_BASE_ROUTE_CODE"
    [initialSelected]="lstOption_Selected.SALES_CONTROL_BASE_ROUTE_CODE"
    [initialState]="lstOption_States[filters?.SALES_CONTROL_BASE_ROUTE_CODE]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.SALES_CONTROL_BASE_NAME"
    class="multi-dropdown__sales-bd"
    dataTitle="Sales Control Base"
    dataDescription="Sales Control Base"
    [keyName]="filters?.SALES_CONTROL_BASE_NAME"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.SALES_CONTROL_BASE_NAME"
    [initialSelected]="lstOption_Selected.SALES_CONTROL_BASE_NAME"
    [initialState]="lstOption_States[filters?.SALES_CONTROL_BASE_NAME]"
    [showTooltip]="false"
    (onFetchDataOnSearch)="onFetchDataOnSearch($event)"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.DEALER_NAME"
    class="multi-dropdown__dealer"
    dataTitle="Dealer"
    dataDescription="Dealer"
    [keyName]="filters?.DEALER_NAME"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.DEALER_NAME"
    [initialSelected]="lstOption_Selected.DEALER_NAME"
    [initialState]="lstOption_States[filters?.DEALER_NAME]"
    [showTooltip]="false"
    [isFetchDataOnSearch]="true"
    (onFetchDataOnSearch)="onFetchDataOnSearch($event)"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.KAM_DEPT_LV3_NAME"
    class="multi-dropdown__kam-dept-dev3"
    dataTitle="KAM Dept Lev3"
    dataDescription="KAM Dept Lev3"
    [keyName]="filters?.KAM_DEPT_LV3_NAME"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.KAM_DEPT_LV3_NAME"
    [initialSelected]="lstOption_Selected.KAM_DEPT_LV3_NAME"
    [initialState]="lstOption_States[filters?.KAM_DEPT_LV3_NAME]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.KAM_DEPT_LV4_NAME"
    class="multi-dropdown__kam-dept-dev4"
    dataTitle="KAM Dept Lev4"
    dataDescription="KAM Dept Lev4"
    [keyName]="filters?.KAM_DEPT_LV4_NAME"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.KAM_DEPT_LV4_NAME"
    [initialSelected]="lstOption_Selected.KAM_DEPT_LV4_NAME"
    [initialState]="lstOption_States[filters?.KAM_DEPT_LV4_NAME]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.KAM_CONTACT_PERSON_NAME"
    class="multi-dropdown__kam-account"
    dataTitle="KAM Account Mgr"
    dataDescription="KAM Account Mgr"
    [keyName]="filters?.KAM_CONTACT_PERSON_NAME"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.KAM_CONTACT_PERSON_NAME"
    [initialSelected]="lstOption_Selected.KAM_CONTACT_PERSON_NAME"
    [initialState]="lstOption_States[filters?.KAM_CONTACT_PERSON_NAME]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.ACCOUNT_LEV2_NAME_EN"
    class="multi-dropdown__account-lev2"
    dataTitle="Account Lev2"
    dataDescription="Account Lev2"
    [keyName]="filters?.ACCOUNT_LEV2_NAME_EN"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.ACCOUNT_LEV2_NAME_EN"
    [initialSelected]="lstOption_Selected.ACCOUNT_LEV2_NAME_EN"
    [initialState]="lstOption_States[filters?.ACCOUNT_LEV2_NAME_EN]"
    [showTooltip]="false"
    [isFetchDataOnSearch]="true"
    (onFetchDataOnSearch)="onFetchDataOnSearch($event)"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.ACCOUNT_LEV3_NAME_EN"
    class="multi-dropdown__account-lev3"
    dataTitle="Account Lev3"
    dataDescription="Account Lev3"
    [keyName]="filters?.ACCOUNT_LEV3_NAME_EN"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.ACCOUNT_LEV3_NAME_EN"
    [initialSelected]="lstOption_Selected.ACCOUNT_LEV3_NAME_EN"
    [initialState]="lstOption_States[filters?.ACCOUNT_LEV3_NAME_EN]"
    [showTooltip]="false"
    [isFetchDataOnSearch]="true"
    (onFetchDataOnSearch)="onFetchDataOnSearch($event)"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>


  <multi-dropdown *ngIf="lstOption_IsUsed?.SUBCATEGORY_NAME_EN"
    class="multi-dropdown__subcategory"
    dataTitle="Subcategory"
    dataDescription="Subcategory"
    [keyName]="filters?.SUBCATEGORY_NAME_EN"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.SUBCATEGORY_NAME_EN"
    [initialSelected]="lstOption_Selected.SUBCATEGORY_NAME_EN"
    [initialState]="lstOption_States[filters?.SUBCATEGORY_NAME_EN]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.BRAND_NAME_EN"
    class="multi-dropdown__brand"
    dataTitle="Brand"
    dataDescription="Brand"
    [keyName]="filters?.BRAND_NAME_EN"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.BRAND_NAME_EN"
    [initialSelected]="lstOption_Selected.BRAND_NAME_EN"
    [initialState]="lstOption_States[filters?.BRAND_NAME_EN]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.PACKAGE_LEV1_NAME_EN"
    class="multi-dropdown__package-lev1"
    dataTitle="Package Lev1"
    dataDescription="Package Lev1"
    [keyName]="filters?.PACKAGE_LEV1_NAME_EN"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.PACKAGE_LEV1_NAME_EN"
    [initialSelected]="lstOption_Selected.PACKAGE_LEV1_NAME_EN"
    [initialState]="lstOption_States[filters?.PACKAGE_LEV1_NAME_EN]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.PACKAGE_LEV2_NAME_EN"
    class="multi-dropdown__package-lev2"
    dataTitle="Package Lev2"
    dataDescription="Package Lev2"
    [keyName]="filters?.PACKAGE_LEV2_NAME_EN"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.PACKAGE_LEV2_NAME_EN"
    [initialSelected]="lstOption_Selected.PACKAGE_LEV2_NAME_EN"
    [initialState]="lstOption_States[filters?.PACKAGE_LEV2_NAME_EN]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>

  <multi-dropdown *ngIf="lstOption_IsUsed?.PACKAGE_DTL_NAME"
    class="multi-dropdown__package-detail"
    dataTitle="Package Detail"
    dataDescription="Package Detail"
    [keyName]="filters?.PACKAGE_DTL_NAME"
    [filterModuleName]="filterModuleName"
    [sectionName]="sectionName"
    [initialData]="lstOptions.PACKAGE_DTL_NAME"
    [initialSelected]="lstOption_Selected.PACKAGE_DTL_NAME"
    [initialState]="lstOption_States[filters?.PACKAGE_DTL_NAME]"
    [showTooltip]="false"
    (saveItemsChange)="onSaveItemsChange($event)"
    (convertTentativeToEnableTemp)="onConvertTentativeToEnableTemp($event)"
    (convertEnableTempToTentative)="onConvertEnableTempToTentative($event)"></multi-dropdown>
</div>

<!-- Search button area -->

<div *ngIf="isShowApplyBtn || isAlwaysShowApplyBtn">
  <div class="d-flex justify-content-center">
    <button type="button" class="btn btn-primary" [disabled]="isAddMoreFilter" (click)="updateChart(); onMixpanelTracking()">Apply</button>
  </div>
</div>