import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { HttpClient }   from '@angular/common/http';
import { Router }       from '@angular/router';
import { Subject }      from 'rxjs';
import * as htmlToImage from 'html-to-image';
import lodashClonedeep  from 'lodash.clonedeep';

import { CsvService }                from 'src/app/services/csv.services';
import { ChartServices }             from 'src/app/services/chart.services';
import { ProcessGroupedDataService } from 'src/app/services/process-grouped-data.services';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

import { FILTER_CONFIGS, AreaDeepdiveQueries } from 'src/app/model/constants';
import { AreaDeepdiveHelper } from 'src/app/components/pages/area-deepdive/area-deepdive-helper';
import { FilterHelper }       from 'src/app/components/elements/add-more-filter/filter-helper';
import { downloadAsPDF }      from 'src/app/helpers/pdfExtend';
import { PDFProperty }        from 'src/app/model/pdfProperty.model';
import { PageModel }          from 'src/app/model/page.model';

@Component({
  selector: 'app-area-deepdive-vs-ly-route',
  templateUrl: './vs-ly-route.component.html',
  styleUrls: ['./vs-ly-route.component.scss']
})
export class VsLyRouteComponent implements OnInit, OnChanges {
  FILTERS: any = FILTER_CONFIGS?.ADD_MORE_FILTER?.DEFAULT || [];
  AREA_DEEPDIVE_FILTERS = FILTER_CONFIGS?.ADD_MORE_FILTER?.COLLECTIONS?.AREA_DEEPDIVE;
  defaultVisibleFilters: number = FILTER_CONFIGS?.ADD_MORE_FILTER?.VISIBLE_COUNT || 4;
  lstOption_ChannelLev = [{ value: 'SALES_ROUTE_NAME', name: 'Route' }, { value: 'COM_DIST_SALES_REP_NAME', name: 'BD' }];
  lstOption_Selected_ChannelLev = this.lstOption_ChannelLev[0].value;

  @Input() lstOption_Selected_DateType: any;
  @Input() lstOption_Selected_SalesDate: any;
  @Input() lstAllOptionsCalendarData: any;
  @Input() lstAllOptionsAreaData: any;
  @Input() lstAllOptionsAccountData: any;
  @Input() lstAllOptionsProductData: any;
  @Input() lstOption_Selected: any;
  @Input() lstOption_States: any;
  @Input() lstOption_IsUsed: any;
  @Input() listData_Filter: any;
  @Input() updatedFilters: any;
  @Output() ready = new EventEmitter<boolean>();
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('observedElement', { static: true }) observedElement: ElementRef;
  @HostListener('window:resize', ['$event'])

  onResize(event: Event): void {
    this._configurePagination();
  }

  paging: PageModel = {
    current: 1,
    totalItem: 0,
    maxSize: 10,
    sizeOnPage: undefined,
    hideFirstLast: false
  };

  sectionName: string = 'vs LY by Route/BD';
  vsLYByRouteData: any = null;
  listGroupRouteDetail: any[] = null;
  vsLYByRouteData_CSV: any = null;

  isLoading: boolean = false;
  isNoDataMode = true;
  lstOption_States_Applied: any = {};
  lstOption_Selected_Applied: any = {};
  areaFilterQuery: object = {};

  public listChart: any[];
  public listChartId: any[] = [];

  public maxBAPC_TY: number;
  public maxNSR_TY: number;
  public maxGP_TY: number;

  public BAPC_TYHalfColumn: any;
  public BAPC_TYFullColumn: any;
  public NSR_TYHalfColumn: any;
  public NSR_TYFullColumn: any;
  public GP_TYHalfColumn: any;
  public GP_TYFullColumn: any;

  sortType: string = "desc";
  sortColumn: string = "NSR_TY";
  chartBAPC_Route: any;
  chartNRS_Route: any;
  chartGP_Route: any;
  isHasFistLoad: boolean = false;

  constructor(private http: HttpClient, private router: Router, private csvService: CsvService, private chartServices: ChartServices, private dataProcessingService: ProcessGroupedDataService, private mixPanelTrackingService: MixPanelTrackingService) {
    this.listChart = [];
  }

  ngOnInit() {
    this.areaFilterQuery = AreaDeepdiveHelper.skuSetQueryString(this.lstOption_Selected);
    this.lstOption_IsUsed = FilterHelper.getListIsUsedFilter(this.FILTERS, this.AREA_DEEPDIVE_FILTERS) || {};
    this._configurePagination();
  }

  private _configurePagination(): void {
    const winWidth = window.innerWidth;

    if (winWidth > 1024) {
      this.paging.maxSize = 10
    }

    if (1024 >= winWidth && winWidth >= 768) {
      this.paging.maxSize = 7
    }

    if (winWidth < 768) {
      this.paging.maxSize = 3;
    }
  }

  onInitData() {
    if (this.vsLYByRouteData) {
      this.paging.totalPage = this.vsLYByRouteData?.TotalPage;
      this.handleonReady();
    }
  }

  onPageChange(newPage: number): void {
    this.paging.current = newPage;
    this.onSearchData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (((changes['lstOption_Selected_DateType'] && this.lstOption_Selected_DateType && this.lstOption_Selected_DateType.length > 0)
        || (changes['lstOption_Selected_SalesDate'] && this.lstOption_Selected_SalesDate && this.lstOption_Selected_SalesDate.length > 0))) {
      if (this.isHasFistLoad) {
        this.onSearchData();
      }

      this.handleonReady();
    }

    if (changes['lstOption_Selected'] || changes['lstOption_States'] || changes['updatedFilters']) {
      this.applyFilters();

      if (this.isHasFistLoad) {
        this.onSearchData();
      }
    }

    this.isHasFistLoad = true;
  }

  applyFilters() {
    this.lstOption_States_Applied = lodashClonedeep(this.lstOption_States);
    this.lstOption_Selected_Applied = lodashClonedeep(this.lstOption_Selected);
  }

  ngOnDestroy() {
    this.destroyCharts()
  }

  ngAfterViewInit(): void {
    this.mixPanelTrackingService.observeElement(this.observedElement);
  }

  onSaveChannelLevChange(event: any) {
    this.lstOption_Selected_ChannelLev = event;
    this.onSearchData();
  }

  onSearchData() {
    if (!this.lstOption_Selected_DateType || !this.lstOption_Selected_DateType?.length || !this.lstOption_Selected_SalesDate || !this.lstOption_Selected_SalesDate.length) {
      return;
    }

    this.isLoading = true;
    this.areaFilterQuery = AreaDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied)

    AreaDeepdiveHelper.GetDataByRoute(this.http, this.lstOption_Selected_DateType ? this.lstOption_Selected_DateType : 'MTD', !AreaDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [], this.areaFilterQuery, this.lstOption_Selected_ChannelLev).then((data) => {
      if (data?.Data) {
        this.vsLYByRouteData = data?.Data;
        this.processData();
        this.sortTable('');
        this.isLoading = false;
      } else {
        console.warn('No data received from API');
        this.isLoading = false;
      }
    }).catch((error) => {
      console.error('Error fetching data:', error);
      this.isLoading = false;
    });
  }

  processData() {
    this.isNoDataMode = this.vsLYByRouteData.length == 0;
    const metrics = this.dataProcessingService.processMetrics(this.vsLYByRouteData);
    Object.assign(this, metrics);

    this.listGroupRouteDetail = this.dataProcessingService.processLevel2Data(
      this.vsLYByRouteData,
      this
    );
  }

  handleonReady() {
    let t = this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }

  createChart() {
    this.destroyCharts();
    const chartType = this.lstOption_Selected_ChannelLev === 'SALES_ROUTE_NAME' ? 'Route' : 'BD';
    this.listChartId = [
      { chartBAPC: `chartBAPC_${chartType}`, chartNRS: `chartNRS_${chartType}`, chartGP: `chartGP_${chartType}` }
    ];

    if (this.listChart.some(chart => chart.chartBAPC.id === this.listChartId[0].chartBAPC)) {
      console.warn("Duplicate chart ID detected. Skipping chart creation.");
      return;
    }

    const dataBAPC_label = this.getLabelsForChart();
    const bapcData = this.listGroupRouteDetail.map(e => parseFloat((e.BAPC_VS_LY * 100).toFixed(1)));
    const nsrData = this.listGroupRouteDetail.map(e => parseFloat((e.NSR_VS_LY * 100).toFixed(1)));
    const gpData = this.listGroupRouteDetail.map(e => parseFloat((e.GP_VS_LY * 100).toFixed(1)));

    setTimeout(() => {
      try {
        this.listChart.push({
          chartBAPC: this.chartServices.createLineChart(this.listChartId[0].chartBAPC, dataBAPC_label, bapcData, -200, 200),
          chartNRS: this.chartServices.createLineChart(this.listChartId[0].chartNRS, dataBAPC_label, nsrData, -200, 200),
          chartGP: this.chartServices.createLineChart(this.listChartId[0].chartGP, dataBAPC_label, gpData, -200, 200),
        });
      } catch (error) {
        console.error('Error creating charts:', error);
      }
    }, 1000);
  }

  // Destroy Chart
  destroyCharts() {
    if (this.listChart?.length > 0) {
      this.listChart.forEach((chart: any) => {
        if (chart.chartBAPC) {
          chart.chartBAPC.destroy();
        }
        if (chart.chartNRS) {
          chart.chartNRS.destroy();
        }
        if (chart.chartGP) {
          chart.chartGP.destroy();
        }
      });
    }

    this.listChart = [];
    this.listChartId = [];
  }

  getLabelsForChart() {
    const labelMapping = {
      'SALES_ROUTE_NAME': 'SALES_ROUTE_NAME',
      'COM_DIST_SALES_REP_NAME': 'COM_DIST_SALES_REP_NAME',
    };

    const labelKey = labelMapping[this.lstOption_Selected_ChannelLev];
    return this.listGroupRouteDetail.map(e => e[labelKey] || 'NULL');
  }

  sortTable(column: string) {
    if (column == this.sortColumn && column) {
      this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortType = "desc";
    }

    this.sortColumn = column ? column : 'NSR_TY';
    this.listGroupRouteDetail = this.sortType == 'asc' ? this.listGroupRouteDetail.slice().sort((a, b) => a[this.sortColumn] - b[this.sortColumn]) : this.listGroupRouteDetail.slice().sort((a, b) => b[this.sortColumn] - a[this.sortColumn]);
    this.destroyCharts();
    this.createChart();
  };

  // CSV Export
  exportAreaRoute_CVS_by_Url() {
    const obj       = AreaDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied);
    const dateType  = this.lstOption_Selected_DateType ? this.lstOption_Selected_DateType : 'MTD';
    const salesDate = !AreaDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [];

    this.areaFilterQuery = {
      ...obj,
      CacheKey: `DATE_TYPE=${encodeURIComponent(dateType)}&SALES_DATE=${salesDate}&${obj['CacheKey']}`,
      Selected: {
        ...obj['Selected'],
        DATE_TYPE: dateType,
        SALES_DATE: salesDate
      }
    };

    this.csvService.getCsvUrl(AreaDeepdiveQueries.byRoute, this.areaFilterQuery, this.lstOption_Selected_ChannelLev)
      .subscribe((data) => {
        if (data) {
          this._downloadCsv(data?.Csv_Url);
        }
      });
  }

  private _downloadCsv(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }

  // PDF Export
  @ViewChild('pdf_print_area_2', { static: false }) pdf_print_area_2: ElementRef;
  exportPDF(name) {
    const $ = window["jQuery"];
    $("body").addClass("pdf-printing");
    $(".table-container").css('height', 'auto');
    $(".table-container").css('overflow-y', 'scroll');
    $(".filter-section").css('display', 'none');

    var area = this.pdf_print_area_2.nativeElement;
    htmlToImage.toCanvas(this.pdf_print_area_2.nativeElement, { quality: 1 })
      .then(function (canvas) {
        var pdfProperty: PDFProperty = {
          option: { margin: [50, 0, 46, 0] },
          canvas: canvas
        }
        downloadAsPDF(name, area, false, pdfProperty);
      });
  }
}
