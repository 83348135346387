import { Injectable } from '@angular/core';
import { Chart } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Injectable({
  providedIn: 'root'
})
export class ChartServices {
  createLineChart(chartId: string, labels: string[], data: number[], minValue: number, maxValue: number, borderColor: string = 'rgb(83, 100, 128)') {
    return new Chart(chartId, {
      type: 'line',
      data: {
        labels,
        datasets: [{
          data,
          fill: false,
          borderColor: borderColor,
          tension: 0
        }]
      },
      plugins: [ChartDataLabels],
      options: {
        maintainAspectRatio: false,
        aspectRatio: 1,
        indexAxis: 'y',
        responsive: true,
        scales: {
          x: { display: false, min: minValue, max: maxValue },
          y: { display: false }
        },
        layout: {
          padding: { left: 0, right: 0, top: 15, bottom: 15 }
        },
        plugins: {
          legend: { display: false },
          datalabels: {
            color: 'black',
            align: 'right',
            formatter: function (value) {
              return value > 0 ? `+${value}` : value;
            }
          }
        }
      }
    });
  }
}
