<ng-container *ngIf="!isVsLyAreaReady || !isVsLyChannelReady || !isVsLyRouteReady || !isVsLyDealerReady || !isVsLyTimeLineReady">
  <div class="text-center spinner-wrapper">
      <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
      </div>
  </div>
</ng-container>

<div *ngIf="isLoading" class="loading-overlay">
  <div class="text-center spinner-wrapper">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
</div>

<div id="pdf_print_area_deepdive" #pdf_print_area_deepdive
[class]="isVsLyAreaReady && isVsLyChannelReady && isVsLyRouteReady && isVsLyDealerReady && isVsLyTimeLineReady ? '' : 'not-show' ">
  <div id="pdf_print_area_2" #pdf_print_area_2 class="row">
    <div class="col-lg-12 col-md-12">
      <div class="card mb-30">
        <div class="area-title-header">
          <h2>Area Breakdown</h2>
        </div>

        <sales-date-filter
          pageName="AreaDeepdive"
          (onDateTypeSaveItemsChange)="onDateTypeSaveItemsChange($event)"></sales-date-filter>

        <app-add-more-filter
          [filterModuleName]="'AreaDeepdivePage'"
          [filters]="FILTERS"
          [listData_Filter]="listData_Filter"
          [lstOption_Selected]="lstOption_Selected"
          [lstOption_States]="lstOption_States"
          [lstOption_IsUsed]="lstOption_IsUsed"
          [defaultVisibleFilters]="defaultVisibleFilters"
          [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
          [lstAllOptionsAreaData]="lstAllOptionsAreaData"
          [lstAllOptionsAccountData]="lstAllOptionsAccountData"
          [lstAllOptionsProductData]="lstAllOptionsProductData"
          (onUpdateChart)="onUpdateChart($event)"
          (onResetFiltersGroup)="onResetFiltersGroup($event)">
        </app-add-more-filter>
        </div>
    </div>
  </div>

  <app-area-deepdive-vs-ly-area
    [lstOption_Selected]="lstOption_Selected"
    [lstOption_States]="lstOption_States"
    [lstOption_IsUsed]="lstOption_IsUsed"
    [listData_Filter]="listData_Filter"
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate"
    [updatedFilters]="updatedFilters"
    (ready)="vsLyAreaReady($event)"></app-area-deepdive-vs-ly-area>

  <app-area-deepdive-vs-ly-channel
    [lstOption_Selected]="lstOption_Selected"
    [lstOption_States]="lstOption_States"
    [lstOption_IsUsed]="lstOption_IsUsed"
    [listData_Filter]="listData_Filter"
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate"
    [updatedFilters]="updatedFilters"
    (ready)="vsLyChannelReady($event)">
  </app-area-deepdive-vs-ly-channel>

  <app-area-deepdive-vs-ly-route
    [lstOption_Selected]="lstOption_Selected"
    [lstOption_States]="lstOption_States"
    [lstOption_IsUsed]="lstOption_IsUsed"
    [listData_Filter]="listData_Filter"
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate"
    [updatedFilters]="updatedFilters"
    (ready)="vsLyRouteReady($event)"></app-area-deepdive-vs-ly-route>

  <app-area-deepdive-vs-ly-dealer
    [lstOption_Selected]="lstOption_Selected"
    [lstOption_States]="lstOption_States"
    [lstOption_IsUsed]="lstOption_IsUsed"
    [listData_Filter]="listData_Filter"
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate"
    [updatedFilters]="updatedFilters"
    (ready)="vsLyDealerReady($event)"></app-area-deepdive-vs-ly-dealer>

  <app-area-deepdive-vs-ly-timeline
    [lstOption_Selected]="lstOption_Selected"
    [lstOption_States]="lstOption_States"
    [lstOption_IsUsed]="lstOption_IsUsed"
    [listData_Filter]="listData_Filter"
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate"
    [updatedFilters]="updatedFilters"
    (ready)="vsLyTimeLineReady($event)"></app-area-deepdive-vs-ly-timeline>
</div>
