import { Component, OnInit, OnDestroy, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import lodashClonedeep from 'lodash.clonedeep';
import { Subscription } from 'rxjs';
import moment from "moment";

import { authorize } from 'src/app/helpers/AuthorizePage';
import { dispatchDateSelectChange } from 'src/app/helpers/tracking';
import { AreaDeepdiveHelper } from './area-deepdive-helper';
import { FILTER_CONFIGS } from 'src/app/model/constants';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FilterHelper } from 'src/app/components/elements/add-more-filter/filter-helper';
import { SalesDateService } from '../../elements/sales-date-filter/sales-date-filter.service';
import { UserService } from 'src/app/services/user.services';

@Component({
  selector: 'app-area-deepdive',
  templateUrl: './area-deepdive.component.html',
  styleUrls: ['./area-deepdive.component.scss'],
  host: {
    'class': 'se-report-content'
  }
})

export class AreaDeepdiveComponent implements OnInit, OnDestroy {
  FILTERS: any = FILTER_CONFIGS?.ADD_MORE_FILTER?.KEY_NAME_AREA_DEEPDIVE || [];
  AREA_DEEPDIVE_FILTERS = FILTER_CONFIGS?.ADD_MORE_FILTER?.COLLECTIONS?.AREA_DEEPDIVE;
  defaultVisibleFilters: number = FILTER_CONFIGS?.ADD_MORE_FILTER?.VISIBLE_COUNT ?? 4;
  lstOption_IsUsed: any = {};
  lstOption_Selected: any = {};
  lstOption_States: any = {};
  lstOption_States_Applied: any = {};
  listData_Filter: any = null;
  lstOption_Selected_Applied: any = {};
  skuFilterQuery: object = {};
  updatedFilters: number = 0;

  isLoading: boolean = false;
  private loadingSubscription: Subscription;
  private salesDateSubscription: Subscription;

  isVsLyAreaReady = false;
  isVsLyChannelReady = false;
  isVsLyRouteReady = false;
  isVsLyDealerReady = false;
  isVsLyTimeLineReady = false;

  lstAllOptionsCalendarData: any;
  lstAllOptionsAreaData: any;
  lstAllOptionsAccountData: any;
  lstAllOptionsProductData: any;

  lstOption_Selected_DateType: any = '';
  lstOption_Aggregated_DateType: any = '';
  lstOption_Selected_SalesDate: any = [];
  userGroupName: string;

  @ViewChild('pdf_print_area_deepdive') pdf_print_area_deepdive: ElementRef;
  sectionName: string = 'vs LY Area';

  dailySalesDateRange: any = {
    ready: false,
    fromDate: null,
    toDate: null,
    maxDate: null,
    minDate: null,
    previousFromDate: null,
    previousToDate: null
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private salesDateService: SalesDateService,
    private userService: UserService,
  ) { }

  async ngOnInit() {
    if (!authorize()) {
      this.router.navigateByUrl('unauthorized');
    } else {
      this.initializeFilters();
      this.userGroupName = this.userService.getUserInfo() || '';
      this.salesDateSubscription = this.salesDateService.getSESalesDate().subscribe((date: string[]) => {
        if (date && date.length > 0) {
          AreaDeepdiveHelper.GetAllFilterCalendarOptions(this.http, date[0], this.userGroupName).then((data) => {
            if (data) {
              this.lstAllOptionsCalendarData = data;
            }
          });

          AreaDeepdiveHelper.GetAllFilterAreaOptions(this.http, date[0], this.userGroupName).then((data) => {
            this.lstAllOptionsAreaData = data;
          });

          AreaDeepdiveHelper.GetAllFilterAccountOptions(this.http, date[0], this.userGroupName).then((data) => {
            this.lstAllOptionsAccountData = data;
          });

          AreaDeepdiveHelper.GetAllFilterProductOptions(this.http, date[0], this.userGroupName).then((data) => {
            this.lstAllOptionsProductData = data;
          });
        }
      });

      this.loadingSubscription = AreaDeepdiveHelper.isLoading().subscribe((loading: boolean) => {
        this.isLoading = loading;
      });
    }
  }
  initializeFilters() {
    const lstOption = FilterHelper.init_lstOption_States(`AreaDeepdivePage_${this.userService.getUserFilterName()}`, this.FILTERS, this.defaultVisibleFilters, this.userService.getUserInfo()) || {};
    this.lstOption_States = lstOption.lstOption_States;
    this.lstOption_Selected = lstOption.lstOption_Selected;
    this.listData_Filter = lstOption.listData_Filter;
    this.skuFilterQuery = AreaDeepdiveHelper.skuSetQueryString(lstOption.lstOption_Selected);
    this.lstOption_IsUsed = FilterHelper.getListIsUsedFilter(this.FILTERS, this.AREA_DEEPDIVE_FILTERS) || {};
  }

  onUpdateChart(eventData: { lstOption_States: any, lstOption_Selected: any }) {
    this.lstOption_States = eventData.lstOption_States;
    this.lstOption_Selected = eventData.lstOption_Selected;
    this.updatedFilters++;
  }

  onResetFiltersGroup($event: any) {
    const { data } = $event;

    if (data && Array.isArray(data) && data.length > 0) {
      data.map(key => {
        if (key && key.length > 0) {
          this.lstOption_Selected[key] = ['All'];
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    if (this.salesDateSubscription) {
      this.salesDateSubscription.unsubscribe();
    }
  }

  convertToNgbDateStruct(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  private getOptionSaleDate(value1: string, value2: string, isInit: boolean) {
    const fromObj = new Date(value1);
    const toObj = new Date(value2);

    let todate = new Date(toObj.getFullYear(), toObj.getMonth(), toObj.getDate());
    let fromdate = new Date(fromObj.getFullYear(), fromObj.getMonth(), fromObj.getDate());
    this.dailySalesDateRange.toDate = todate;
    this.dailySalesDateRange.fromDate = fromdate;
    this.dailySalesDateRange.previousToDate = todate;
    this.dailySalesDateRange.previousFromDate = fromdate;

    if (isInit) {
      let maxDate = new Date(toObj.getFullYear(), toObj.getMonth(), toObj.getDate());
      let minDate = new Date(fromObj.getFullYear(), fromObj.getMonth(), fromObj.getDate());
      this.dailySalesDateRange.maxDate = this.convertToNgbDateStruct(maxDate);
      this.dailySalesDateRange.minDate = this.convertToNgbDateStruct(minDate);
    }

    this.dailySalesDateRange.ready = true;
  }

  public onDateTypeSaveItemsChange = (event: any) => {
    dispatchDateSelectChange({ ...event, ...{ element: "area-deepdive" }, ...{ isDateRange: true } });
    const { selectedDateType, selectedSalesDate } = event;

    if (selectedDateType) {
      this.lstOption_Aggregated_DateType = selectedDateType;
    }

    if (selectedSalesDate) {
      this.lstOption_Selected_SalesDate = [...selectedSalesDate];
    }
  }

  public storeFilterOption() {
    FilterHelper.store_lstOption_States('AreaDeepdiveComponent',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      this.lstOption_Selected_DateType,
      this.lstOption_Selected_SalesDate,
      this.dailySalesDateRange,
      this.userGroupName);
  }

  public onDateSelect(e: any) {
    if (e) {
      const { from, to } = e;

      if (from && to) {
        const startDate = moment(new Date(from)).format("YYYY-MM-DD");
        const endDate = moment(new Date(to)).format("YYYY-MM-DD");

        if (!moment(startDate).isSame(this.dailySalesDateRange.previousFromDate) || !moment(endDate).isSame(this.dailySalesDateRange.previousToDate)) {
          this.lstOption_Selected_SalesDate = [startDate, endDate];
          this.getOptionSaleDate(startDate, endDate, false);
          this.storeFilterOption();
        }
      }
    }
  }

  public vsLyAreaReady(ready: boolean) {
    this.isVsLyAreaReady = ready;
  }

  public vsLyChannelReady(ready: boolean) {
    this.isVsLyChannelReady = ready;
  }

  public vsLyRouteReady(ready: boolean) {
    this.isVsLyRouteReady = ready;
  }

  public vsLyDealerReady(ready: boolean) {
    this.isVsLyDealerReady = ready;
  }

  public vsLyTimeLineReady(ready: boolean) {
    this.isVsLyTimeLineReady = ready;
  }
}
