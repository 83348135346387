import { AreaDeepdiveHelper } from '../components/pages/area-deepdive/area-deepdive-helper';
import { AnalyticsHelper } from '../helpers/analytics';
import { Injectable } from '@angular/core';

interface ProcessedMetrics {
  maxBAPC_TY: number;
  maxNSR_TY: number;
  maxGP_TY: number;
  BAPC_TYHalfColumn: string;
  BAPC_TYFullColumn: string;
  NSR_TYHalfColumn: string;
  NSR_TYFullColumn: string;
  GP_TYHalfColumn: string;
  GP_TYFullColumn: string;
  isNoDataMode: boolean;
}

interface BaseDataItem {
  BAPC_TY: number;
  NSR_TY: number;
  GP_TY: number;
}

interface ProcessedDataItem extends BaseDataItem {
  WithBAPC_TY: string;
  WithNSR_TY: string;
  WithGP_TY: string;
  BAPC_TY_Format: string;
  NSR_TY_Format: string;
  GP_TY_Format: string;
}

interface GroupedData {
  subIdChart: string;
  ChildItems: ProcessedDataItem[];
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})

export class ProcessGroupedDataService {
  processMetrics(data: BaseDataItem[]): ProcessedMetrics {
    const isNoDataMode = data.length === 0;

    const maxBAPC_TY = AreaDeepdiveHelper.roundMaxValueInChart(
      Math.max(...data.map(item => item.BAPC_TY).filter(value => !isNaN(value)))
    );

    const maxNSR_TY = AreaDeepdiveHelper.roundMaxValueInChart(
      Math.max(...data.map(item => item.NSR_TY).filter(value => !isNaN(value)))
    );

    const maxGP_TY = AreaDeepdiveHelper.roundMaxValueInChart(
      Math.max(...data.map(item => item.GP_TY).filter(value => !isNaN(value)))
    );

    return {
      maxBAPC_TY,
      maxNSR_TY,
      maxGP_TY,
      isNoDataMode,
      BAPC_TYHalfColumn: AnalyticsHelper.formatNumber(maxBAPC_TY / 2000),
      BAPC_TYFullColumn: AnalyticsHelper.formatNumber(maxBAPC_TY / 1000),
      NSR_TYHalfColumn: AnalyticsHelper.formatNumber(maxNSR_TY / 2000000),
      NSR_TYFullColumn: AnalyticsHelper.formatNumber(maxNSR_TY / 1000000),
      GP_TYHalfColumn: AnalyticsHelper.formatNumber(maxGP_TY / 2000000),
      GP_TYFullColumn: AnalyticsHelper.formatNumber(maxGP_TY / 1000000)
    };
  }

  processDataItem(item: BaseDataItem, metrics: ProcessedMetrics): ProcessedDataItem {
    return {
      ...item,
      WithBAPC_TY: Math.abs(item.BAPC_TY / metrics.maxBAPC_TY * 100).toFixed(2),
      WithNSR_TY: Math.abs(item.NSR_TY / metrics.maxNSR_TY * 100).toFixed(2),
      WithGP_TY: Math.abs(item.GP_TY > 0 ? item.GP_TY / metrics.maxGP_TY * 100 : 0).toFixed(2),
      BAPC_TY_Format: AnalyticsHelper.formatNumber((item.BAPC_TY / 1000).toFixed(0)),
      NSR_TY_Format: AnalyticsHelper.formatNumber((item.NSR_TY / 1000000).toFixed(0)),
      GP_TY_Format: AnalyticsHelper.formatNumber((item.GP_TY / 1000000).toFixed(0))
    };
  }

  processLevel2Data(data: any[], metrics: ProcessedMetrics): ProcessedDataItem[] {
    return data.map(item => this.processDataItem(item, metrics));
  }

  processLevel3Data(
    data: any[],
    metrics: ProcessedMetrics,
    level2Field: string
  ): GroupedData[] {
    const level2Names = Array.from(new Set(data.map(item => item[level2Field])));

    return level2Names.map(level2Name => {
      const filteredItems = data.filter(item => item[level2Field] === level2Name);
      const childItems = filteredItems.map(item => this.processDataItem(item, metrics));

      return {
        [level2Field]: level2Name ?? 'NULL',
        subIdChart: level2Name?.split(' ')?.join('_') ?? 'NULL',
        ChildItems: childItems
      };
    });
  }

  processHierarchicalData(
    data: any[],
    metrics: ProcessedMetrics,
    level2Field: string,
    level3Field: string
  ): GroupedData[] {
    const result: GroupedData[] = [];
    const level2Names = Array.from(new Set(data.map(item => item[level2Field])));

    level2Names.forEach(level2Name => {
      const level2Items = data.filter(item => item[level2Field] === level2Name);
      const level3Names = Array.from(new Set(level2Items.map(item => item[level3Field])));

      level3Names.forEach(level3Name => {
        const filteredItems = data.filter(
          item => item[level2Field] === level2Name && item[level3Field] === level3Name
        );

        const childItems = filteredItems.map(item => this.processDataItem(item, metrics));

        result.push({
          [level2Field]: level2Name,
          [level3Field]: level3Name,
          subIdChart: `${level2Name?.split(' ').join('_') ?? 'Unknown_Channel2'}_${level3Name?.split(' ').join('_') ?? 'Unknown_Channel3'}`,
          ChildItems: childItems
        });
      });
    });

    return result;
  }
}
