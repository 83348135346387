<div id="pdf_print_skudeepdive" #pdf_print_skudeepdive class="js-sku-vs-ly-timeline row sku-deepdive-vs-ly-timeline">
    <div class="col-lg-12 col-md-12">
        <div class="card mb-30 print-mb-0">
            <app-add-more-filter class="app-add-more-filter"
                [filterModuleName]="'VsLyTimelineComponent'"
                [filters]="FILTERS"
                [listData_Filter] = "listData_Filter"
                [lstOption_Selected]="lstOption_Selected"
                [lstOption_States]="lstOption_States"
                [lstOption_IsUsed]="lstOption_IsUsed"
                [defaultVisibleFilters]="defaultVisibleFilters"
                [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
                [lstAllOptionsAreaData]="lstAllOptionsAreaData"
                [lstAllOptionsAccountData]="lstAllOptionsAccountData"
                [lstAllOptionsProductData]="lstAllOptionsProductData"
                [sectionName]="sectionName"
                (onUpdateChart)="onUpdateChart($event)"
                (onResetFiltersGroup)="onResetFiltersGroup($event)"></app-add-more-filter>

            <div *ngIf="isLoading" class="text-center spinner-wrapper">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <div class="filter-container-pdf">
                <div class="filter-list row">
                    <b class="filter-list-header col-1half">Date Type: </b>
                    <div class="filter-list-content col-10half">
                        <span>{{this.lstOption_Selected_DateType}}</span>
                    </div>
                </div>
                <div class="filter-list row">
                    <b class="filter-list-header col-1half">Sales Date: </b>
                    <div class="filter-list-content col-10half">
                        <span>{{lstOption_Selected_SalesDate}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.CHANNEL_LEV1_NAME == 'enable'">
                    <b class="filter-list-header col-1half">Channel Lev1:</b>
                    <div class="filter-list-content col-10half">
                        <span>{{lstOption_Selected_Applied.CHANNEL_LEV1_NAME}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.CHANNEL_LEV2_NAME == 'enable'">
                    <b class="filter-list-header col-1half">Channel Lev2:</b>
                    <div class="filter-list-content col-10half">
                        <span>{{lstOption_Selected_Applied.CHANNEL_LEV2_NAME}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.CHANNEL_LEV3_NAME == 'enable'">
                    <b class="filter-list-header col-1half">Channel Lev3:</b>
                    <div class="filter-list-content col-10half">
                        <span>{{lstOption_Selected_Applied.CHANNEL_LEV3_NAME}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.SALES_HQ_LEV1_NAME == 'enable'">
                    <b class="filter-list-header col-1half">Sales HQ Lev1: </b>
                    <div class="filter-list-content col-10half">
                        <span>{{lstOption_Selected_Applied.SALES_HQ_LEV1_NAME}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.SALES_HQ_LEV2_NAME == 'enable'">
                    <b class="filter-list-header col-1half">Sales HQ Lev2: </b>
                    <div class="filter-list-content col-10half">
                        <span>{{lstOption_Selected_Applied.SALES_HQ_LEV2_NAME}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.SALES_HQ_LEV3_NAME == 'enable'">
                    <b class="filter-list-header col-1half">Sales HQ Lev3: </b>
                    <div class="filter-list-content col-10half">
                        <span [class]="lstOption_Selected_Applied.SALES_HQ_LEV3_NAME!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.SALES_HQ_LEV3_NAME}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.SALES_CENTER_NAME == 'enable'">
                    <b class="filter-list-header col-1half">Sales Center: </b>
                    <div class="filter-list-content col-10half">
                        <span [class]="lstOption_Selected_Applied.SALES_CENTER_NAME!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.SALES_CENTER_NAME}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.SALES_ROUTE_NAME == 'enable'">
                    <b class="filter-list-header col-1half">Sales Route: </b>
                    <div class="filter-list-content col-10half">
                        <span>{{lstOption_Selected_Applied.SALES_ROUTE_NAME}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.COM_DIST_SALES_REP_NAME == 'enable'">
                    <b class="filter-list-header col-1half">Sales BD: </b>
                    <div class="filter-list-content col-10half">
                        <span [class]="lstOption_Selected_Applied.COM_DIST_SALES_REP_NAME!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.COM_DIST_SALES_REP_NAME}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.DEALER_NAME == 'enable'">
                    <b class="filter-list-header col-1half">Dealer: </b>
                    <div class="filter-list-content col-10half">
                        <span [class]="lstOption_Selected_Applied.DEALER_NAME!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.DEALER_NAME}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.KAM_DEPT_LV2_NAME == 'enable'">
                    <b class="filter-list-header col-1half">KamDeptLev2: </b>
                    <div class="filter-list-content col-10half">
                        <span [class]="lstOption_Selected_Applied.KAM_DEPT_LV2_NAME!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.KAM_DEPT_LV2_NAME}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.KAM_DEPT_LV3_NAME == 'enable'">
                    <b class="filter-list-header col-1half">KamDeptLev3: </b>
                    <div class="filter-list-content col-10half">
                        <span [class]="lstOption_Selected_Applied.KAM_DEPT_LV3_NAME!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.KAM_DEPT_LV3_NAME}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.KAM_DEPT_LV4_NAME == 'enable'">
                    <b class="filter-list-header col-1half">KamDeptLev4: </b>
                    <div class="filter-list-content col-10half">
                        <span [class]="lstOption_Selected_Applied.KAM_DEPT_LV4_NAME!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.KAM_DEPT_LV4_NAME}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.KAM_CONTACT_PERSON_NAME == 'enable'">
                    <b class="filter-list-header col-1half">KAM Account Mgr: </b>
                    <div class="filter-list-content col-10half">
                        <span [class]="lstOption_Selected_Applied.KAM_CONTACT_PERSON_NAME!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.KAM_CONTACT_PERSON_NAME}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.ACCOUNT_LEV2_NAME_EN == 'enable'">
                    <b class="filter-list-header col-1half">Account lev2: </b>
                    <div class="filter-list-content col-10half">
                        <span [class]="lstOption_Selected_Applied.ACCOUNT_LEV2_NAME_EN!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.ACCOUNT_LEV2_NAME_EN}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.ACCOUNT_LEV3_NAME_EN == 'enable'">
                    <b class="filter-list-header col-1half">Account lev3: </b>
                    <div class="filter-list-content col-10half">
                        <span [class]="lstOption_Selected_Applied.ACCOUNT_LEV3_NAME_EN!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.ACCOUNT_LEV3_NAME_EN}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.CATEGORY_NAME_EN == 'enable'">
                    <b class="filter-list-header col-1half">Category: </b>
                    <div class="filter-list-content col-10half">
                        <span>{{lstOption_Selected_Applied.CATEGORY_NAME_EN}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.SUBCATEGORY_NAME_EN == 'enable'">
                    <b class="filter-list-header col-1half">Subcategory: </b>
                    <div class="filter-list-content col-10half">
                        <span [class]="lstOption_Selected_Applied.SUBCATEGORY_NAME_EN!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.SUBCATEGORY_NAME_EN}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.BRAND_NAME_EN == 'enable'">
                    <b class="filter-list-header col-1half">Brand: </b>
                    <div class="filter-list-content col-10half">
                        <span>{{lstOption_Selected_Applied.BRAND_NAME_EN}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.PACKAGE_LEV1_NAME_EN == 'enable'">
                    <b class="filter-list-header col-1half">Package lev1: </b>
                    <div class="filter-list-content col-10half">
                        <span>{{lstOption_Selected_Applied.PACKAGE_LEV1_NAME_EN}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.PACKAGE_LEV2_NAME_EN == 'enable'">
                    <b class="filter-list-header col-1half">Package lev2: </b>
                    <div class="filter-list-content col-10half">
                        <span>{{lstOption_Selected_Applied.PACKAGE_LEV2_NAME_EN}}</span>
                    </div>
                </div>
                <div class="filter-list row" *ngIf="lstOption_States_Applied.PACKAGE_DTL_NAME == 'enable'">
                    <b class="filter-list-header col-1half">Package details: </b>
                    <div class="filter-list-content col-10half">
                        <span [class]="lstOption_Selected_Applied.PACKAGE_DTL_NAME!='All' ? 'japanese-text' : ''">{{lstOption_Selected_Applied.PACKAGE_DTL_NAME}}</span>
                    </div>
                </div>
            </div>

            <h3 #observedElement class="title-hidden">{{ sectionName }}</h3>
            <!-- Data chart -->
            <div class="row" *ngIf="!isLoading">
                <div class="col-lg-12 col-md-12">
                    <div>
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <h3>{{ sectionName }}</h3>
                        </div>
                        <div class="subtitle">
                            <p>*Each period is shown by the start date</p>
                        </div>
                        <div class="card-body">
                            <div class="progress-timeline-container">
                                <div class="table-container" #horizontalScrollTable>
                                    <div class="table-content BAPC-container flex">
                                        <div class="BAPC-header">
                                            <div class="table-row__head vertial_chart__head flex">
                                                <p class="rotate-text text-center">BAPC</p>
                                                <p class="rotate-text text-center">(K Case)</p>

                                            </div>
                                        </div>
                                        <div class="BAPC-content-container">
                                            <!--BAPCTY-->
                                            <div class="table-row type-1" *ngIf="!isBAPCTYHasNegativeElement">
                                                <div class="table-row__head vertial_chart__head flex">
                                                    <p class="rotate-text text-center">Act</p>

                                                    <span [ngStyle]="hozBarBAPCTY"
                                                        class="table-row__head-bar-stick-title" *ngIf="!isNoDataMode">{{hozBarTitleBAPCTY}}</span>

                                                    <span style="bottom: 0; margin-bottom: -3px"
                                                        class="table-row__head-bar-stick-title" *ngIf="!isNoDataMode">{{hozBarIndexBAPCTY}}</span>
                                                </div>
                                                <div class="table-row__body table-row__body-bar">
                                                    <div [ngStyle]="hozBarBAPCTY" class="table-row__body-bar-stick">
                                                        <span class="table-row__body-middle__line"></span>
                                                    </div>
                                                    <div class="table-row__body-start type-bapc_ty">
                                                        <ng-container *ngFor="let item of arrBAPCTY;">
                                                            <div class="bar-item" [style.height.%]="item.height">
                                                                <p class="rotate-text">{{item.value}}</p>
                                                            </div>

                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="table-row type-2" *ngIf="isBAPCTYHasNegativeElement">
                                                <div class="table-row__head vertial_chart__head flex">
                                                    <p class="rotate-text text-center">Act</p>

                                                    <span style="top: auto; bottom: 75%;"
                                                        class="table-row__head-bar-stick-title">{{hozBarTitleBAPCTY}}</span>
                                                    <span style="top: 50%; transform: translateY(-50%);"
                                                        class="table-row__head-bar-stick-title">{{hozBarIndexBAPCTY}}</span>
                                                    <span style="top: auto; bottom: 25%;"
                                                        class="table-row__head-bar-stick-title">-{{hozBarTitleBAPCTY}}</span>
                                                </div>
                                                <div class="table-row__body table-row__body-bar table-row__body-percent">
                                                    <div class="table-row__body-percent__wrapper">
                                                        <div class="percent__line">
                                                            <span [ngStyle]="hozBarBAPCTY"
                                                                class="table-row__body-middle__line"></span>
                                                        </div>
                                                        <div class="percent__value">
                                                            <ng-container *ngFor="let item of arrBAPCTY;">
                                                                <div class="bar-item" [style.height.%]="item.height">
                                                                    <p class="rotate-text">{{item.value}}</p>
                                                                </div>

                                                            </ng-container>
                                                        </div>
                                                        <div class="percent__line">
                                                            <span [ngStyle]="hozBarOppGPTY"
                                                                class="table-row__body-middle__line"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clear"></div>

                                            <!--BAPCVSLY-->
                                            <div class="table-row type-2">
                                                <div class="table-row__head vertial_chart__head flex">
                                                    <p class="rotate-text text-center">% vs LY</p>

                                                    <span style="top: auto; bottom: 75%;"
                                                        class="table-row__head-bar-stick-title">{{hozBarTitleBAPCVSLY}}</span>
                                                    <span style="top: 50%; transform: translateY(-50%);"
                                                        class="table-row__head-bar-stick-title">0.0</span>
                                                    <span style="top: auto; bottom: 25%;"
                                                        class="table-row__head-bar-stick-title">{{hozBarTitleOppBAPCVSLY}}</span>
                                                </div>
                                                <div class="table-row__body table-row__body-bar table-row__body-percent">
                                                    <div class="table-row__body-percent__wrapper">
                                                        <div class="percent__line">
                                                            <span [ngStyle]="hozBarBAPCVSLY"
                                                                class="table-row__body-middle__line"></span>
                                                        </div>
                                                        <div class="percent__value">
                                                            <ng-container *ngFor="let item of arrBAPCVSLY;">
                                                                <div class="bar-item"
                                                                    [ngClass]="{'bar__positive': item.isPositive, 'bar__negative' : !item.isPositive }">
                                                                    <span
                                                                        [ngClass]="{'bar-item__positive bar__positive--gray': item.isPositive, 'bar-item__negative bar__negative--gray' : !item.isPositive }"
                                                                        [style.height.%]="item.height"></span>
                                                                        <span class="rotate-text">{{ item.isPositive ? '+' + item.value : item.value }}</span>
                                                                    </div>

                                                            </ng-container>
                                                        </div>
                                                        <div class="percent__line">
                                                            <span [ngStyle]="hozBarOppBAPCVSLY"
                                                                class="table-row__body-middle__line"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clear"></div>
                                        </div>
                                    </div>

                                    <div class="table-content NRS-container flex">
                                        <div class="NRS-header">
                                            <div class="table-row__head vertial_chart__head flex">
                                                <p class="rotate-text text-center">NSR</p>
                                                <p class="rotate-text text-center">(MM JPY)</p>
                                            </div>
                                        </div>
                                        <div class="NRS-content-container">
                                            <!--NSRTY-->
                                            <div class="table-row type-1" *ngIf="!isNSRTYHasNegativeElement">
                                                <div class="table-row__head vertial_chart__head flex">
                                                    <p class="rotate-text text-center">Act</p>
                                                    <span [ngStyle]="hozBarNSRTY"
                                                        class="table-row__head-bar-stick-title" *ngIf="!isNoDataMode">{{hozBarTitleNSRTY}}</span>
                                                    <span style="bottom: 0; margin-bottom: -3px"
                                                        class="table-row__head-bar-stick-title" *ngIf="!isNoDataMode">{{hozBarIndexNSRTY}}</span>
                                                </div>
                                                <div class="table-row__body table-row__body-bar">
                                                    <div [ngStyle]="hozBarNSRTY" class="table-row__body-bar-stick">
                                                        <span class="table-row__body-middle__line"></span>
                                                    </div>
                                                    <div class="table-row__body-start type-nsr-ty">
                                                        <ng-container *ngFor="let item of arrNSRTY;">
                                                            <div class="bar-item" [style.height.%]="item.height">
                                                                <p class="rotate-text">{{item.value}}</p>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="table-row type-2" *ngIf="isNSRTYHasNegativeElement">
                                                <div class="table-row__head vertial_chart__head flex">
                                                    <p class="rotate-text text-center">Act</p>
                                                    <span style="top: auto; bottom: 75%;"
                                                        class="table-row__head-bar-stick-title" *ngIf="!isNoDataMode">{{hozBarTitleNSRTY}}</span>
                                                    <span style="top: 50%; transform: translateY(-50%);"
                                                        class="table-row__head-bar-stick-title" *ngIf="!isNoDataMode">{{hozBarIndexNSRTY}}</span>
                                                    <span style="top: auto; bottom: 25%;"
                                                        class="table-row__head-bar-stick-title" *ngIf="!isNoDataMode">-{{hozBarTitleNSRTY}}</span>
                                                </div>
                                                <div class="table-row__body table-row__body-bar table-row__body-percent">
                                                    <div class="table-row__body-percent__wrapper">
                                                        <div class="percent__line">
                                                            <span [ngStyle]="hozBarNSRTY"
                                                                class="table-row__body-middle__line"></span>
                                                        </div>
                                                        <div class="percent__value">
                                                            <ng-container *ngFor="let item of arrNSRTY;">
                                                                <div class="bar-item"
                                                                    [ngClass]="{'bar__positive': item.isPositive, 'bar__negative' : !item.isPositive }">
                                                                    <span
                                                                        [ngClass]="{'bar-item__positive bar__positive--gray': item.isPositive, 'bar-item__negative bar__negative--gray' : !item.isPositive }"
                                                                        [style.height.%]="item.height"></span>
                                                                        <span class="rotate-text">{{ item.isPositive ? '+' + item.value : item.value }}</span>
                                                                    </div>
                                                            </ng-container>
                                                        </div>
                                                        <div class="percent__line">
                                                            <span [ngStyle]="hozBarOppGPTY"
                                                                class="table-row__body-middle__line"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clear"></div>

                                            <!--NSRVSLY-->
                                            <div class="table-row type-2">
                                                <div class="table-row__head vertial_chart__head flex">
                                                    <p class="rotate-text text-center">% vs LY</p>
                                                    <span style="top: auto; bottom: 75%;"
                                                        class="table-row__head-bar-stick-title">{{hozBarTitleNSRVSLY}}</span>
                                                    <span style="top: 50%; transform: translateY(-50%);"
                                                        class="table-row__head-bar-stick-title">0.0</span>
                                                    <span style="top: auto; bottom: 25%;"
                                                        class="table-row__head-bar-stick-title">{{hozBarTitleOppNSRVSLY}}</span>
                                                </div>
                                                <div class="table-row__body table-row__body-bar table-row__body-percent">
                                                    <div class="table-row__body-percent__wrapper">
                                                        <div class="percent__line">
                                                            <span [ngStyle]="hozBarNSRVSLY"
                                                                class="table-row__body-middle__line"></span>
                                                        </div>
                                                        <div class="percent__value">
                                                            <ng-container *ngFor="let item of arrNSRVSLY;">
                                                                <div class="bar-item"
                                                                    [ngClass]="{'bar__positive': item.isPositive, 'bar__negative' : !item.isPositive }">
                                                                    <span
                                                                        [ngClass]="{'bar-item__positive bar__positive--gray': item.isPositive, 'bar-item__negative bar__negative--gray' : !item.isPositive }"
                                                                        [style.height.%]="item.height"></span>
                                                                        <span class="rotate-text">{{ item.isPositive ? '+' + item.value : item.value }}</span>
                                                                    </div>
                                                            </ng-container>
                                                        </div>
                                                        <div class="percent__line">
                                                            <span [ngStyle]="hozBarOppNSRVSLY"
                                                                class="table-row__body-middle__line"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clear"></div>
                                        </div>
                                    </div>

                                    <div class="table-content GP-container flex">
                                        <div class="GP-header">
                                            <div class="table-row__head vertial_chart__head flex">
                                                <p class="rotate-text text-center">GP</p>
                                                <p class="rotate-text text-center">(MM JPY)</p>
                                            </div>
                                        </div>
                                        <div class="GP-content-container">
                                            <!--GPTY-->
                                            <div class="table-row type-1" *ngIf="!isGPTYHasNegativeElement">
                                                <div class="table-row__head vertial_chart__head flex">
                                                    <p class="rotate-text text-center">Act</p>
                                                    <span [ngStyle]="hozBarGPTY"
                                                        class="table-row__head-bar-stick-title" *ngIf="!isNoDataMode">{{hozBarTitleGPTY}}</span>
                                                    <span style="bottom: 0; margin-bottom: -3px"
                                                        class="table-row__head-bar-stick-title" *ngIf="!isNoDataMode">{{hozBarIndexGPTY}}</span>
                                                </div>
                                                <div class="table-row__body table-row__body-bar">
                                                    <div [ngStyle]="hozBarGPTY" class="table-row__body-bar-stick">
                                                        <span class="table-row__body-middle__line"></span>
                                                    </div>
                                                    <div class="table-row__body-start type-nsr-ty">
                                                        <ng-container *ngFor="let item of arrGPTY;">
                                                            <div class="bar-item" [style.height.%]="item.height">
                                                                <p class="rotate-text">{{item.value}}</p>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="table-row type-2" *ngIf="isGPTYHasNegativeElement">
                                                <div class="table-row__head vertial_chart__head flex">
                                                    <p class="rotate-text text-center">Act</p>
                                                    <span style="top: auto; bottom: 75%;"
                                                        class="table-row__head-bar-stick-title" *ngIf="!isNoDataMode">{{hozBarTitleGPTY}}</span>
                                                    <span style="top: 50%; transform: translateY(-50%);"
                                                        class="table-row__head-bar-stick-title" *ngIf="!isNoDataMode">{{hozBarIndexGPTY}}</span>
                                                    <span style="top: auto; bottom: 25%;"
                                                        class="table-row__head-bar-stick-title" *ngIf="!isNoDataMode">-{{hozBarTitleGPTY}}</span>
                                                </div>
                                                <div class="table-row__body table-row__body-bar table-row__body-percent">
                                                    <div class="table-row__body-percent__wrapper">
                                                        <div class="percent__line">
                                                            <span [ngStyle]="hozBarGPTY"
                                                                class="table-row__body-middle__line"></span>
                                                        </div>
                                                        <div class="percent__value">
                                                            <ng-container *ngFor="let item of arrGPTY;">
                                                                <div class="bar-item"
                                                                    [ngClass]="{'bar__positive': item.isPositive, 'bar__negative' : !item.isPositive }">
                                                                    <span
                                                                        [ngClass]="{'bar-item__positive bar__positive--gray': item.isPositive, 'bar-item__negative bar__negative--gray' : !item.isPositive }"
                                                                        [style.height.%]="item.height"></span>
                                                                        <span class="rotate-text">{{ item.isPositive ? '+' + item.value : item.value }}</span>
                                                                    </div>
                                                            </ng-container>
                                                        </div>
                                                        <div class="percent__line">
                                                            <span [ngStyle]="hozBarOppGPTY"
                                                                class="table-row__body-middle__line"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clear"></div>

                                            <!--GPVSLY-->
                                            <div class="table-row type-2">
                                                <div class="table-row__head vertial_chart__head flex">
                                                    <p class="rotate-text text-center">% vs LY</p>
                                                    <span style="top: auto; bottom: 75%;"
                                                        class="table-row__head-bar-stick-title">{{hozBarTitleGPVSLY}}</span>
                                                    <span style="top: 50%; transform: translateY(-50%);"
                                                        class="table-row__head-bar-stick-title">0.0</span>
                                                    <span style="top: auto; bottom: 25%;"
                                                        class="table-row__head-bar-stick-title">{{hozBarTitleOppGPVSLY}}</span>
                                                </div>
                                                <div class="table-row__body table-row__body-bar table-row__body-percent">
                                                    <div class="table-row__body-percent__wrapper">
                                                        <div class="percent__line">
                                                            <span [ngStyle]="hozBarNSRVSLY"
                                                                class="table-row__body-middle__line"></span>
                                                        </div>
                                                        <div class="percent__value">
                                                            <ng-container *ngFor="let item of arrGPVSLY;">
                                                                <div class="bar-item"
                                                                    [ngClass]="{'bar__positive': item.isPositive, 'bar__negative' : !item.isPositive }">
                                                                    <span
                                                                        [ngClass]="{'bar-item__positive bar__positive--gray': item.isPositive, 'bar-item__negative bar__negative--gray' : !item.isPositive }"
                                                                        [style.height.%]="item.height"></span>
                                                                        <span class="rotate-text">{{ item.isPositive ? '+' + item.value : item.value }}</span>
                                                                    </div>
                                                            </ng-container>
                                                        </div>
                                                        <div class="percent__line">
                                                            <span [ngStyle]="hozBarOppGPVSLY"
                                                                class="table-row__body-middle__line"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clear"></div>
                                        </div>
                                    </div>

                                    <!--Horizontal bar-->
                                    <div class="table-row horizontal-bar-chart">
                                        <div class="table-row__head_bottom horizontal-bar-chart__head">
                                        </div>
                                        <div class="table-row__body table-row__body-bar bottom">
                                            <div class="table-row__body-start border-top">
                                                <ng-container *ngFor="let item of arrDays;">
                                                <div class="bar-item-canvas">
                                                    <p class="rotate-text">{{item.value.replaceAll('-','/')}}</p>
                                                </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="d-flex flex-row-reverse mt-4 hide-pdf-print">
                <download-button [ngClass]=" isLoading ? 'disable-button' :''">
                    <a #downloadLink style="display: none;"></a>
                    <button *ngIf="!isLoading" ngbDropdownItem (click)="exportSkuTimeline_CVS_by_Url()">Download CSV</button>
                    <!--<button *ngIf="!isLoading" ngbDropdownItem (click)="exportPDF('vs LY Timeline')">Download PDF</button>-->
                </download-button>
            </div>
        </div>
    </div>
</div>
