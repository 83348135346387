<div class="faceshare-component card">
  <div class="card-header">
    <h3 #observedElement class="component-title">By SKU</h3>
  </div>

  <a #downloadLink style="display: none;"></a>
  <div class="d-inline-flex download">
    <button type="button" class="btn btn-download-excel download-button-tracking" (click)="downloadExcel()">
      <span>Download Excel</span>
      <ng-icon name="IonDownloadOutline" size="16px" strokeWidth="46px"></ng-icon>
    </button>
  </div>
</div>