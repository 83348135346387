import { Component, OnChanges, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from "@angular/core";
import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { DistrictRouterProgressHelper } from "src/app/components/pages/district-route-progress/district_route_progress_helper";
import { downloadAsPDF as _downloadAsPDF } from "../../../../helpers/pdfExtend";
import * as htmlToImage from 'html-to-image';
import moment from "moment";
import { FILTER_CONFIGS, AnalyticsQueries } from "src/app/model/constants";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import { PDFProperty } from "src/app/model/pdfProperty.model";
import { Router } from '@angular/router';
import { FilterHelper } from 'src/app/components/elements/add-more-filter/filter-helper';
import { CsvService } from 'src/app/services/csv.services';
import { UserService } from 'src/app/services/user.services';
import { MixPanelTrackingService } from 'src/app/services/mixpanel-tracking.services';

@Component({
    selector: "district-timeline",
    templateUrl: "./district-timeline.component.html",
    styleUrls: ["./district-timeline.component.scss"],
})
export class DistrictTimelineComponent implements OnChanges, OnInit {
    @Input() saleYearMonth : any[];
    @Output() ready = new EventEmitter<boolean>();
    @ViewChild("canvas") canvas: ElementRef;
    @ViewChild("downloadLink") downloadLink: ElementRef;
    @ViewChild("scroll") scroller: ElementRef;
    @ViewChild('observedElement', { static: true }) observedElement: ElementRef;

    // filter

    sectionName: string = 'vs LY Timeline';
    @Input() lstAllOptionsData: any;
    lstAllOptionsFilteredData: any;
    progressTimelineRawData: any[];

    FILTERS: any                  = []
    DISTRICT_PROGRESS_FILTERS     = FILTER_CONFIGS?.ADD_MORE_FILTER?.COLLECTIONS?.DISTRICT_PROGRESS;
    defaultVisibleFilters: number = FILTER_CONFIGS?.ADD_MORE_FILTER?.VISIBLE_COUNT ?? 4;

    listData_Filter: any            = null;
    lstOption_States: any           = {};
    lstOption_States_Applied: any   = {};
    lstOption_Selected: any         = {};
    lstOption_IsUsed: any           = {};
    lstOption_Selected_Applied: any = {};


    selectedAggregateBy: string  ='daily';
    maxDate: any;
    selectedDate: any;

    progressTimelineData:any;
    progressTimelineData_CSV:any;

    populated: boolean;
    listColumnHQTimeline: any[];
    listRowIndex: any[];

    arrBAPCTY: any[];
    hozBarBAPCTY: any;
    hozBarOppBAPCTY: any;
    hozBarTitleBAPCTY: string;
    hozBarIndexBAPCTY: string;
    isBAPCTYHasNegativeElement: boolean;

    arrBAPCVSLY: any[];
    hozBarBAPCVSLY: any;
    hozBarOppBAPCVSLY: any;
    hozBarTitleBAPCVSLY: string;
    hozBarTitleOppBAPCVSLY: string;

    arrNSRTY: any[];
    hozBarNSRTY: any;
    hozBarOppNSRTY: any;
    hozBarTitleNSRTY: string;
    hozBarTitleOppNSRTY: string;
    hozBarIndexNSRTY: string;
    isNSRTYHasNegativeElement: boolean;

    arrNSRVSLY: any[];
    hozBarNSRVSLY: any;
    hozBarOppNSRVSLY: any;
    hozBarTitleNSRVSLY: string;
    hozBarTitleOppNSRVSLY: string;

    arrGPTY: any[];
    hozBarGPTY: any;
    hozBarOppGPTY: any;
    hozBarTitleGPTY: string;
    hozBarTitleOppGPTY: string;
    hozBarIndexGPTY: string;
    isGPTYHasNegativeElement: boolean;

    arrGPVSLY: any[];
    hozBarGPVSLY: any;
    hozBarOppGPVSLY: any;
    hozBarTitleGPVSLY: string;
    hozBarTitleOppGPVSLY: string;
    isHasFistLoad: boolean = false;

    arrDays: any[];

    isLoading: boolean = true;

    constructor(private router: Router, public datepipe: DatePipe, private http: HttpClient,
                private csvService: CsvService, private userService: UserService, private mixPanelTrackingService: MixPanelTrackingService) {}

    ngOnInit(): void {
      this._getFilterConfigs();
    }

    ngOnChanges(changes: SimpleChanges) {
      if (changes['saleYearMonth'] && this.saleYearMonth && this.saleYearMonth.length > 0){
        if(this.isHasFistLoad){
          this.onFilterChange();
        } 

        if(this.progressTimelineData){
            this.transformData();
        }

        this.handleMarket();
      }
      this.isHasFistLoad = true;
    }

    ngAfterViewInit(): void {
      this.mixPanelTrackingService.observeElement(this.observedElement);
    }

    handleMarket() {
      let t = this;
      setTimeout(() => {
        t.ready.emit(true);
      });
    }

    private _getFilterConfigs() {
      this.FILTERS    = FilterHelper.sortObjectByVisibleItems(FILTER_CONFIGS?.ADD_MORE_FILTER?.DEFAULT, this.DISTRICT_PROGRESS_FILTERS.slice(0, this.defaultVisibleFilters)) || [];
      const lstOption         = FilterHelper.init_lstOption_States(`DistrictTimelineComponent_${this.userService.getUserFilterName()}`, this.FILTERS, this.defaultVisibleFilters, this.userService.getUserInfo()) || {};
      this.lstOption_States   = {...lstOption?.lstOption_States};
      this.lstOption_Selected = {...lstOption?.lstOption_Selected};
      if(lstOption?.lstOption_Selected?.AggregateBy){
        this.selectedAggregateBy = lstOption?.lstOption_Selected?.AggregateBy ;
      }

      this.listData_Filter    = {...lstOption.listData_Filter};
      this.lstOption_States_Applied = {...lstOption?.lstOption_States};
      this.lstOption_Selected_Applied = {...lstOption?.lstOption_Selected};
      this.lstOption_IsUsed   = FilterHelper.getListIsUsedFilter(this.FILTERS, this.DISTRICT_PROGRESS_FILTERS) || {};
    }

    onAggregateChange($event: any){
        this.selectedAggregateBy = $event;
        this.lstOption_Selected.AggregateBy = $event;
        this.onFilterChange();
    }

    onFilterChange() {
      if (!this.saleYearMonth || this.saleYearMonth?.length === 0) {
        return;
      }

      this.isLoading = true;
      const analyticsQuery = DistrictRouterProgressHelper.DistrictTimelineSetQueryString(this.selectedAggregateBy, {...this.lstOption_Selected_Applied, SALES_DATE: this.saleYearMonth});

      DistrictRouterProgressHelper.GetHQTimelineDataByAggregateKey(this.http, analyticsQuery).then((timelineData) => {
          this.progressTimelineData = timelineData?.Data;
          this.progressTimelineData.forEach(element => {
            if (this.selectedAggregateBy === "weekly"){
                element.BY_FILTER = DistrictRouterProgressHelper.getFirstDayOfWeekAsString(element.BY_FILTER);
            }
          });

          if(this.selectedAggregateBy === "weekly" || this.selectedAggregateBy == "quarterly"){ // now accept sort for weekly,   we can allow sort for all aggregateBy
          this.progressTimelineData.sort((a, b) => {
            if (a.BY_FILTER < b.BY_FILTER) {
                return -1;
            } else if (a.BY_FILTER > b.BY_FILTER) {
                return 1;
            } else {
                return 0;
            }
            });
          }

          this.progressTimelineRawData = this.defaultGroupByFilter(this.progressTimelineData);
          this.transformData();
          this.isLoading = false;
      });
    }

    defaultGroupByFilter(hqTimelineData: any[]) {
        var result = [];
        Object.values(
          hqTimelineData.reduce((res, value) => {
            const groupKey = value.BY_FILTER;
            if (!res[groupKey]) {
              res[groupKey] = { BY_FILTER: groupKey, GP_VS_LY: 0, GP_TY: 0, NSR_VS_LY: 0, NSR_TY: 0, BAPC_VS_LY: 0, BAPC_TY: 0 };
              result.push(res[groupKey]);
            }
            const numericFields = ["GP_VS_LY",  "GP_TY", "NSR_VS_LY",  "NSR_TY", "BAPC_VS_LY", "BAPC_TY"];
            numericFields.forEach(field => {
              switch (field) {
                case "GP_VS_LY":
                case "NSR_VS_LY":
                case "BAPC_VS_LY":
                case "GP_TY":
                case "NSR_TY":
                case "BAPC_TY":
                  var fieldValue = value[field] === "" ? 0 : parseFloat(value[field]);
                  if (!res[groupKey]) {
                    res[groupKey] = {};
                  }
                  if (!res[groupKey][field]) {
                    res[groupKey][field] = 0;
                  }
                  res[groupKey][field] += fieldValue;
                  res[groupKey][field] = parseFloat(res[groupKey][field].toFixed(2));
                  break;
              }
            });
            return res;
          }, {})
        );
        return result;
    }

    kFormatter(num: any) {
        return Number(num / 1000);
    }

    mFormatter(num: any) {
        return Number(num / 1000000);
    }


    transformData() {

        //BAPCTY data
        const bapctyData: {
            BY_FILTER: string;
            BAPC_TY: number }[] =
        this.progressTimelineData.map((item: any) => ({
            BY_FILTER: item.BY_FILTER,
            BAPC_TY: item.BAPC_TY,
        }));
        const objBAPCTY = this.formatChartData("BAPC_TY", bapctyData);
        this.arrBAPCTY = objBAPCTY.result;
        this.hozBarBAPCTY = objBAPCTY.hozBarCss;
        this.hozBarOppBAPCTY = objBAPCTY.hozBarOppCss;
        this.hozBarTitleBAPCTY = `${this.kFormatter(objBAPCTY.hozBarTitle).toLocaleString()}`;
        this.hozBarIndexBAPCTY = "0";
        this.isBAPCTYHasNegativeElement = objBAPCTY.result.some(e => !e.isPositive);

        //BAPCVSLY data
        const bapcvslyData: {
            BY_FILTER: string;
            BAPC_VS_LY: number;
        }[] = this.progressTimelineData.map((item: any) => ({
            BY_FILTER: item.BY_FILTER,
            BAPC_VS_LY: (parseFloat(item.BAPC_VS_LY)*100).toFixed(1) ,
        }));
        const objBAPCVSLY = this.formatChartData("BAPC_VS_LY", bapcvslyData);
        this.arrBAPCVSLY = objBAPCVSLY.result;
        this.hozBarBAPCVSLY = objBAPCVSLY.hozBarCss;
        this.hozBarOppBAPCVSLY = objBAPCVSLY.hozBarOppCss;
        this.hozBarTitleBAPCVSLY = `+${objBAPCVSLY.hozBarTitle.toFixed(1)}`;
        this.hozBarTitleOppBAPCVSLY = `-${objBAPCVSLY.hozBarTitle.toFixed(1)}`;

        //NSRTY data
        const nsrtyData: {
            BY_FILTER: string;
            NSR_TY: number;
        }[] = this.progressTimelineData.map((item: any) => ({
            BY_FILTER: item.BY_FILTER,
            NSR_TY: item.NSR_TY,
        }));
        const objNSRTY = this.formatChartData("NSR_TY", nsrtyData);
        this.arrNSRTY = objNSRTY.result;
        this.hozBarNSRTY = objNSRTY.hozBarCss;
        this.hozBarOppNSRTY = objNSRTY.hozBarOppCss;
        this.hozBarTitleNSRTY = `${this.mFormatter(objNSRTY.hozBarTitle).toLocaleString()}${objNSRTY.hozBarTitle > 999.5 ? "" : ""}`;
        this.hozBarIndexNSRTY = `${objNSRTY.hozBarTitle > 999.5 ? "0" : "0"}`;
        this.isNSRTYHasNegativeElement = objNSRTY.result.some(e => !e.isPositive);

        //NSRVSLY data
        const nsrvslyData: {
            BY_FILTER: string;
            NSR_VS_LY: number;
        }[] = this.progressTimelineData.map((item: any) => ({
            BY_FILTER: item.BY_FILTER,
            NSR_VS_LY: (parseFloat(item.NSR_VS_LY)*100).toFixed(1) ,
        }));
        const objNSRVSLY = this.formatChartData("NSR_VS_LY", nsrvslyData);
        this.arrNSRVSLY = objNSRVSLY.result;
        this.hozBarNSRVSLY = objBAPCVSLY.hozBarCss;
        this.hozBarOppNSRVSLY = objNSRVSLY.hozBarOppCss;
        this.hozBarTitleNSRVSLY = `+${objNSRVSLY.hozBarTitle.toFixed(1)}`;
        this.hozBarTitleOppNSRVSLY = `-${objNSRVSLY.hozBarTitle.toFixed(1)}`;

        //GPTY data
        const gptyData: {
            BY_FILTER: string;
            GP_TY: number;
        }[] = this.progressTimelineData.map((item: any) => ({
            BY_FILTER: item.BY_FILTER,
            GP_TY: item.GP_TY,
        }));
        const objGPTY = this.formatChartData("GP_TY", gptyData);
        this.arrGPTY = objGPTY.result;
        this.hozBarGPTY = objGPTY.hozBarCss;
        this.hozBarOppGPTY = objGPTY.hozBarOppCss;
        this.hozBarTitleGPTY = `${this.mFormatter(objGPTY.hozBarTitle).toLocaleString()}${objGPTY.hozBarTitle > 999.5 ? "" : ""}`;
        this.hozBarIndexGPTY = `${objGPTY.hozBarTitle > 999999.5 ? "0" : "0"}`;
        this.isGPTYHasNegativeElement = objGPTY.result.some(e => !e.isPositive);

        //GPVSLY data
        const gpvslyData: {
            BY_FILTER: string;
            GP_VS_LY: number;
        }[] = this.progressTimelineData.map((item: any) => ({
            BY_FILTER: item.BY_FILTER,
            GP_VS_LY: (parseFloat(item.GP_VS_LY)*100).toFixed(1) ,
        }));
        const objGPVSLY = this.formatChartData("GP_VS_LY", gpvslyData);
        this.arrGPVSLY = objGPVSLY.result;
        this.hozBarGPVSLY = objGPVSLY.hozBarCss;
        this.hozBarOppGPVSLY = objGPVSLY.hozBarOppCss;
        this.hozBarTitleGPVSLY = `+${objGPVSLY.hozBarTitle.toFixed(1)}`;
        this.hozBarTitleOppGPVSLY = `-${objGPVSLY.hozBarTitle.toFixed(1)}`;
        this.arrDays = this.progressTimelineData.map((item: any) => ({
            value: this.formartChartDate(item.BY_FILTER, item.MIN_SALES_DATE) ,
        }));
    }

    formartChartDate(BY_FILTER: string,MIN_SALES_DATE: string)
    {
        switch(this.selectedAggregateBy){

            case "monthly":
            case "yearly":
            case "daily":
                return BY_FILTER;
                break;
            case "weekly":
                return BY_FILTER;
                break;
            case "quarterly":
                const year = BY_FILTER.slice(0, 4);
                const quarter = BY_FILTER.slice(-1);
                return `${year}-Q${quarter}`;
                break;
            default:
                return BY_FILTER;
                break;
        }
        return "";
    }

    roundMaxValue(value: number): number {
        if (value <= 100) {
            return 100;
        } else {
            const orderOfMagnitude = Math.pow(10, Math.floor(Math.log10(value) - 1));
            let result = Math.ceil(value / orderOfMagnitude) * orderOfMagnitude;
            let secondDigit = parseInt(result.toString().charAt(1));
            if (secondDigit % 2 !== 0) {
              secondDigit += 1;
              const firstDigit = parseInt(result.toString().charAt(0));
              if (secondDigit === 10) {
                secondDigit = 0;
                result = (firstDigit + 1) * Math.pow(10, Math.floor(Math.log10(value)));
              } else {
                const resultString = result.toString();
                result = parseInt(resultString.charAt(0) + secondDigit + resultString.substring(2));
              }
            }
            return result;
        }
    }

    formatChartData(category: string,data: any[]): {result: any[]; hozBarCss: any; hozBarOppCss: any; hozBarTitle: number;} {
        let result: any[];
        let maxValue: number;
        let hozBarCss: any;
        let hozBarOppCss: any;
        let hozBarTitle: number;
        let isHasNegative: boolean;
        switch (category) {
            case "BAPC_TY":
                maxValue = this.roundMaxValue(Math.abs(Math.ceil(Math.max(...data.map((item) => item.BAPC_TY)))));
                isHasNegative = data.some(item => item.BAPC_TY < 0);
                result = data.map(item => {
                    const percentage = isHasNegative ? (Math.abs(item.BAPC_TY) / (maxValue / 2) * 100) : (parseFloat(item.BAPC_TY) / maxValue * 100);
                    const formattedNumber = this.kFormatter(item.BAPC_TY);
                    const formattedValue = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(formattedNumber);
                    const value = `${formattedValue}`;
                    const height = Math.min(Math.abs(percentage), isHasNegative ? 195 : 99);
                    const isPositive = item.BAPC_TY >= 0;
                    return { value, height, isPositive };
                });

                hozBarCss = {
                    top: "auto",
                    bottom: `${50}%`,
                };
                hozBarOppCss = {
                    top: "auto",
                    bottom: `-${52}%`,
                };
                hozBarTitle = maxValue / 2;
                return { result, hozBarCss, hozBarOppCss, hozBarTitle };
            case "NSR_TY":
            case "GP_TY":
                maxValue = this.roundMaxValue(Math.abs(Math.ceil(Math.max(...data.map((item) => Math.abs(item[category]))))));
                isHasNegative = data.some(item => item[category] < 0);
                result = data.map(item => {
                    const percentage = isHasNegative ? (Math.abs(item[category]) / (maxValue / 2) * 100) : (parseFloat(item[category]) / maxValue * 100);
                    const formattedNumber = this.mFormatter(item[category]);
                    const formattedValue = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(Math.round(formattedNumber));
                    const value = `${formattedValue}${Math.abs(item[category]) > 999.5 ? '' : ''}`;
                    const height =  Math.min(Math.abs(percentage), isHasNegative ? 195 : 99);
                    const isPositive = item[category] >= 0;
                    return { value, height, isPositive };
                });

                hozBarCss = {
                    top: "auto",
                    bottom: `${50}%`,
                };
                hozBarOppCss = {
                    top: "auto",
                    bottom: `-${52}%`,
                };
                hozBarTitle = maxValue / 2;
                return { result, hozBarCss, hozBarOppCss, hozBarTitle };
            case "BAPC_VS_BP":
            case "BAPC_VS_LY":
            case "NSR_VS_BP":
            case "NSR_VS_LY":
            case "GP_VS_BP":
            case "GP_VS_LY":
                maxValue = 100;
                result = data.map((item) => {
                const percentage = (parseFloat(item[category]) / maxValue * 100);
                const height = Math.min(Math.abs(percentage), 195);
                return {
                    value: isNaN(parseFloat(item[category])) ? '' : `${parseFloat(item[category]).toFixed(1)}`,
                    height: height,
                    isPositive: item[category] >= 0,
                  };
                });

                hozBarCss = {
                    top: "auto",
                    bottom: `${50}%`,
                };
                hozBarOppCss = {
                    top: "auto",
                    bottom: `-${50}%`,
                };
                hozBarTitle = maxValue;
                return { result, hozBarCss, hozBarOppCss, hozBarTitle };
            default:
                result = data;
                return { result, hozBarCss, hozBarOppCss, hozBarTitle };
        }
    }

      exportTimeline_CSV() {
        const analyticsQuery = DistrictRouterProgressHelper.DistrictTimelineSetQueryString(this.selectedAggregateBy, {...this.lstOption_Selected_Applied, SALES_DATE: this.saleYearMonth});
  
        DistrictRouterProgressHelper.GetHQTimelineDataByAggregateKey(this.http, analyticsQuery,true).then((timelineData) => {
            this.progressTimelineData_CSV = timelineData;
            this.progressTimelineData_CSV.forEach(element => {
              if (this.selectedAggregateBy === "weekly"){
                  element.BY_FILTER = DistrictRouterProgressHelper.getFirstDayOfWeekAsString(element.BY_FILTER);
              }
            });
  
            if(this.selectedAggregateBy === "weekly" || this.selectedAggregateBy == "quarterly"){ // now accept sort for weekly,   we can allow sort for all aggregateBy
            this.progressTimelineData_CSV.sort((a, b) => {
              if (a.BY_FILTER < b.BY_FILTER) {
                  return -1;
              } else if (a.BY_FILTER > b.BY_FILTER) {
                  return 1;
              } else {
                  return 0;
              }
              });
            }
            const fileName = "vs LY Timeline"
            let data = [];
            let filters: any;
            filters = {
                aggregateBy: String(this.selectedAggregateBy == null ? 'By day' : this.selectedAggregateBy),
                saleYearMonth:this.saleYearMonth.toString(),
                saleHQLev1: String(this.lstOption_Selected_Applied.SALES_HQ_LEV1_NAME),
                saleHQLev2: String(this.lstOption_Selected_Applied.SALES_HQ_LEV2_NAME),
                saleHQLev3: String(this.lstOption_Selected_Applied.SALES_HQ_LEV3_NAME),
                saleCenter: String(this.lstOption_Selected_Applied.SALES_CENTER_NAME),
                saleBaseControl: String(this.lstOption_Selected_Applied.SALES_CONTROL_BASE_NAME),
                saleRoute: String(this.lstOption_Selected_Applied.SALES_CONTROL_BASE_ROUTE_CODE).replace(/,/g, ', '),
                channelLev1: String(this.lstOption_Selected_Applied.CHANNEL_LEV1_NAME),
                channelLev2: String(this.lstOption_Selected_Applied.CHANNEL_LEV2_NAME),
                channelLev3: String(this.lstOption_Selected_Applied.CHANNEL_LEV3_NAME),
                channel_NL: String(this.lstOption_Selected_Applied.CHANNEL_NL),
            };
            
            this.progressTimelineData_CSV.forEach(items => {
              data.push({
                  saleDate: filters.saleYearMonth,
                  saleHQLev1: filters.saleHQLev1,
                  saleHQLev2: filters.saleHQLev2,
                  saleHQLev3: filters.saleHQLev3,
                  saleCenter: filters.saleCenter,
                  saleBaseControl: filters.saleBaseControl,
                  saleRoute: filters.saleRoute,
                  channelLev1: filters.channelLev1,
                  channelLev2: filters.channelLev2,
                  channelLev3: filters.channelLev3,
                  channel_NL: filters.channel_NL,
                  aggregateBy: this.formatAggregateBy(filters.aggregateBy),
                  "Date": items['BY_FILTER_Display']  === null ? '' : items['BY_FILTER_Display'] ,
                  "BAPC_TY":items['BAPC_TY_Display']  === null ? '' : items['BAPC_TY_Display'],
                  "BAPC_VS_LY": items['BAPC_VS_LY_Display']  === null ? '' : items['BAPC_VS_LY_Display'],
                  "NSR_TY": items['NSR_TY_Display']  === null ? '' : items['NSR_TY_Display'],
                  "NSR_VS_LY": items['NSR_VS_LY_Display']  === null ? '' : items['NSR_VS_LY_Display'],
                  "GP_TY": items['GP_TY_Display']  === null ? '' : items['GP_TY_Display'],
                  "GP_VS_LY": items['GP_VS_LY_Display']  === null ? '' : items['GP_VS_LY_Display']
              })
            });
    
            new AngularCsv(data, fileName, {
                showLabels: true,
                headers: ['Sales Year Month','Sales HQ Lev1', 'Sales HQ Lev2', 'Sales HQ Lev3', 'Sales Center', 'Sale Control Base', 'Sale Route' , 'Channel Lev1', 'Channel Lev2', 'Channel Lev3','National/Local', 'Aggregate By', 'Date',
                'BAPC TY', 'BAPC% VS LY', 'NSR TY', 'NSR% VS LY','GP TY', 'GP% VS LY'
                ],
            });

        });
    }

    exportTimeline_CSV_by_Url() {
      const analyticsQuery = DistrictRouterProgressHelper.DistrictTimelineSetQueryString(
        this.selectedAggregateBy,
        {...this.lstOption_Selected_Applied, SALES_DATE: this.saleYearMonth}
      );

      this.csvService.getCsvUrl(AnalyticsQueries.progressByTimeline, analyticsQuery, '')
        .subscribe((data) => {
          if (data) {
            this._downloadCsv(data?.Csv_Url);
          }
      });
    }

    public updateDistrictTimelineData(event: any) :void {
      const { lstOption_Selected, lstOption_States } = event;

      this.lstOption_Selected         = { ...lstOption_Selected };
      this.lstOption_Selected_Applied = { ...lstOption_Selected };
      this.lstOption_States_Applied   = { ...lstOption_States }

      this.onFilterChange();
    }

    onResetFiltersGroup($event: any) {
      const {data} = $event;

      if (data && Array.isArray(data) && data.length > 0) {
        data.map(key => {
          if (key && key.length > 0) {
            this.lstOption_Selected[key] = ['All'];
          }
        });
      }
    }

    formatAggregateBy(value:string){
      var arr = [{value: 'daily', name: 'By day'},
              {value: 'weekly', name: 'By week'},
              {value: 'monthly', name: 'By month'},
              {value: 'quarterly', name: 'By quarter'},
              {value: 'yearly', name: 'By year'}];
      return arr.find((item)=>item.value == value).name;
  }

  @ViewChild('horizontalScrollTable') horizontalScrollTable: ElementRef;
  initPrintTimeline(){
    const table    = this.horizontalScrollTable.nativeElement;
    const head     = table.getElementsByClassName('table-row__head vertial_chart__head')[0] || null;
    const cells    = table.getElementsByClassName('bar-item') || null;
    const daycells = table.getElementsByClassName('bar-item-canvas') || null;

    const tableRect = table?.getBoundingClientRect() || null;
    const headRect  = head?.getBoundingClientRect() || null;
    const visibleHead = (headRect?.right >= tableRect?.left) ? (headRect?.right - tableRect?.left) : 0;

    cells.forEach(function(cell) {
      const cellRect = cell.getBoundingClientRect();
      if (
        cellRect.left >= tableRect.left + visibleHead - 10 &&
        cellRect.right <= tableRect.right
      ) {
        cell.classList.add('visible-cell');
        cell.classList.remove('hidden-cell');
      } else {
        cell.classList.remove('visible-cell');
        cell.classList.add('hidden-cell');
      }
    });

    daycells.forEach(function(daycell) {
      const cellRect = daycell.getBoundingClientRect();
      if (
        cellRect.left >= tableRect.left + visibleHead - 10 &&
        cellRect.right <= tableRect.right
      ) {
        daycell.classList.add('visible-cell');
        daycell.classList.remove('hidden-cell');
      } else {
        daycell.classList.remove('visible-cell');
        daycell.classList.add('hidden-cell');
      }
    });
  }

  private _downloadCsv(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }

    @ViewChild('pdf_print_districttimeline', { static: false }) pdf_print_districttimeline: ElementRef;
      exportPDF(name){
        this.initPrintTimeline();

        const $ = window["jQuery"];
        $(".hidden-cell").css('display', 'none');
        $("body").addClass("pdf-printing-se pdf-printing");
        $(".table-container").css('height', 'auto');
        $(".table-container").css('overflow-y', 'scroll');
        $(".filter-section").css('display', 'none');

        htmlToImage.toCanvas(this.pdf_print_districttimeline.nativeElement, { quality: 1 })
          .then(function (canvas) {
          let pdfProperty: PDFProperty = {
              option: { margin: [50, 0, 46, 0] },
              canvas: canvas
          }
          _downloadAsPDF(name, null, false, pdfProperty);
          });
      }
}
